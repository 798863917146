import axios from "axios";

const auth = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

class AuthService {
  async validateToken(token) {
    return await auth.get(`${process.env.VUE_APP_LOGIN_URL}/validate-token/${token}`);
  }
}

export default new AuthService();
