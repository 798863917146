<template>
    <div>
      <div class="tab-container-333">
        <span class="tab-333">Introduction <button @click="removeTab('Introduction')">x</button></span>
        <span class="tab-333">Opening Activity <button @click="removeTab('Opening Activity')">x</button></span>
        <span class="tab-333">Main Activity <button @click="removeTab('Main Activity')">x</button></span>
        <span class="tab-333">Closing Activity <button @click="removeTab('Closing Activity')">x</button></span>
        <span class="tab-333">Assessment <button @click="removeTab('Assessment')">x</button></span>
        <span class="tab-add-333">+</span>
      </div>
      <div class="input-container-333">
        <input
          type="text"
          placeholder="Indicate the duration of this section, in minutes"
          class="duration-input"
        />
        <input
          type="text"
          placeholder="Write your lesson plan"
          class="plan-input"
        />
        <EditorContent :editor="editor" />
        <button class="next-button">Next section</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { Editor, EditorContent } from '@tiptap/vue-3';
  import StarterKit from '@tiptap/starter-kit';
  
  const editor = ref(null);
  
  onMounted(() => {
    editor.value = new Editor({
      extensions: [StarterKit],
      content: '<p>Disini ada tiptap editor</p>', // Initial content for the editor
    });
  });
  
  onBeforeUnmount(() => {
    if (editor.value) {
      editor.value.destroy();
    }
  });
  
  const removeTab = (tabName) => {
    console.log(`Removing tab: ${tabName}`);
  };
  </script>
  
  <style scoped>
  .tab-container-333 {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .tab-333 {
    background: #f0f0f0;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .tab-333 button {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .tab-add-333 {
    background: #d0d0d0;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .input-container-333 {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
  }
  
  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 0px;
    margin: 5px 0;
    font-size: 1.2em;
    color: #333;
  }
  
  input.duration-input {
    font-size: 1em;
    color: #aaa;
  }
  
  input.plan-input {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .next-button {
    background: #d0d0d0;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px 0 0 auto;
  }
  </style>
  