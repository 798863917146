<template>
    <div>
        <v-img height=120 src="/images/animated-gif-maker.gif" alt="Arrow Icon" />
        <div class="loading-label">
            Engaging Artificial Intelligence
        </div>
    </div>
    <div class="progress-container-outer mt-4">
        <div class="progress-container">
            <div class="progress-bar" id="progress-bar">
                <!-- Tempatkan progress-number di dalam progress-bar -->
                <div id="progress-number" class="progress-number"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Daftar teks yang akan digantikan secara bergantian
            textList: [
                "Digital whiteboards foster collaborative creativity!",
                "Virtual reality turns lessons into adventures!",
                "Math games make numbers exciting!",
                "Tech tools tailor lessons to learners!",
                "3D printing turns ideas into reality!",
                "Animation tools bring art classes alive!",
                "Music apps harmonize theory and practice!",
                "Tech makes learning interactive and engaging!",
                "Coding for kids sparks early innovation!",
                "Interactive maps explore history's landscapes!",
                "Environmental apps foster green education!",
                "Educational VR immerses in ancient history!",
                "STEM kits spark scientific curiosity!",
                "Mind mapping software organizes ideas!",
                "AI companions make learning less lonely!",
                "Digital storytelling sparks joyful writing!",
                "Gaming apps fuel happy learning adventures!",
                "Music apps harmonize creativity and tech!",
                "Animation tools unleash happy animators!",
                "DIY electronics kits ignite joyful invention!"
            ],
            currentIndex: 0, // Untuk melacak indeks teks saat ini
        };
    },
    mounted() {
        this.animateProgressBar();
        this.changeText(); // Memanggil metode changeText saat komponen dimuat
    },
    methods: {
        animateProgressBar() {
            const progressBar = document.getElementById('progress-bar');
            const progressNumber = document.getElementById('progress-number');
            let width = 0;
            const interval = setInterval(() => {
                if (width >= 85) {
                    clearInterval(interval);
                } else {
                    width++;
                    progressBar.style.width = width + '%';
                    progressNumber.textContent = width + '%';
                }
            }, 40);
        },
        changeText() {
            // Menggunakan setInterval untuk mengganti teks setiap 2 detik
            setInterval(() => {
                const newText = this.textList[this.currentIndex];
                const loadingLabel = document.querySelector('.loading-label');

                if (loadingLabel) {
                    loadingLabel.textContent = newText;
                    this.currentIndex = (this.currentIndex + 1) % this.textList.length; // Memperbarui indeks untuk teks selanjutnya
                }
            }, 4000); // 2000 ms = 2 detik
        },
    },
};
</script>

<style>
.progress-container-outer {
    width: 30%;
    margin: 0 auto;
    position: relative;
}

.progress-container {
    width: 100%;
    background: #F2F3FB;
    border-radius: 25px;
    padding: 3px;
    position: relative;
}

.progress-bar {
    width: 0;
    height: 10px;
    background: #003D6A;
    border-radius: 25px;
    position: relative;
}

.progress-number {
    color: #FFFFFF;
    font-family: 'League Spartan';
    position: absolute;
    right: 5px;
    top: -2px;
    font-size: 0.7em;
}

.loading-label {
    font-family: 'League Spartan';
    color: #003D6A;
    text-align: center;
}
</style>
