<template>
    <div v-if="isMobile">
        <div class="discussion-card" style="background-color: white;">
            <div @click="updateVisibility" class="input-text-discussion" style="border-bottom: 0px;color: #CCCCCC;">
                <img v-if="!user" height="30" src="/images/chinaus/placeholder-background.png" alt="Arrow Icon">
                <img v-else-if="user" height="30" :src="user.image" alt="Arrow Icon">

                <!-- Editor Tiptap -->
                Share your course experience
            </div>
        </div>
        <div>
            <ModalBottomComponent :visible="showModal" @update:visible="updateVisibility">
                <!-- Fill in capital here -->
                <div class="container-modal" style="width: 100%;">
                    <div class="discussion-card mb-5">
                        <div class="input-text-discussion">
                            <img height="30" src="/images/chinaus/placeholder-background.png" alt="Arrow Icon">
                            <editor-content :editor="editor" class="custom-editor-style" />
                        </div>
                        <div style=" display: flex; justify-content: space-between; ">
                            <div style="color: rgb(224, 0, 105); font-size: 0.8em;">{{ editorMessage }}</div>
                            <div style="font-size: 0.8em;">{{ totalCharacters }} / 1000</div>
                        </div>
                        <div class="tooltip-discussion-input" style="margin: 10px 0px">
                            <div style="display: flex; gap: 10px;">


                                <div class="image-upload-container">
                                    <input type="file" id="imageUpload" @change="handleImageUpload" accept=".png, .jpg, .jpeg" 
                                        hidden>
                                    <label for="imageUpload" class="upload-button">
                                        <img class="image-tool-upload" height="25"
                                            src="/images/chinaus/select-image.svg" alt="Arrow Icon">
                                    </label>

                                    <div v-if="imagePreview" class="image-preview-container">
                                        <img :src="imagePreview" class="image-preview">
                                        <button @click="removeImage" class="remove-image-btn">✖</button>
                                    </div>
                                </div>

                                <img class="image-tool" @click="toggleBold" height="25" src="/images/chinaus/bold.svg"
                                    alt="Bold Icon">
                                <img class="image-tool" @click="toggleItalic" height="25"
                                    src="/images/chinaus/italic.svg" alt="Italic Icon">
                                <img class="image-tool" @click="toggleUnderline" height="25"
                                    src="/images/chinaus/underline.svg" alt="Underline Icon">
                                <img class="image-tool" @click="toggleBulletList" height="25"
                                    src="/images/chinaus/bullet-points.svg" alt="Bullet Points Icon">
                            </div>
                        </div>
                    </div>

                    <div>
                        <button @click="postDiscussion()" :disabled="isLoading"
                            :class="{ 'button-send-loading': isLoading }" class="button button-send"
                            style="width: 100%; border-radius: 8px;">Send</button>
                    </div>
                </div>
            </ModalBottomComponent>
        </div>
    </div>
    <div v-else>
        <div class="discussion-card" style="background-color: white;">
            <div class="input-text-discussion">
                <img v-if="!user" height="30" src="/images/chinaus/placeholder-background.png" alt="Arrow Icon">
                <img v-else-if="user" height="30" :src="user.image" alt="Arrow Icon">

                <!-- Editor Tiptap -->
                <editor-content :editor="editor" class="custom-editor-style" />
            </div>
            <div style=" display: flex; justify-content: space-between; ">
                <div style="color: rgb(224, 0, 105); font-size: 0.8em;">{{ editorMessage }}</div>
                <div style="font-size: 0.8em;">{{ totalCharacters }} / 1000</div>
            </div>
            <div class="tooltip-discussion-input">
                <div class="tooltip-discussion-input-child" style="margin-top: 10px; display: flex; gap: 10px;">

                    <div class="image-upload-container">
                        <input type="file" id="imageUpload" @change="handleImageUpload" accept=".png, .jpg, .jpeg"  hidden>
                        <label for="imageUpload" class="upload-button">
                            <img class="image-tool-upload" height="25" src="/images/chinaus/select-image.svg"
                                alt="Arrow Icon">
                        </label>

                    </div>

                    <img class="image-tool" @click="toggleBold" height="25" src="/images/chinaus/bold.svg"
                        alt="Bold Icon">
                    <img class="image-tool" @click="toggleItalic" height="25" src="/images/chinaus/italic.svg"
                        alt="Italic Icon">
                    <img class="image-tool" @click="toggleUnderline" height="25" src="/images/chinaus/underline.svg"
                        alt="Underline Icon">
                    <img class="image-tool" @click="toggleBulletList" height="25"
                        src="/images/chinaus/bullet-points.svg" alt="Bullet Points Icon">
                </div>
                <div>
                    <button @click="postDiscussion()" :disabled="isLoading"
                        :class="{ 'button-send-loading': isLoading }" class="button button-send"
                        style="width: 100%; border-radius: 8px;">Send</button>
                </div>
            </div>
            <div v-if="imagePreview" class="image-preview-container">
                <img height="80" :src="imagePreview" class="image-preview">

                <img @click="removeImage" class="remove-image-btn" height="25" src="/images/close.svg" alt="Arrow Icon">
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { ref, onMounted, onUnmounted, onBeforeUnmount, defineProps, computed } from 'vue';
import ModalBottomComponent from '@/components/ModalBottomComponent.vue';
import cookiesService from '@/services/cookiesService';
import discussionService from '@/services/discussionService';

const editor = ref(null)
const isMobile = ref(window.innerWidth < 768);
const isLoading = ref(false);
const showModal = ref(false);
const user = ref(null);

const imagePreview = ref(null);
const imageData = ref('');
const editorMessage = ref('');

const props = defineProps({
    getAllDiscussion: Function
})

onMounted(() => {
    editor.value = new Editor({
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder: 'Share your course experience',
            }),
            Underline,
        ],
    });

    // checkAuthToken();
})

const isEditorEmpty = computed(() => {
    return !editor.value || editor.value.isEmpty;
});

const totalCharacters = computed(() => {
    if (!editor.value) return 0;
    return editor.value.getText().length;
});

const checkAuthToken = async () => {
    try {
        const authToken = await cookiesService.getCookie('authToken');
        if (authToken) {
            user.value = authToken;

            clearInterval(intervalId);
        }
    } catch (error) {
        console.error(error);
    }
};

const intervalId = setInterval(checkAuthToken, 500);

function handleResize() {
    isMobile.value = window.innerWidth < 768;
}

function updateVisibility() {
    showModal.value = !showModal.value
}

onBeforeUnmount(() => {
    editor.value.destroy()
})

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

function toggleBold() {
    editor.value.chain().focus().toggleBold().run();
}

function toggleItalic() {
    editor.value.chain().focus().toggleItalic().run();
}

function toggleUnderline() {
    editor.value.chain().focus().toggleUnderline().run();
}

function toggleBulletList() {
    editor.value.chain().focus().toggleBulletList().run();
}

async function postDiscussion() {
    if (isLoading.value) {
        return;
    }

    if(editor.value.isEmpty) {
        editorMessage.value = 'Please insert text';
        return;
    }

    if(editor.value.getText().length > 1000) {
        editorMessage.value = 'Cannot exceed 1000 characters';
        return;
    }

    if (editor.value) {
        isLoading.value = true;
        const htmlContent = editor.value.getHTML();
        const data = { content: htmlContent };

        if(imageData.value !== '') {
            data.base64Image = imageData.value;
        }

        try {
            const response = await discussionService.postDiscussion(data);
            if (response.status == 200) {
                editor.value.commands.setContent('');
                removeImage();
                props.getAllDiscussion();
            } else {
                console.error("Failed to post discussion even though no errors occurred", response);
            }
        } catch (error) {
            console.error("Error posting discussion", error);
        }
        isLoading.value = false;
    } else {
        console.log("Editor is not initialized");
    }
}

const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
            imagePreview.value = e.target.result;
            imageData.value = e.target.result;  // Simpan sebagai base64
        };
        reader.readAsDataURL(file);
    }
};

const removeImage = () => {
    imagePreview.value = null;
    imageData.value = '';
    document.getElementById('imageUpload').value = '';  // Reset input file
};
</script>

<style>
.discussion-card {
    transition: background-color 0.3s ease;
}

.custom-editor-style {
    width: 100% !important;
}

.custom-editor-style .ProseMirror {
    width: 100% !important;
    height: 100% !important;
}

/* Basic editor styles */
.tiptap {
    >*+* {
        margin-top: 0.75em;
    }
}

/* Placeholder (at the top) */
.tiptap p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #CCCCCC;
    pointer-events: none;
    height: 0;
}

/* Placeholder (on every new line) */
/*.tiptap p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }*/

.image-tool:hover {
    filter: brightness(0) saturate(0%) invert(90%) sepia(0%) saturate(749%) hue-rotate(0deg) brightness(106%) contrast(90%);
    cursor: pointer;
}

.image-tool-upload:hover {
    filter: brightness(0) saturate(100%) invert(40%) sepia(72%) saturate(4553%) hue-rotate(327deg) brightness(102%) contrast(102%);
    cursor: pointer;
}

.button-send-loading {
    background-color: grey !important;
    cursor: default;
}

.image-upload-container {
    position: relative;
}

.upload-button {
    display: inline-block;
    cursor: pointer;
}

.image-preview-container {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}

.image-preview {
    width: 200px;
    /* Atur sesuai kebutuhan */
    height: auto;
    border: 1px solid #ccc;
}

.remove-image-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #003D6A;
}
</style>