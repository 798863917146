<template>
  <div class="accordion">
    <div class="accordion-title" @click="toggle" :class="{ 'accordion-title-active': isOpen }" :icon-position="props.iconPosition">
      <span v-if="props.iconPosition === 'left'" class="icon-container">
        <img :src="isOpen ? props.expandedIcon : props.collapsedIcon" height="15" alt="Icon" class="clickable-image" />
      </span>
      <slot name="title">Default Title</slot>
      <span v-if="props.iconPosition === 'right'" class="icon-container">
        <img :src="isOpen ? props.expandedIcon : props.collapsedIcon" height="15" alt="Icon" class="clickable-image" />
      </span>
    </div>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-show="isOpen" class="accordion-content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  expandedIcon: {
    type: String,
    default: '/images/chinaus/minus.svg'
  },
  collapsedIcon: {
    type: String,
    default: '/images/chinaus/plus.svg'
  },
  iconPosition: {
    type: String,
    default: 'right'
  }
});

const isOpen = ref(false);

function toggle() {
  isOpen.value = !isOpen.value;
}

function beforeEnter(el) {
  el.style.transformOrigin = 'top';
  el.style.transform = 'scaleY(0)';
}

function enter(el, done) {
  requestAnimationFrame(() => {
    el.style.transition = 'transform 0.3s ease';
    el.style.transform = 'scaleY(1)';
    done();
  });
}

function beforeLeave(el) {
  el.style.transformOrigin = 'top';
  el.style.transform = 'scaleY(1)';
}

function leave(el, done) {
  requestAnimationFrame(() => {
    el.style.transition = 'transform 0.3s ease';
    el.style.transform = 'scaleY(0)';
    done();
  });
}
</script>

<style>
.accordion {
  width: 100%;
  margin-bottom: 5px;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
}

.accordion-title-active {
  /* color: #00925E; */
}

.accordion-content {
  padding: 10px;
  font-size: 1.2em;
  will-change: transform;
  width: 100%; /* Pastikan lebar accordion content 100% */
}

.icon-container {
  display: flex;
  align-items: center;
}

.accordion-title[icon-position='left'] .icon-container {
  order: -1; /* Menempatkan ikon di awal jika di kiri */
}

.accordion-title[icon-position='right'] .icon-container {
  order: 1; /* Menempatkan ikon di akhir jika di kanan */
}
</style>
