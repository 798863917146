import VueCookies from 'vue-cookies';

export default {
  getCookie(name) {
    return VueCookies.get(name);
  },
  setCookie(name, value, options) {
    VueCookies.set(name, value, options);
  },
  removeCookie(name) {
    VueCookies.remove(name);
  },
};
