<template>
    <footer class="footer-admin">
      <div class="footer-content">
        <span>&copy; {{ currentYear }} Edmaker Pte. Ltd.</span>
      </div>
    </footer>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const currentYear = ref(new Date().getFullYear());
  
  onMounted(() => {
    currentYear.value = new Date().getFullYear();
  });
  </script>
  
  <style scoped>
  .footer-admin {
    width: 100%;
    background-color: white;
    color: #003D6A;
    text-align: center;
    border-top: 1px solid #D8DAE5;
    bottom: 0;
  }

  .footer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  </style>
  