<template>
  <div class="container-37">
    <a href="/admin/china-us" class="back-link">
      <img src="/images/chinaus/arrow-left.svg" alt="arrow-left">
      Back
    </a>

    <div class="border-container-37 mb-5">
      <h1 class="project-title">
        {{ projectData.title }}
      </h1>

      <h2 class="section-title">
        Team Information
      </h2>

      <select v-model="selectedTeamMember" @change="updateSelectedMember" class="team-dropdown">
        <option :value="teamData.leader">Team Leader - {{ teamData.leader.user.firstname }} {{ teamData.leader.user.lastname }}</option>
        <option v-for="(member, index) in teamData.members" :key="index" :value="member">
          Team Member - {{ member.user.firstname }} {{ member.user.lastname }}
        </option>
      </select>

      <div v-if="selectedTeamMember">
        <div class="info-group">
          <h6>Name*</h6>
          <p>{{ selectedTeamMember.user.firstname }} {{ selectedTeamMember.user.lastname }}</p>
        </div>
      
        <div class="info-group">
          <h6>Phone Number*</h6>
          <p>{{ selectedTeamMember.phone }}</p>
        </div>
      
        <div class="info-group">
          <h6>Email*</h6>
          <p>{{ selectedTeamMember.user.email }}</p>
        </div>
      
        <div class="info-group">
          <h6>Date of Birth*</h6>
          <p>{{ selectedTeamMember.date_of_birth }}</p>
        </div>
      
        <div class="info-group">
          <h6>City*</h6>
          <p>{{ selectedTeamMember.city }}</p>
        </div>
      
        <div class="info-group">
          <h6>State*</h6>
          <p>{{ selectedTeamMember.state }}</p>
        </div>
      </div>

      <h2 class="section-title">
        Project's Details
      </h2>

      <div class="info-group">
        <h6>Project's Name</h6>
        <p class="info-project-title">{{ projectData.title }}</p>
      </div>

      <div class="info-group">
        <h6>Project's Image</h6>
        <div class="image-container">
          <img :src="`https://storage.eddy4teachers.com/${projectData.image_url}`" alt="project image">
        </div>
      </div>

      <div class="info-group">
        <h6>Project's Summary</h6>
        <p>{{ projectData.summary }}</p>
      </div>

      <div class="info-group">
        <h6>Hardware*</h6>
        <p>{{ projectData.hardware }}</p>
      </div>

      <div class="info-group">
        <h6>Software*</h6>
        <p>{{ projectData.software }}</p>
      </div>

      <div class="info-group">
        <h6>Project Background*</h6>
        <p>{{ projectData.background }}</p>
        <p>{{ projectData.details }}</p>
      </div>

      <div class="info-group">
        <h6>Potential Impact*</h6>
        <p>{{ projectData.impact }}</p>
      </div>

      <div class="info-group">
        <h6>Schematics*</h6>
        <a :href="`https://storage.eddy4teachers.com/${projectData.schematics_url}`" target="_blank" class="btn-link">
          View Schematics
        </a>
      </div>

      <div class="info-group">
        <h6>Video Demo</h6>
        <input type="text" v-model="projectData.demo_url" value="bit.ly/demovideo">
      </div>

      <div v-if="projectData.attachments_url" class="info-group">
        <h6>Attachment</h6>
        <a :href="`https://storage.eddy4teachers.com/${projectData.attachments_url}`" target="_blank" class="btn-link">
          View Attachment
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import ChinaUsService from '@/services/chinausService.js';

const route = useRoute();

const projectData = ref({
  title: "",
  image_url: "",
  summary: "",
  hardware: "",
  software: "",
  background: "",
  details: "",
  impact: "",
  schematics_url: "",
  demo_url: "",
  attachments_url: "",
  additional_info: ""
});

const teamData = ref({
  leader: {
    user_id: null,
    phone: "",
    date_of_birth: "",
    state: "",
    city: "",
    parental_consent_url: "",
    user: {
      firstname: "",
      lastname: "",
      email: "",
      image: ""
    }
  },
  members: []
});

const selectedTeamMember = ref(null);

const updateSelectedMember = () => {
  selectedTeamMember.value = selectedTeamMember.value ? selectedTeamMember.value : teamData.value.leader;
};

onMounted(async () => {
  const projectId = route.params.id;

  const response = await ChinaUsService.getSubmissionbyId(projectId);
  if (response.status === 200) {
    Object.assign(projectData.value, response.data.data.project);
    Object.assign(teamData.value.leader, response.data.data.leader);
    teamData.value.members = response.data.data.members;
    selectedTeamMember.value = teamData.value.leader;
  }
});
</script>


<style scoped>
body {
  font-family: 'League Spartan', sans-serif;
}

.container-37 {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  color: #003D6A;
  padding: 20px;
}

.border-container-37 {
  border: 1px solid #D8DAE5;
  padding: 1.5em 2em;
  border-radius: 1.5em;
}

.back-link {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.38em;
  text-decoration: none;
  margin-bottom: 1em;
}

.back-link img {
  height: 1em;
  width: 1em;
}

.project-title {
  font-size: 1.75em;
  font-weight: 400;
}

.team-dropdown {
  margin-top: 1.25em;
  margin-bottom: 1.5em;
  position: relative;
  width: max-content;
  border: 1px solid #3B4648;
  border-radius: 0.75em;
  padding: 0.5em 1em;
}

.team-dropdown:focus {
  outline: none;
}

.section-title {
  margin-top: 0.5em;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration-line: underline;
  text-decoration-thickness: 0.05em;
  text-underline-offset: 0.3em;
}

.info-group {
  margin-top: 0.75em;
}

.info-group h6 {
  font-size: 1em;
  font-weight: 400;
}

.info-group p {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 0.75em;
}

.info-group .info-project-title {
  font-size: 1.5em;
  font-weight: 600;
}

.info-group .image-container {
  width: 24em;
  height: 16em;
  margin: 0 auto;
  border-radius: 0.5em;
}

.info-group .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5em;
}

.info-group .btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3B4648;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  font-weight: 600;
  color: #3B4648;
}

.info-group input {
  width: 100%;
  border: 1px solid #3B4648;
  border-radius: 0.5em;
  padding: 0.5em 1em;
}
</style>
