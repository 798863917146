<template>
    <div style="display: flex;margin-bottom: 5em;">
        <!-- content section -->
        <div :style="{ marginLeft: '50px', marginRight: '20px', width: defaultContentWidth + 'px' }">
            <div style="display:flex;gap:5px;justify-content:space-between;">
                <div class="header">{{ title }} </div>
                <div v-if="pageMeta==='preview'"><button @click="redirectToEditWithQueryParams" class="btn-save" style="width: 100%;padding-left: 20px;padding-right: 20px;"><img src="/images/edit.svg" alt=""> Edit</button></div>
            </div>
            <div class="border-header"></div>
            <div style="display: flex; margin: 0.6em 0em;">
                <div class="chip"><img height="20" style="margin-right: 0.3em;" src="/images/ai-assisted.svg" alt="ai-assisted" />AI Assisted</div>
                <div v-if="Array.isArray(softwareName) && softwareName.length > 0" class="chip"><img height="20" style="margin-right: 0.3em;"
                        src="/images/software-icon.svg" alt="category" />Software :<span class="ml-2" v-for="item in softwareName"
                        :key="item">{{ softwareName.join(',') }}</span></div>
                <div @click="getAndOpenPDF" class="chip" style="cursor: pointer;"><img height="20" style="margin-right: 0.3em;" src="/images/category-icon.svg" alt="ai-assisted" />{{ buttonText }}</div>
                <div @click="openTeacherMode" style="cursor: pointer;" class="chip"><img height="20" style="margin-right: 0.3em;" src="/images/teacher-mode.svg" alt="ai-assisted" />Teacher Mode</div>
            </div>
            <div class="tabs">
                <span class="tab" :class="{ active: activeTab === 'introduction' }" @click="setActiveTab('introduction')" >Introduction</span>
                <span class="tab" :class="{ active: activeTab === 'opening' }" @click="setActiveTab('opening')" >Opening Activity</span>
                <span class="tab" :class="{ active: activeTab === 'main-activity' }" @click="setActiveTab('main-activity')" >Main Activity</span>
                <span class="tab" :class="{ active: activeTab === 'closing-activity' }" @click="setActiveTab('closing-activity')" >Closing Activity</span>
                <span class="tab" :class="{ active: activeTab === 'assessment' }" @click="setActiveTab('assessment')" >Assessment</span>
            </div>
            <div class="content">
                <!-- <div v-if="activeTab === 'introduction'">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in cididunt ut
                        labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="sub-header">Assessment</div>
                    <p>This lesson will introduce the learners to factors, an essential component of mathematics as they help
                        explore number properties and simplify mathematical operations. To make the process of understanding factors
                        interactive, learners will be engaging with virtual manipulatives offered by Mathigon. Virtual manipulatives
                        are digital tools or interactive objects that replicate the functionality of physical manipulatives. The
                        idea is to ensure that the learners get hands-on experience, but in a digital world.</p>
                        
                    <div class="sub-header">Lesson Overview</div>

                    <div style="display: flex;justify-content: center;align-items: center;">
                        <table class="activity-table">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Overview</th>
                                    <th>Activity Objectives</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Opening Activity</td>
                                    <td>Learners will be a part of an imaginary pizza party where they will divide slices of pizzas into
                                        equal groups.</td>
                                    <td>Find that some numbers can be divided into equal groups, while others cannot</td>
                                </tr>
                                <tr>
                                    <td>Main Activity</td>
                                    <td>Learners will use virtual manipulatives offered by Mathigon to identify factors of numbers
                                        ranging from 1-50.</td>
                                    <td>
                                        <ul>
                                            <li>Identify prime numbers.</li>
                                            <li>Use virtual manipulatives to extract factors.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Closing Activity</td>
                                    <td>Differentiate between prime and composite numbers.</td>
                                    <td>Differentiate between prime and composite numbers.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="sub-header">Lesson Objectives</div>
                    <ul style="margin-left: 20px;">
                        <li>Identify prime numbers</li>
                        <li>Understand factors</li>
                        <li>Differentiate between prime and composite numbers</li>
                        <li>Use virtual manipulative</li>
                    </ul>

                </div> -->
                
                <div v-if="activeTab === 'introduction'" v-html="this.introContent" style="padding: 0.5rem 0;"></div>
                <div v-if="activeTab === 'opening'" v-html="this.openingActivityContent" style="padding: 0.5rem 0;"></div>
                <div v-if="activeTab === 'main-activity'" v-html="this.mainActivityContent" style="padding: 0.5rem 0;"></div>
                <div v-if="activeTab === 'closing-activity'" v-html="this.closingActivityContent" style="padding: 0.5rem 0;"></div>
                <div v-if="activeTab === 'assessment'" v-html="this.assessmentContent" style="padding: 0.5rem 0;"></div>
                
                <!-- <div class="sub-header">Resources</div>
                <div>
                    <button class="button">Resources A Download</button>
                </div>
                <div style="margin-top: 10px;">
                    <button class="button">Resources B Download</button>
                </div> -->
            </div>
            
            <p class="title" style="margin-top: 1rem;">
                Resources
            </p>

            <div style="margin-top: 1.25rem; display: flex; flex-direction: column; gap: 1.25rem;">
                <a class="standard-text button-blue" @click="downloadResources" style="display: flex;gap: 0.5em;cursor: pointer;">
                    <img src="/images/document-upload.svg" alt="" style="margin-top: 0.1em;"> Teacher’s Deck 
                </a>
            </div>
        
        </div>


        <ContentSidebar :resource="resource"></ContentSidebar>
        
    </div>
</template>

<script>
import ContentSidebar from '@/components/ContentSidebar.vue';
import LessonPackageService from '@/services/lessonPackageService';
import { mapState } from 'vuex';
// import { useRouter, useRoute } from 'vue-router';

export default {
    data() {
    return {
      activeTab: 'introduction',
      sidebarOpen: false,
      defaultContentWidth: null,
      introContent: '',
      openingActivityContent: '',
      mainActivityContent: '',
      closingActivityContent: '',
      assessmentContent: '',
      title: '',
      softwareName: null,
      resource: null,
      pageMeta: null,
      buttonText: 'Print as PDF',
      contentId: null,
      lessonPackageId: null,
      teacherModeId: null
    };
  },
  components: {
    ContentSidebar
  },
  computed: {
    ...mapState(['formData']),
  },
  emits: ['loading','error'],
  async mounted() {
    this.setDefaultContentWidth();
    
    this.fetchPageMeta();
  
    window.addEventListener('resize', this.setDefaultContentWidth);

    this.lessonPackageId = this.$route.params.id;

    if(this.lessonPackageId) {
        await this.fetchDataLessonPackage(this.lessonPackageId);
    } else {
        const data = this.decryptDataFromURL();
        await this.fetchData(data);
    }

  },  
  methods: {  
    fetchDataFromParent() {
      const data = this.decryptDataFromURL();
      
      this.fetchData(data);
    },
    fetchPageMeta() {
        const pageMeta = this.$route.meta.page;
        this.pageMeta = pageMeta;
    },
    redirectToEditWithQueryParams() {
        this.$router.push({ path: `/edit-packages/${this.lessonPackageId}`});
    },
    decryptDataFromURL() {
        // Menggunakan this.$route dan this.$router untuk akses ke route dan router
        const encryptedString = this.$route.query.data;
        if (!encryptedString) {
            this.$router.push({ path: '/', query: { key: 'reset' } });
            return;
        }
        
        const decryptedString = decodeURIComponent(encryptedString);
        const redirectObject = JSON.parse(decryptedString);
        
        return redirectObject;
    },
    setActiveTab(tabName) {
      this.activeTab = tabName;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    setDefaultContentWidth() {
      const contentElement = this.$el.querySelector('.content');
      if (contentElement) {
        const marginSpace = 100;
        this.defaultContentWidth = window.innerWidth - marginSpace;
      }
    },
    findSectionByTitle(content, title) {
      const keys = Object.keys(content);

      for (const key of keys) {
        const section = content[key];
                
        if (section.title === title) {
          return section;
        }
      }

      return null;
    },
    findSectionContent(section) {
      if (!section) {
        return '';
      }

      const keys = Object.keys(section.content);

      for (const key of keys) {
        const content = section.content[key];
        return content.content;
      }
    },
    async downloadResources() {
        window.open(`${process.env.VUE_APP_API_URL}/api/lesson-packages/download?lesson_package_id=${this.contentId}&resource_type=slides`, '_blank');
    },
    async fetchData(data) {
        this.$emit('loading', true);
        try {
            const response = await LessonPackageService.buildLessonPackage(data);
            if (response.status === 200) {
                
                const content = response.data.data.lesson_package.content;
                this.title = response.data.data.lesson_package.title;
                this.resource = response.data.data.resource;
                this.contentId = response.data.data.resource.lesson_package_id;

                this.lessonPackageId = this.contentId;

                const introSection = this.findSectionByTitle(content, 'Introduction');
                const openingActivitySection = this.findSectionByTitle(content, 'Opening Activity');
                const mainActivitySection = this.findSectionByTitle(content, 'Main Activity');
                const closingActivitySection = this.findSectionByTitle(content, 'Closing Activity');
                const assessmentSection = this.findSectionByTitle(content, 'Assessment');

                this.introContent = this.findSectionContent(introSection);
                
                this.openingActivityContent = this.findSectionContent(openingActivitySection);
                this.mainActivityContent = this.findSectionContent(mainActivitySection);
                this.closingActivityContent = this.findSectionContent(closingActivitySection);
                this.assessmentContent = this.findSectionContent(assessmentSection);

                const selectedData = response.data.data.resource.selected_data;
                if (response.data.data.resource.payload.software) {
                    this.softwareName = response.data.data.resource.payload.software;
                } else {
                    this.softwareName = response.data.data.resource.raw_data[selectedData - 1]['core']['software'];
                }

                this.teacherModeId = response?.data?.data?.resource?.slides_id;
            } else {
                this.$emit('error', true);
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            this.$emit('error', true);
        } finally {
            this.$emit('loading', false);
        }
    },
    async fetchDataLessonPackage(id) {
        this.$emit('loading', true);
        try {
            const response = await LessonPackageService.getLessonPackage(id);
            if (response.status === 200) {
                
                const content = response.data.data.lesson_package.content;
                this.title = response.data.data.lesson_package.title;
                this.resource = response.data.data.resource;
                this.contentId = response.data.data.resource.lesson_package_id;

                const introSection = this.findSectionByTitle(content, 'Introduction');
                const openingActivitySection = this.findSectionByTitle(content, 'Opening Activity');
                const mainActivitySection = this.findSectionByTitle(content, 'Main Activity');
                const closingActivitySection = this.findSectionByTitle(content, 'Closing Activity');
                const assessmentSection = this.findSectionByTitle(content, 'Assessment');

                this.introContent = this.findSectionContent(introSection);
                this.openingActivityContent = this.findSectionContent(openingActivitySection);
                this.mainActivityContent = this.findSectionContent(mainActivitySection);
                this.closingActivityContent = this.findSectionContent(closingActivitySection);
                this.assessmentContent = this.findSectionContent(assessmentSection);

                const selectedData = response.data.data.resource.selected_data;
                if (response.data.data.resource.payload.software) {
                    this.softwareName = response.data.data.resource.payload.software;
                } else {
                    this.softwareName = response.data.data.resource.raw_data[selectedData - 1]['core']['software'];
                }

                this.teacherModeId = response?.data?.data?.resource?.slides_id;
            } else {
                this.$emit('error', true);
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            this.$emit('error', true);
        } finally {
            this.$emit('loading', false);
        }
    },
    async getAndOpenPDF() {
        try {
            this.buttonText = 'Loading...';
            
            const appUrl = process.env.VUE_APP_URL;
            const apiUrl = process.env.VUE_APP_API_URL;
            const apiEndpoint = `${apiUrl}/print?url=${appUrl}/print/${this.lessonPackageId}`;
            const response = await fetch(apiEndpoint);

            if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);

            window.open(pdfUrl, '_blank');
            this.buttonText = 'Print as PDF';
        } catch (error) {
            console.error('Error fetching PDF:', error);
            this.buttonText = 'Print as PDF';
        }
    },
    openTeacherMode() {
        window.open('https://slides.eddy4teachers.com/' + this.teacherModeId , "_blank");
    }
  },
};
</script>

<style>
.container {
    width: 80%;
    margin: 0 auto;
}

.header {
    font-family: 'League Spartan' !important;
    font-size: 1.7em;
    font-weight: 600;
    margin: 0.3em 0em;
    max-width: 66vw;
    word-wrap: break-word;
}

.sub-header {
    font-size: 1.3em;
    font-weight: 500;
}

.chip {
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-family: 'League Spartan';
    color: #003D6A;
    text-decoration: none;
}

.chip:not(:last-child) {
    padding-right: 0.5em;
    border-right: 2px solid #EAECF0;
}

.content {
    font-family: 'League Spartan';
    font-size: 16px;
}

.activity-table {
    width: 60%;
    border-collapse: collapse;
}

.activity-table th,
.activity-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.activity-table th {
    background-color: #f8f8f8;
}

.activity-table td:not(:first-child) {
    /* background-color: #f1f1f1; */
}

.activity-table th:first-child,
.activity-table td:first-child {
    /* background-color: #e7e7e7; */
}

.right-sidebar {
    font-family: 'League Spartan';
    display: flex;
    flex: 0 0 50px;
    justify-content: center; 
    border-left: 2px solid #D8DAE5;
    transition: flex-basis 0.5s ease;
}

.right-sidebar.is-open {
    font-family: 'League Spartan';
    flex: 0 0 250px;
}

.category-label {
    font-size: 1.2em; 
    font-weight: 700;
}

.category-chip {
    font-size: 0.7em; 
    padding: 0.1em; 
    border-radius: 2px;
}

.category-chip-child {
    background-color: rgb(234, 236, 240);
    padding: 0.3em;
    border-radius: 3px;
}

.standard-text {
    font-size: 1rem;
    font-weight: 400;
}

.button-blue {
    width: max-content; 
    padding: 0.625rem 1.25rem; 
    border-radius: 1rem; 
    background-color: #003D6A; 
    color: #ffffff; 
    line-height: 1.25rem; 
    text-align: center; 
    text-decoration: none;
}
</style>