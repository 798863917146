<template>
    <div class="input-group my-2">
        <label for="projectTitle">Project’s Name</label>
        <div class="preview-submit" style="font-weight: 600; font-size: 2em;">{{ localProjectData.title || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Project’s Image</label>
        <div class="preview-submit" style="display: flex; justify-content: center !important;">
            <img :src="`https://storage.eddy4teachers.com/${localProjectData.image_url}`" style="width: 50%;" alt="">
        </div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Hardware*</label>
        <div class="preview-submit">{{ localProjectData.hardware || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Software*</label>
        <div class="preview-submit">{{ localProjectData.software || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Project Background*</label>
        <div class="preview-submit">{{ localProjectData.background || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Project Details*</label>
        <div class="preview-submit">{{ localProjectData.details || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Potential Impact*</label>
        <div class="preview-submit">{{ localProjectData.impact || `(empty)` }}</div>
    </div>

    <div class="input-group mb-4">
        <label for="schematics">Schematics*</label>
        <FileUploadComponent v-if="!props.isPreview && !props.isConfirm" :defaultLabel="'View Schematics'" v-model:="localProjectData.schematics_url" :allowedTypes="['image/']" />
        <div v-else class="file-upload-wrapper">
            <template v-if="['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(localProjectData.schematics_url.split('.').pop().toLowerCase())">
                <img v-if="localProjectData.schematics_url" :src="`https://storage.eddy4teachers.com/${localProjectData.schematics_url}`" alt="Image Preview" style="width: 50%;" />
            </template>
            <template v-else>
                <div>
                    <a style="text-decoration: none;" :href="`https://storage.eddy4teachers.com/${projectData.schematics_url}`" target="_blank" class="btn-link color-primary">
                        View Schematics
                    </a>
                </div>
            </template>
        </div>
    </div>

    <div class="input-group mb-2">
        <label for="summary">Video Demo</label>
        <textarea :disabled="props.isPreview || props.isConfirm" id="summary" type="description" placeholder="Fill up the field" required v-model:="localProjectData.demo_url" />
    </div>

    <div class="input-group mb-4">
        <label for="schematics">Attachment*</label>
        <FileUploadComponent v-if="!props.isPreview && !props.isConfirm" :defaultLabel="'View Attachment'" :allowedTypes="'image/'" v-model="localProjectData.attachments_url" />
        <div v-else class="file-upload-wrapper">
            <template v-if="['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(localProjectData.attachments_url.split('.').pop().toLowerCase())">
                <img v-if="localProjectData.attachments_url" :src="`https://storage.eddy4teachers.com/${localProjectData.attachments_url}`" alt="Image Preview" style="width: 50%;" />
            </template>
            <template v-else>
                <a style="text-decoration: none;" :href="`https://storage.eddy4teachers.com/${projectData.attachments_url}`" target="_blank" class="btn-link color-primary">
                    View Attachment
                </a>
            </template>
        </div>
    </div>

    <div v-if="!props.isPreview && !props.isConfirm" style="display: flex;gap: 10px;justify-content: end;">
        <button @click="gotoStep(2)" class="button-competition-red" type="button">
            Previous Page
        </button>
        
        <button @click="updateVisibilityModalSave()" class="button button-email" type="button">
            Submit Page
        </button>                    
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import FileUploadComponent from '@/components/FileUploadComponent.vue';

const props = defineProps({
    projectData: {
        type: Object,
        required: true
    },
    isPreview: {
        type: Boolean,
        default: false
    },
    isConfirm: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update-project-data', 'go-to-step','update-visibility-modal-save']);
const localProjectData = ref({ ...props.projectData });

watch(localProjectData, (newValue) => {
    // Emit event dengan data terbaru setiap kali localProjectData berubah
    emit('update-project-data', newValue);
}, { deep: true });

function gotoStep(index) {
    emit('go-to-step', index);
}

function updateVisibilityModalSave() {
    emit('update-visibility-modal-save');
}
</script>