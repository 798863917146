<template>
    <v-row>
      <v-col cols="12" md="6">
        <BaseInput type="multiline" v-model="topic" :error="isTopicError" :errorMessage="topicErrorMessage" placeholder="Topic or a longer description of what you’re teaching (e.g. decimals, narrative writing, organic chemistry...)" label="‎" />
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="6">
            <BaseInput type="select" :options="gradeOptions" :maxHeight="'245'" v-model="grade" :error="isGradeError" :errorMessage="gradeErrorMessage" placeholder="Grade" label="Grade*"/>
          </v-col>
          <v-col cols="6">
            <BaseInput type="select" :options="subjectOptions" v-model="subject" :error="isSubjectError" :errorMessage="subjectErrorMessage" placeholder="Subject" label="Subject*"/>
          </v-col>
        </v-row>
        <v-row v-if="!showAdvancedOptions">
          <v-col cols="12" @click="toggleShowAdvancedOptions()">
            <div class="advanced-options">
              <div class="px-2">
                Advanced Options
              </div>
              <img src='./images/arrow-circle-left.svg'>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="showAdvancedOptions">
          <v-col cols="4">
            <BaseInput type="select" :options="techDomainOptions" :maxHeight="'155'" v-model="techDomain" placeholder="Tech Domain" label="Tech Domain"/>
          </v-col>
          <v-col cols="4">
            <BaseInput type="select" :options="softwareOptions" :maxHeight="'155'" v-model="software" placeholder="Software" label="Software"/>
          </v-col>
          <v-col cols="4">
            <BaseInput type="multipleSelect" :options="studentProfileOptions" :maxHeight="'155'" v-model="studentProfile" placeholder="Student Profile" label="Student Profile"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <button class="btn-main" :disabled="props.disableButton" style="width: 100%;" @click="emitGenerateClicked">Generate</button>
      </v-col>
    </v-row>
</template>

<script setup>
import { ref, defineEmits, onMounted, defineExpose, defineProps } from 'vue';
import BaseInput from '@/components/BaseInput.vue';
import TermService from '@/services/termService';
import { useStore } from 'vuex';
import { inject } from 'vue';
import LocalStorageService from '@/services/localStorageService';
import { watch } from 'vue';

// eslint-disable-next-line 
const props = defineProps({
  disableButton: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const topic = ref('');
const grade = ref('');
const subject = ref('');
const techDomain = ref('');
const software = ref('');
const studentProfile = ref([]);
const showAdvancedOptions = ref(false);

const gradeOptions = ref([]);
const subjectOptions = ref([]);
const techDomainOptions = ref([]);
const softwareOptions = ref([]);
const studentProfileOptions = ref([]);

const isTopicError = ref(false);
const isGradeError = ref(false);
const isSubjectError = ref(false);

const topicErrorMessage = ref('');
const gradeErrorMessage = ref('');
const subjectErrorMessage = ref('');

watch(() => store.state.formData, () => {
  mappingFormData();
});

const validateForm = () => {
  if (!topic.value) {
    isTopicError.value = true;
    topicErrorMessage.value = 'Topic is required';
  } else {
    isTopicError.value = false;
    topicErrorMessage.value = '';
  }

  if (!subject.value) {
    isGradeError.value = true;
    gradeErrorMessage.value = 'Grade is required';
  } else {
    isGradeError.value = false;
    gradeErrorMessage.value = '';
  }
  
  if (!subject.value) {
    isSubjectError.value = true;
    subjectErrorMessage.value = 'Subject is required';
  } else {
    isSubjectError.value = false;
    subjectErrorMessage.value = '';
  }  
};

const emit = defineEmits(['generate-clicked', 'reset-form']);

const toggleShowAdvancedOptions = () => {
  showAdvancedOptions.value = !showAdvancedOptions.value;
};  

const emitGenerateClicked = () => {
  validateForm();
  if (!isTopicError.value && !isGradeError.value && !isSubjectError.value) {
    const data = {
      topic: topic.value,
      grade_term_id: grade.value,
      grade_term_name : gradeOptions.value.find(o => o.value === grade.value) ?  gradeOptions.value.find(o => o.value === grade.value) ?.text : '',
      subject_term_id: subject.value,
      subject_term_name : subjectOptions.value.find(o => o.value === subject.value) ?  subjectOptions.value.find(o => o.value === subject.value) ?.text : '',
      domain_term_id: techDomain.value,
      domain_term_name : techDomainOptions.value.find(o => o.value === techDomain.value) ?  techDomainOptions.value.find(o => o.value === techDomain.value) ?.text : '',
      software_term_id: software.value,
      software_term_name : softwareOptions.value.find(o => o.value === software.value) ?  softwareOptions.value.find(o => o.value === software.value) ?.text : '',
      student_profile_term_ids: studentProfile.value,
    };

    if(studentProfile.value) {
      data.student_profile_term_name = [];
      studentProfile.value.forEach(items => {
        data.student_profile_term_name.push(studentProfileOptions.value.find(o => o.value === items) ?  studentProfileOptions.value.find(o => o.value === items) ?.text : '');
      });
    }
    // Simpan data ke Vuex store
    LocalStorageService.setFormData(data);
    store.commit('SET_FORM_DATA', data);
    emit('generate-clicked', data);
  }
};

const mappingFormData = () => {

  topic.value = store.state.formData.topic;
  grade.value = store.state.formData.grade_term_id;
  subject.value = store.state.formData.subject_term_id;
  techDomain.value = store.state.formData.domain_term_id;
  software.value = store.state.formData.software_term_id;
  studentProfile.value = store.state.formData.student_profile_term_ids;
};

const mappingNullFormData = () => {
  let storedData = store.state.formData;
    
    if (storedData !== null && storedData !== undefined) {
        const formData = LocalStorageService.getFormData();
        
        if(formData) {
            store.commit('SET_FORM_DATA', formData);
            storedData = store.state.formData;
        } 
    }
}

// eslint-disable-next-line
const resetForm = inject('resetForm', () => {
  topic.value = '';
  grade.value = '';
  subject.value = '';
  techDomain.value = '';
  software.value = '';
  studentProfile.value = [];
  showAdvancedOptions.value = false;
  isTopicError.value = false;
  isGradeError.value = false;
  isSubjectError.value = false;
  topicErrorMessage.value = '';
  gradeErrorMessage.value = '';
  subjectErrorMessage.value = '';
});

defineExpose({
  resetForm
});

onMounted(async () => {
  try {
    const gradesResponse = await TermService.getAllGrades();
    gradeOptions.value = gradesResponse.data.data.map(item => ({ text: item.title, value: item.id })); // Perbaikan pada variabel
    
    const subjectsResponse = await TermService.getAllSubjects();
    subjectOptions.value = subjectsResponse.data.data.map(item => ({ text: item.title, value: item.id })); // Perbaikan pada variabel
    mappingSubjects();

    const domainsResponse = await TermService.getAllDomains();
    techDomainOptions.value = domainsResponse.data.data.map(item => ({ text: item.title, value: item.id })); // Perbaikan pada variabel

    const softwaresResponse = await TermService.getAllSoftwares();
    softwareOptions.value = softwaresResponse.data.data.map(item => ({ text: item.title, value: item.id })); // Perbaikan pada variabel

    const profilesResponse = await TermService.getAllStudentProfiles();
    studentProfileOptions.value = profilesResponse.data.data.map(item => ({ text: item.title, value: item.id })); // Perbaikan pada variabel

    mappingNullFormData();
    mappingFormData();
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
});

const mappingSubjects = () => {
  const sortedSubjects = subjectOptions.value.sort((a, b) => {
    if (a.text === 'Other') return 1;
    if (b.text === 'Other') return -1;
    return a.text.localeCompare(b.text);
  });

  subjectOptions.value = sortedSubjects;
};  
</script>

<style>
.advanced-options {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  color: #003D6A;
}

.advanced-options:hover {
  border-color: #CCCCCC; /* Change border color to red */
  background-color: #EAECFF; /* Light red background */
}

</style>