<template>
        <div class="container d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="image-container">
                    <img height="220" src="/images/chinaus/submission-icon.svg" alt="Arrow Icon">
                </div>
                <div class="label-container">
                    <h2 class="label-card-chinaus">403 - No Authorization</h2>
                    <div>
                        You need login to open this page.
                    </div>
                    <div>
                        <a :href="`${appLoginUrl}/request-login`" class="button-login mt-4" style="text-decoration:none;">
                            Log in
                        </a>
                        <a :href="`${appLoginUrl}/login?tab=show-register`" class="button-signup mt-4 mx-2" style="text-decoration:none;"> 
                            Sign up 
                        </a>             
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>
import { computed } from 'vue';
const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);
</script>