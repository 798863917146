<template>
    <v-container v-if="isLoading" class="center-container">
        <div style="width: 100%;">
            <LoadingComponent></LoadingComponent>
        </div>
    </v-container>

    <div style="margin-top: 4em;"></div>
    <div style="margin-top: 4em;"></div>

    <GeneratedComponent ref="generatedComponentRef" v-show="!isLoading && !isError"  @loading="handleLoading" @error="handleError"></GeneratedComponent>

    <v-container v-show="!isLoading && isError">
        <ErrorGenerate @click="handleReload"></ErrorGenerate>
    </v-container>
</template>

<script setup>
import { defineEmits } from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GeneratedComponent from '@/components/GeneratedComponent.vue';
import { ref,onMounted } from 'vue';
import ErrorGenerate from '@/components/ErrorGenerate.vue';

const isLoading = ref(false);
const isError = ref(false);
const emit = defineEmits(['change-header-footer', 'fetch-data']);
const generatedComponentRef = ref(null);

const handleLoading = (isLoadingValue) => {
  isLoading.value = isLoadingValue;
};

const handleError = (isErrorValue) => {
    isError.value = isErrorValue;
};

const handleReload = () => {
    if (generatedComponentRef.value) {
        generatedComponentRef.value.fetchDataFromParent();
    }
};

onMounted(() => {
    // const route = useRoute();
    // const lessonPackageId = route.params.id;
    // console.log("ID Lesson Package:", lessonPackageId);
    emit('handleChangeFooterType', 'simplified-footer');
});
</script>