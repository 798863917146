<template>
    <BaseInput2 type="select" :options="users" @update:modelValue="handleUserChange" v-model="selectedMemberId"  placeholder="Team Leader" />

    <div v-if="selectedMemberData">
    <div class="input-group my-2">
        <label for="projectTitle">Name*</label>
        <div class="preview-submit">{{ `${selectedMemberData.user.firstname} ${selectedMemberData.user.lastname}` || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Phone Number*</label>
        <div class="preview-submit">{{ selectedMemberData.phone || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Email*</label>
        <div class="preview-submit">{{ selectedMemberData.user.email || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">Date of Birth*</label>
        <div class="preview-submit">{{ selectedMemberData.date_of_birth || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">City*</label>
        <div class="preview-submit">{{ selectedMemberData.state || `(empty)` }}</div>
    </div>

    <div class="input-group my-2">
        <label for="projectTitle">State*</label>
        <div class="preview-submit">{{ selectedMemberData.city || `(empty)` }}</div>
    </div>

    <div v-if="!props.isConfirm">
    <div class="default-flex my-2 fs-08" :class="{ 'input-group checkbox-error': checkbox1error, 'input-group': !checkbox1error }" for="checkbox1" style="gap: 10px;">
        <input type="checkbox" :class="{ 'checkbox-error': checkbox1error }" id="checkbox1" v-model="checkbox1">
        <label :class="{ 'checkbox-error': checkbox1error, 'preview-submit': !checkbox1error }" for="checkbox1" style="margin-top: 0.25em;">I agree to the applicable privacy policy for the Competition</label>
    </div>

    <div class="default-flex my-2 fs-08" :class="{ 'input-group checkbox-error': checkbox2error, 'input-group': !checkbox2error }" for="checkbox1" style="gap: 8px;">
        <input type="checkbox" :class="{ 'checkbox-error': checkbox2error }" id="checkbox2" v-model="checkbox2">
        <label :class="{ 'checkbox-error': checkbox2error, 'preview-submit': !checkbox2error }" for="checkbox2" style="margin-top: 0.25em;">I agree to the promotional release for the Competition</label>
    </div>

    <div class="default-flex my-2 fs-08" :class="{ 'input-group checkbox-error': checkbox3error, 'input-group': !checkbox3error }" for="checkbox1" style="gap: 8px;">
        <input type="checkbox" :class="{ 'checkbox-error': checkbox3error }" id="checkbox3" v-model="checkbox3">
        <label :class="{ 'checkbox-error': checkbox3error, 'preview-submit': !checkbox3error }" for="checkbox3" style="margin-top: 0.25em;">I agree that this work is my own, and was not copied from another groups</label>
    </div>
    </div>

    <!-- :class="{ 'button-competition-loading': props.isLoading, 'button-competition': !props.isLoading }" -->

    <div v-if="!props.isConfirm" style="display: flex;gap: 10px;justify-content: end;">
        <button @click="gotoStep(2)" class="button-competition-red" type="button">
            Previous Page
        </button>
        
        <button @click="updateVisibilityModalSave()" class="button button-email" type="button">
            Submit Page
        </button>                    
    </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from 'vue';
import BaseInput2 from '@/components/BaseInput2.vue';

const users = ref([]);
const selectedMemberId = ref(null);
const selectedMemberData = ref(null);
const emit = defineEmits(['go-to-step','update-visibility-modal-save']);
const checkbox1 = ref(false);
const checkbox2 = ref(false);
const checkbox3 = ref(false);

const checkbox1error = ref(false);
const checkbox2error = ref(false);
const checkbox3error = ref(false);

const props = defineProps({
    teamData: {
        type: Object,
        required: true
    },
    isConfirm: {
        type: Boolean,
        default: false
    }
});

watch(checkbox1, (newVal) => {
  if (newVal) {
    checkbox1error.value = false;
  }
});

watch(checkbox2, (newVal) => {
  if (newVal) {
    checkbox2error.value = false;
  }
});

watch(checkbox3, (newVal) => {
  if (newVal) {
    checkbox3error.value = false;
  }
});

onMounted(() => {
  extractUsersFromTeam(props.teamData);
});


function extractUsersFromTeam(teamData) {
    if (teamData.leader && teamData.leader.user) {
        users.value.push({
            value: teamData.leader.user.user_id, 
            user_id: teamData.leader.user.user_id,
            firstname: teamData.leader.user.firstname,
            lastname: teamData.leader.user.lastname,
            text: `${ teamData.leader.user.firstname ? teamData.leader.user.firstname : '' } ${ teamData.leader.user.lastname ? teamData.leader.user.lastname : '' }`,
        });
        selectedMemberId.value = teamData.leader.user.user_id;
        handleUserChange(teamData.leader.user.user_id);
    }

    // Menambahkan setiap member ke array
    teamData.members.forEach(member => {
        if (member.user) {
            users.value.push({
                value: member.user.user_id, 
                user_id: member.user.user_id,
                firstname: member.user.firstname,
                lastname: member.user.lastname,
                text: `${ member.user.firstname ? member.user.firstname : '' } ${ member.user.lastname ? member.user.lastname : '' }`,
            });
        }
    });
}

const handleUserChange = (selectedUser) => {
    if(props.teamData.leader.user_id === selectedUser) {
        selectedMemberData.value = props.teamData.leader;
    } else {
        const selectedMemberIndex = props.teamData.members.findIndex(member => member.user_id === selectedUser);
        
        if (selectedMemberIndex !== -1) {
            selectedMemberData.value = props.teamData.members[selectedMemberIndex];
        } else {
            console.log('No member found with user_id:', selectedUser);
        }
    }
}

function gotoStep(index) {
    emit('go-to-step', index);
}

function checkboxValidation() {
    if (!checkbox1.value) {
        checkbox1error.value = true;
    }
    if (!checkbox2.value) {
        checkbox2error.value = true;
    }
    if (!checkbox3.value) {
        checkbox3error.value = true;
    }

    if(checkbox1.value && checkbox2.value && checkbox3.value) {
        return true;
    } else {
        return false;
    }
}

function updateVisibilityModalSave() {
    if (checkbox1.value && checkbox2.value && checkbox3.value) {
        emit('update-visibility-modal-save');
    } else {
        checkboxValidation();
    }
}
</script>

<style>
.checkbox-error {
    color: red !important;
}

.input-group.checkbox-error input[type="checkbox"] {
    border: 1px solid red;
}
</style>