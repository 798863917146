<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <!-- Fill in capital here -->
            <div class="container-modal">
                <div class="modal-title">Your order in progress</div>
                <div class="border-header"></div>
                <div class="modal-icon">
                    <img src="/images/maker-cart/ok-bro.svg" alt="Save Icon" style="height: 260px;">
                    <!-- Update the src as per your image path -->
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    <h1 class="color-red">Order Processed!</h1>
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    Please check your email for confirmation of your quote request. We’ll get back via email in 24 hours!
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;margin-bottom: 20px;">
                    If you would like to speak to us before then, don’t hesitate to email hello@edm8ker.com 
                </div>

                <div class="modal-button">
                    <button @click="updateVisibilityModal(false)" class="btn btn-save" style="padding: 0.5em 1em; min-width: 100px;">
                        Confirm
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';

const showModal = ref(false);

function updateVisibilityModal(visible) {
    showModal.value = visible;
}

defineExpose({
    updateVisibilityModal
});
</script>

<style>

</style>
