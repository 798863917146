// src/services/userService.js
import http from './httpService';

class UsStateService {
  async getAllStates() {
    return await http.get('/us-states');
  }
  
  async getCityByState(state) {
    return await http.get(`/us-states?state=${state}`);
  }
}

export default new UsStateService();
