<template>
    <v-container v-if="isLoading" class="center-container">
        <div style="width: 100%;">
            <LoadingComponent></LoadingComponent>
        </div>
    </v-container>

    <v-container v-else-if="!isLoading">
        <div style="margin-bottom: 1.5em;">
            <PhaseComponent :selectedNumber="generatePhase" @phase-clicked="handlePhaseClick"></PhaseComponent>
        </div>
        <v-row class="justify-center">
            <v-col cols="12" md="8">
                <PromptEditor></PromptEditor>
            </v-col>
        </v-row>
    </v-container>

    <GeneratedComponent ref="generatedComponentRef" v-show="!isLoading && !isError" @loading="handleLoading" @error="handleError"></GeneratedComponent>

    <v-container v-show="!isLoading && isError">
        <ErrorGenerate @click="handleReload"></ErrorGenerate>
    </v-container>
</template>

<script setup>
import { defineEmits } from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GeneratedComponent from '@/components/GeneratedComponent.vue';
import PhaseComponent from '@/components/PhaseComponent.vue';
import PromptEditor from '@/components/PromptEditor.vue';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ErrorGenerate from '@/components/ErrorGenerate.vue';

const router = useRouter();
const route = useRoute();
const generatePhase = ref('1');
const isLoading = ref(false);
const isError = ref(false);
const emit = defineEmits(['change-header-footer', 'fetch-data']);
const generatedComponentRef = ref(null);

const setGeneratePhase = (newPhase) => {
    generatePhase.value = newPhase;
};

const handleLoading = (isLoadingValue) => {
  isLoading.value = isLoadingValue;
};

const handleError = (isErrorValue) => {
    isError.value = isErrorValue;
};

const handleReload = () => {
    if (generatedComponentRef.value) {
        generatedComponentRef.value.fetchDataFromParent();
    }
};

const decryptDataFromURL = () => {
    const encryptedString = route.query.data;
    if (!encryptedString) {
        router.push({ path: '/', query: { key: 'reset' } });
        return;
    }
    
    const decryptedString = decodeURIComponent(encryptedString);
    // eslint-disable-next-line
    const redirectObject = JSON.parse(decryptedString);
};

onMounted(() => {
    decryptDataFromURL();
    setGeneratePhase(3);
    emit('handleChangeFooterType', 'simplified-footer');
});

const handlePhaseClick = (phaseNumber) => {
    if(phaseNumber === 2) {
        router.push({ path: '/', query: { key: 'step-2' } });
    } else if (phaseNumber === 1) {
        router.push({ path: '/', query: { key: 'reset' } });
    }
};
</script>
