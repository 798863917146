<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/submission-background.svg" alt="Arrow Icon"
                        class="responsive-image-submission-page" />
                </div>
                <div class="label-cover">
                    <div class="white-backdrop">
                        Submission
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NavigationBarComponent id="navigation-bar"></NavigationBarComponent>

    <div class="welcome-page-4-dsa welcome-page-5 pb-5">
        <div class="container d-flex justify-center align-center pt-10">
            <div class="progress-step-container">
                <div class="progress-bar-step">
                    <div @click="goToStep(1)" class="progress-circle completed">
                        <div>Team Info</div>
                    </div>
                    <div class="progress-bar-line" :class="{ 'filled': currentStep > 1 }"></div>
                    <div @click="goToStep(2)" class="progress-circle" :class="{ 'completed': currentStep >= 2 }">
                        <div>Project Information</div>
                    </div>
                    <div class="progress-bar-line" :class="{ 'filled': currentStep > 2 }"></div>
                    <div @click="goToStep(3)" class="progress-circle" :class="{ 'completed': currentStep >= 3 }">
                        <div>Review and Submit</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="errorMessage.length > 0" class="container d-flex justify-center align-center" style="padding: 2em 4em 0em 4em;">
            <ul class="file-upload-wrapper">
                <li v-for="item in errorMessage" :key="item">{{ item }}</li>
            </ul>
        </div>

        <!-- Content for step 2 -->
        <div v-if="currentStep === 1">
            <TeamInformationFormPage :teamData="teamData" :isLoading="isLoading" @go-to-step="goToStep"
                @save-submission="saveSubmission" />
        </div>

        <!-- Content for step 2 -->
        <div v-else-if="currentStep === 2">
            <ProjectInformationFormPage :projectData="projectData" :isLoading="isLoading" @update-project-data="updateProjectData"
                @go-to-step="goToStep" @save-submission="saveSubmission" />
        </div>

        <!-- Content for step 3 -->
        <div v-else-if="currentStep === 3">
            <div class="container d-flex justify-center align-center py-5">
                <div class="card-chinaus" style="background-color: #F2F3FB;border-radius: 25px;">
                    <div class="label-container">
                        <h2 class="label-card-chinaus">Review and Submit</h2>

                        <div class="tabs scroll-container my-4">
                            <span @click="goToTabReview(1)" class="tab"
                                :class="{ 'tab-chinaus-active-primary': currentTabReview == 1 }">Team
                                Information</span>
                            <span @click="goToTabReview(2)" class="tab"
                                :class="{ 'tab-chinaus-active-primary': currentTabReview == 2 }">Project
                                Information</span>
                        </div>

                        <div v-if="currentTabReview == 1">
                            <TeamInformationPreviewPage :teamData="teamData" @go-to-step="goToStep" @update-visibility-modal-save="updateVisibilityModalSave"/>
                        </div>
                        <div v-else-if="currentTabReview == 2">
                            <ProjectInformationPreviewPage :projectData="projectData" @update-project-data="updateProjectData" @go-to-step="goToStep" @update-visibility-modal-save="updateVisibilityModalSave"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalComponent :visible="showModalSave" @update:visible="updateVisibilityModalSave()">
        <!-- Fill in capital here -->
        <div class="container-modal">
            <div class="modal-title">Submit your project?</div>
            <div class="border-header"></div>
            <div class="modal-icon">
                <img src="/images/chinaus/confirmed-bro-1.svg" alt="Save Icon" style="height: 260px;">
                <!-- Update the src as per your image path -->
            </div>
            <div style="font-family: 'League Spartan';display: flex;justify-content: center;font-size: 1em;margin-top: 20px">You can still access and edit your submission until the application window closes.</div>

            <div class="modal-button">
                <button class="btn btn-discard" style="min-width: 100px;" @click="updateVisibilityModalSave">Back</button>
                <button class="btn" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" style="padding: 0.5em 1em; min-width: 100px;" @click="saveSubmission(true)">
                    Confirm
                </button>
            </div>
        </div>
    </ModalComponent>

</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router'; // Import useRoute
import TeamInformationFormPage from '@/views/ChinaUs/SubmissionSubmit/TeamInformationFormPage.vue';
import ProjectInformationFormPage from '@/views/ChinaUs/SubmissionSubmit/ProjectInformationFormPage.vue';
import TeamInformationPreviewPage from '@/views/ChinaUs/SubmissionSubmit/TeamInformationPreviewPage.vue';
import ProjectInformationPreviewPage from '@/views/ChinaUs/SubmissionSubmit/ProjectInformationPreviewPage.vue';
import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';
import cookiesService from '@/services/cookiesService';
import ChinaUsService from '@/services/chinausService.js';
import ModalComponent from '@/components/ModalComponent.vue';

const router = useRouter();

const teamData = ref({
    leader: {
        user_id: null,
        phone: "",
        date_of_birth: "",
        state: "",
        city: "",
        parental_consent_url: ""
    },
    members: [
        {
            user_id: null,
            phone: "",
            date_of_birth: "",
            state: "",
            city: ""
        },
    ]
});

const errorMessage = ref([]);
const isLoading = ref(false);

const AppState = Object.freeze({
    isAdminorYSA: 'isAdminorYSA',
    isFormExist: 'isFormExist',
    isNoForm: 'isNoForm',
    isSubmitted: 'isSubmitted'
});

const currentAppState = ref(AppState.isAdminorYSA);
const showModalSave = ref(false);

const getSubmissionForm = async () => {
    try {
        const response = await ChinaUsService.getSubmissionForm();

        if (response.status === 200) {
            if (response.data.message === "This user has no submission") {
                currentAppState.value = AppState.isNoForm;
            } else if (response.data.message === "Submission form retrieved successfully") {
                currentAppState.value = AppState.isFormExist;
                if (response.data.data) {
                    Object.assign(teamData.value.leader, response.data.data.leader);
                    Object.assign(teamData.value.members, response.data.data.members);
                    projectData.value = response.data.data.project;
                }
            }
        } else if (response.status === 403) {
            router.push({ path: `/china-us/submission` });
        }
    } catch (error) {
        console.log(error.message);
    }
};

const currentUser = ref(null);

const checkAuthToken = async () => {
    try {
        const authToken = await cookiesService.getCookie('authToken');
        if (authToken) {
            currentUser.value = authToken;
        }
    } catch (error) {
        console.error(error);
    }
};

checkAuthToken();

const currentStep = ref(1);
const currentTabReview = ref(1);

const projectData = ref({
    title: "",
    image_url: "",
    summary: "",
    hardware: "",
    software: "",
    background: "",
    details: "",
    impact: "",
    schematics_url: "",
    demo_url: "",
    attachments_url: "",
    additional_info: ""
});

const updateProjectData = (updatedData) => {
    projectData.value = updatedData; // Update data asli
}

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 540) {
        height = width * (1500 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 540) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
}

onMounted(async () => {
    await checkAuthToken();

    teamData.value.leader.user_id = currentUser.value.user_id;
    adjustHeight();
    setBackgroundImage();
    setBackgroundPosition();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse.svg")`;
    }

    await getSubmissionForm();
});

function setBackgroundPosition() {
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');
    const width = window.innerWidth;

    if (width <= 540) {
        welcomePageElement.style.backgroundPosition = `right top`;
    } else {
        welcomePageElement.style.backgroundPosition = `right top`;

    }

}

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}

const goToStep = (step) => {
    currentStep.value = step;
};

const goToTabReview = (step) => {
    currentTabReview.value = step;
};

function validateMembers() {
    teamData.value.members.forEach((member, index) => {
        if (member.user_id === null || member.user_id === '') {
            teamData.value.members.splice(index, 1);
        }
    });
}

async function saveSubmission(is_finished = false) {
    try {

        isLoading.value = true;
        validateMembers()
        const data = {
            "is_finished": is_finished,
            "team": teamData.value,
            "project": projectData.value,
        }
        
        const response = await ChinaUsService.postSubmissionForm(data);

        if (response.status === 200) {
            showModalSave.value = false;
            isLoading.value = false;
            if(!is_finished) {
                location.reload();
            } else {
                router.push({ path: `/china-us/submission-review/${response.data.data.project.id}` });
            }
        } else if (response.status === 400) {
            showModalSave.value = false;
            isLoading.value = false;
            if(response.data) {
                errorMessage.value = response.data.error;
            }
            scrollToNavigationBar();
        }
    } catch (error) {
        showModalSave.value = false;
        isLoading.value = false;
        errorMessage.value = error.message;
        scrollToNavigationBar();
    }
}

function updateVisibilityModalSave() {
    showModalSave.value = !showModalSave.value
}

function scrollToNavigationBar() {
    const navBar = document.getElementById('navigation-bar');
    if (navBar) {
        navBar.scrollIntoView({ behavior: 'smooth' });
    }
}
</script>

<style>
.card-chinaus-accordion {
    font-family: 'League Spartan';
    display: flex;
    gap: 1em;
    background-color: rgba(242, 243, 251, 0.55);
    padding: 2em 2em;
    border-radius: 15px;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
}

.button-email-ri {
    background-color: white;
    color: #00925E;
    border: 0.8px solid #00925E !important;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-weight: 550;
}

.button-email-ri img {
    width: 18px;
    height: auto;
}

.welcome-page-4-dsa {
    background-repeat: no-repeat;
}

.accordion-eqdsad {
    font-size: 1.2em;
    font-weight: 500;
}

.progress-step-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.progress-bar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
}

.step {
    text-align: center;
    width: 33%;
}

.step.completed {
    font-weight: bold;
    color: #00925E;
    /* or any color to indicate completion */
}

.progress-bar-step {
    display: flex;
    align-items: center;
    width: 100%;
}

.progress-circle {
    background-color: #ccc;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.progress-circle div {
    position: absolute;
    display: block;
    bottom: 30px;
    width: 9em;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 14px;
    font-family: 'League Spartan' !important;
    color: #ccc;
}

.progress-circle.completed {
    background-color: #00925E;
}

.progress-circle.completed div {
    color: #00925E;
}

.progress-bar-line {
    height: 3px;
    flex-grow: 1;
    background-color: #ccc;
    position: relative;
}

.progress-bar-line.filled {
    background-color: #00925E;
}

.filter-brown {
    filter: invert(92%) sepia(1%) saturate(8%) hue-rotate(324deg) brightness(90%) contrast(91%);
}

.preview-submit {
    color: #003D6A !important;
    font-size: 0.9em;
}
</style>