// src/services/ChinaUsService.js

class LessonPlanService {
  // eslint-disable-next-line
  async getAllSubmission(page, sort) {
    // Data dummy yang diminta
    const dataDummy = {
      status: 200,
      data: {
      data: {
        search: "",
        page: page,
        limit: 10,
        total_rows: 20,
        total_pages: 2,
        rows: [
          {
            Status: "Published",
            Title: "Beats & Brushes: Painting Music with Hyperscore",
            Grade: "4th Grade, 5th Grade, 6th Grade",
            Subject: "Music",
            Domain: "Multimedia and Animation",
            Action: {
              edit: "https://eddy4teachers.com/admin/lesson/edit/2212",
              copy: "2212",
              delete: "2212",
            },
          },
          {
            Status: "Published",
            Title: "Bite-Size Writing: The Hamburger Method",
            Grade: "2nd Grade",
            Subject: "ELA",
            Domain: "Multimedia and Animation",
            Action: {
              edit: "https://eddy4teachers.com/admin/lesson/edit/2190",
              copy: "2190",
              delete: "2190",
            },
          },
          {
            Status: "Published",
            Title: "Facial Advocacy: Designing Climate Awareness Filters",
            Grade: "6th Grade, 7th Grade",
            Subject: "Social Studies",
            Domain: "App Development",
            Action: {
              edit: "https://eddy4teachers.com/admin/lesson/edit/2188",
              copy: "2188",
              delete: "2188",
            },
          },
          // Data lainnya seperti yang diminta
        ],
      },
    }
    };

    // Simulasi delay untuk meniru request ke server
    await new Promise((resolve) => setTimeout(resolve, 500));

    return dataDummy;
  }

  // eslint-disable-next-line
  async deletSubmission(id) {
    // Data dummy untuk response hapus
    const responseDummy = {
      status: 200,
      message: "Submission deleted successfully",
    };

    // Simulasi delay untuk meniru request ke server
    await new Promise((resolve) => setTimeout(resolve, 500));

    return responseDummy;
  }
}

export default new LessonPlanService();
