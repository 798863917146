<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <!-- Fill in capital here -->
            <div class="container-modal">
                <div class="modal-title">Add more activities</div>
                <div class="border-header"></div>
                <div class="modal-icon">
                    <img src="/images/maker-cart/ok-bro.svg" alt="Save Icon" style="height: 260px;">
                    <!-- Update the src as per your image path -->
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    <h1 class="color-red">A minimum of four Maker Activities are</h1>
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    <h1 class="color-red">required to request a quote.</h1>
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    If you have a special request, please email our team directly to request a custom quote.
                </div>

                <div class="modal-button">
                    <button @click="updateVisibilityModal(false)" class="btn btn-save" style="padding: 0.5em 1em; min-width: 100px;">
                        Confirm
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';

const showModal = ref(false);

function updateVisibilityModal(visible) {
    showModal.value = visible;
}

defineExpose({
    updateVisibilityModal
});
</script>

<style>

</style>
