class LocalStorageService {
  setItem(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  }

  getItem(key) {
    const value = sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  
  removeItem(key) {
    sessionStorage.removeItem(key);
  }
  
  clear() {
    sessionStorage.clear();
  }

  resetFormData() {
    sessionStorage.removeItem('form-data');
  }

  getFormData() {
    const value = sessionStorage.getItem('form-data');
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  setFormData(value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
      sessionStorage.setItem('form-data', value);
    }
  }

  resetFormResults() {
    sessionStorage.removeItem('form-results');
  }

  getFormResults() {
    const value = sessionStorage.getItem('form-results');
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  setFormResults(value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
      sessionStorage.setItem('form-results', value);
    }
  }
  
  setCacheWithExpiry(key, value, expiryMinutes = 20) {
    const now = new Date();
    const item = {
      value: typeof value === 'object' ? JSON.stringify(value) : value,
      expiry: now.getTime() + expiryMinutes * 60000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  
  getCache(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    this.clearCache(key);
    return JSON.parse(item.value);
  }
  
  clearCache(key) {
    localStorage.removeItem(key);
  }
  
  displayCachedData(key) {
    const data = this.getCache(key);
    if (data) {
      console.log(data);
      this.clearCache(key);
    } else {
      localStorage.log("Data tidak ditemukan atau sudah expired.");
    }
  }  
}

export default new LocalStorageService();