<template>
    <div class="edit-prompt">
        <div class="edit-prompt-row" @click="toggleExpand">
            <div class="px-2" >
                Open Prompt Editor
            </div>
            <img :class="{ 'rotate': isExpanded }" src='./images/arrow-circle-left.svg'>
        </div>
        <div ref="expandableContent" class="expandable-content" :style="{ height: contentHeight }" @mouseenter="showOverlay" @mouseleave="hideOverlay">
            <div class="overlay my-4" v-show="isOverlayVisible" @click.stop="toggleSection">
                <div class="overlay-content">Modify This Prompt</div>
            </div>
            <PromptPreview v-if="showPromptPreview" @generate-clicked="checkMultipleSelectValue"></PromptPreview>
        </div>
        <div v-if="isSectionVisible" class="new-section">
            <PromptForm  @generate-clicked="checkMultipleSelectValue"></PromptForm>
        </div>
    </div>
</template>

<script>
import PromptPreview from '@/components/PromptPreview.vue';
import PromptForm from '@/components/PromptForm.vue';

export default {
    components: {
        PromptPreview,
        PromptForm
    },
    data() {
        return {
            isExpanded: false,
            contentHeight: '0px',
            isOverlayVisible: false,
            isSectionVisible: false,
            showPromptPreview: true,
        };
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
            this.updateContentHeight();
            if (!this.isExpanded) {
                this.isSectionVisible = false; // Pastikan section baru disembunyikan saat expandable content ditutup
                this.showPromptPreview = true; // Pastikan PromptPreview ditampilkan lagi
            }
        },
        updateContentHeight() {
            this.$nextTick(() => {
                if (this.isExpanded) {
                    const content = this.$refs.expandableContent;
                    if(content) {
                        this.contentHeight = `${content.scrollHeight}px`;
                    }
                } else {
                    this.contentHeight = '0px';
                }
            });
        },
        showOverlay() {
            this.isOverlayVisible = true;
        },
        hideOverlay() {
            this.isOverlayVisible = false;
        },
        toggleSection() {
            this.isSectionVisible = !this.isSectionVisible;
            this.showPromptPreview = !this.showPromptPreview;
            this.isExpanded = false;
            this.contentHeight = '0px';
        },
        checkMultipleSelectValue() {
            this.$router.push({ path: '/', query: { key: 'step-2' } });
        }
    }
};
</script>

<style>
    .edit-prompt {
        background-color: #FFF;
        border: 0.5px solid #D8DAE5;
        border-radius: 10px;
        font-size: 16px;
        font-family: 'League Spartan', sans-serif;
        padding: 10px 20px;
        cursor: pointer;
        color: #003D6A;
        transition: all 2s ease;
    }
    .edit-prompt-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-weight: 600;
    }
    .rotate {
        transform: rotate(180deg);
        transition: transform 0.5s ease;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 61, 106, 0.5);
        opacity: 0;
        transition: opacity 0.5s ease;
        z-index: 9999;
        border-radius: 10px;
    }

    .expandable-content:hover .overlay {
        opacity: 1; /* Munculkan overlay saat di-hover */
    }
    .expandable-content {
        position: relative; /* Tambahkan ini */
        transition: height 0.5s ease;
        overflow: hidden;
        height: 0; /* Default height */
    }
    .edit-prompt .expandable-content {
        max-height: 500px; /* Sesuaikan dengan kebutuhan */
    }
    .overlay-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF; /* Atur warna teks */
        font-weight: bold; /* Atur ketebalan teks */
        z-index: 10000; /* Pastikan teks muncul di atas overlay */
        background-color: transparent; /* Pastikan latar belakang teks transparan */
        padding: 10px; /* Atur padding jika perlu */
        border-radius: 5px; /* Opsional: Tambahkan border-radius untuk estetika */
        text-align: center; /* Pastikan teks terpusat */
    }
</style>
