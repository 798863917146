<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <!-- HERO SECTION -->
            <div id="dynamicHeightDiv" style="    background-color: white;" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/summary-asset.svg" alt="China Us Background" class="responsive-image" />
                </div>
                <div class="label-cover-2">                
                    <div class="label-cover">
                        <img src="/images/chinaus/img-top.svg" alt="Arrow Icon" class="clickable-image china-us-logo" />
                    </div>
                    <div class="black-backdrop">
                        China-U.S. Young Maker Competition
                    </div>
                    <div class="black-backdrop">
                        US Division Semifinals
                    </div>
                    <div>
                        <div class="black-backdrop-mini" style="line-height: 0.9;"> 
                            Host: 
                        </div>
                        <div class="black-backdrop-mini-2">
                            Ministry of Education of the People’s Republic of China
                        </div>
                    </div>
                    <div>
                        <div class="black-backdrop-mini">
                            Organizers:
                        </div>                 
                        <div>
                            <img src="/images/chinaus/img-1.svg" alt="Arrow Icon" class="clickable-image organizer-logo mx-2" />
                            <img src="/images/chinaus/img-2.svg" alt="Arrow Icon" class="clickable-image organizer-logo mx-2" />
                            <img src="/images/chinaus/img-3.svg" alt="Arrow Icon" class="clickable-image organizer-logo mx-2" />
                            <img src="/images/chinaus/img-4.svg" alt="Arrow Icon" class="clickable-image organizer-logo mx-2" />
                            <img src="/images/chinaus/img-5.svg" alt="Arrow Icon" class="clickable-image organizer-logo mx-2" />
                        </div>
                    </div>
                    <div>
                        <div class="black-backdrop-mini" style="line-height: 1.6;"> 
                            US Semifinals Competition Organizer: 
                        </div>                  
                        <div>
                            <img src="/images/chinaus/edm8kerlogo.svg" alt="Arrow Icon" class="clickable-image organizer-logo" />
                        </div>   
                    </div>                 
                </div>
            </div>
        </div>
    </div>

    <NavigationBarComponent></NavigationBarComponent>

    <div class="welcome-page-4 welcome-page-5">
        <div class="container-china-us d-flex justify-center align-center py-5">
            <!-- Winner Section -->
            <div class="card-chinaus" style="flex-direction: column; text-align: center; display: flex; align-items: center;">
                 <!-- Winner Section Label -->
                <div class="label-container" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <img class="winner-section-label" src="/images/chinaus/winner/asset_1.svg" alt="Trophy Icon" style="width: 200px; height: 200px;">
                    <h2 class="label-card-chinaus-2">Congratulations to all Finalists!!</h2>
                    <img class="winner-section-label" src="/images/chinaus/winner/asset_2.svg" alt="Medal Icon" style="width: 200px; height: 200px;">
                </div>
                <div style="font-size: 1.4em;">
                    Each team will be awarded an expenses-paid trip to China to take part in the Grand Finals.
                </div>
                <div class="grid-container-zz">
                    <a v-for="(item, index) in gridItems" :key="index" :href="`/china-us/submission-detail/${item.project.id}`" :class="['grid-item']">
                        <img height="35px" width="35px" class="ysa-winner-icon" src="/images/chinaus/winner/medal.svg">
                        <img class="grid-item-thumnail" :src="`https://storage.eddy4teachers.com/${item.project.image_url}`" onerror="this.onerror=null; this.src='/images/chinaus/submission-list-default.png'" alt="Project Image">
                        <div class="caption-1">{{ item.project.title }}</div>
                    </a>
                </div>
            </div>
        </div>


        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus" style="flex-direction: column; text-align: center;">
                <div class="label-container" style="display: flex; justify-content: space-between; flex-direction: column;">
                <h2 class="label-card-chinaus">Find Out More!</h2>
                <div>Watch our webinar recording for more information!</div>
                </div>
                <div id="image-container-3" class="image-container" style="order: 1;margin-bottom: 50px;">
                    <iframe id="responsive-iframe" style="border-radius: 15px;" src="https://drive.google.com/file/d/1FQKwScXyo6kcC24PzGnfIwX6cRLq9kFW/preview" allow="autoplay"></iframe>
                </div>
            </div>
        </div>

        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="image-container">
                    <img class="card-image-china-us" src="/images/checklist-bro-2.svg" alt="Eligibility">
                </div>
                <div class="label-container">
                    <h2 class="label-card-chinaus">Eligibility</h2>
                    <ul>
                        <li>Projects should address a pressing challenge facing your community, or meet a societal need.
                        </li>
                        <li>Open to a broad range of hardware & software-enabled projects that have not been
                            commercialized.
                        </li>
                        <li>Open to students and makers aged 16-40.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="label-container right-left">
                    <h2 class="label-card-chinaus">Prizes</h2>
                    <div>Top 10 finalists win a <b>paid trip to China</b> to compete in the Grand Finals. This will include:</div>
                    
                    <ul>
                        <li>Opportunities to network with Makers in China and the US</li>
                        <li>Cultural immersion to savor the rich history and culture of China, the host country for the China-US Young Maker Competition</li>
                    </ul>
                    <div style="margin-top: 10px;">Additionally, four Special Mention prizes ($50 in gift vouchers per prize) will be awarded to projects outside the top 10 finalists.</div>
                </div>
                <div class="image-container" style="order: 1">
                    <img class="card-image-china-us" src="/images/character-2.svg" alt="Prizes">
                </div>
            </div>
        </div>

        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="image-container">
                    <img class="card-image-china-us" src="/images/files-sent-bro-2.svg" alt="Timeline">
                </div>
                <div class="label-container">
                    <h2 class="label-card-chinaus">Timeline</h2>
                    <ul>
                        <li>Submissions <b>25 May</b>, and close [UPDATED] <b>June 30, 23:59 (PST).</b>
                        </li>
                        <li>Early bird submission deadline: <b>10 June</b></li>
                        <li>Results of <b>US semifinals</b> to be <b>announced by end-June.</b>
                        </li>
                        <li><b>Grand finals</b> will be held in <b>5-11 August.</b></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus mb-10">
                <div class="label-container right-left">
                    <h2 class="label-card-chinaus">Bonus Support</h2>
                    <ul>
                        <li>Info sessions and office hours with Intel team members to enhance submission quality - dates
                            TBC.
                        </li>
                        <li>Details of previous winners can be found <a target="_blank"
                                href="https://www.hackster.io/contests/2022chinausyoungmakercompetition">here.</a> </li>
                    </ul>
                </div>
                <div class="image-container" style="order: 1">
                    <img class="card-image-china-us" src="/images/active-support-bro.svg" alt="Bonus Support">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';
// import { useRouter } from 'vue-router';

// const router = useRouter();

const gridItems = ref([
    {
        "project": {
            "id": 87,
            "team_id": 88,
            "unique_code": "20240623144339-1829",
            "title": "Use 3D printing to make every good thing in the countryside within reach.",
            "image_url": "files/ymc/images/20240623143023_7.logo.png",
            "schematics_url": "files/ymc/images/20240623144048_power_point.pdf",
        }
    },
    {
        "project": {
            "id": 83,
            "team_id": 83,
            "unique_code": "20240619192415-1810",
            "title": "Career Builders",
            "image_url": "files/ymc/images/20240619183635_Screenshot_2024-06-19_at_2.36.25 PM.png",
            "schematics_url": "files/ymc/images/20240619192344_Screenshot_2024-06-19_at_3.23.34 PM.png",
        }
    },
    {
        "project": {
            "id": 79,
            "team_id": 81,
            "unique_code": "20240608005633-1804",
            "title": "AI-powered Ultrasounds for Maternal Health",
            "image_url": "files/ymc/images/20240618235609_AI_powered_ultrasound.jpg",
            "schematics_url": "files/ymc/images/20240608005337_AI_Maternal_Health_Schematic_.pdf",
        }
    },
    {
        "project": {
            "id": 75,
            "team_id": 77,
            "unique_code": "20240608205229-1799",
            "title": "Emotion Safari: A Social-Emotional Learning Toolkit",
            "image_url": "files/ymc/images/20240622221425_cover.png",
            "schematics_url": "files/ymc/images/20240602023553_Schematics.png",
        }
    },
    {
        "project": {
            "id": 92,
            "team_id": 93,
            "unique_code": "20240702021624-1848",
            "title": "TYLE",
            "image_url": "files/ymc/images/20240702021513_IMG_2104.jpeg",
            "schematics_url": "files/ymc/images/20240702015241_IMG_2103.jpeg",
        }
    },
    {
        "project": {
            "id": 91,
            "team_id": 92,
            "unique_code": "20240630011406-1054",
            "title": "Engaging Worlds",
            "image_url": "files/ymc/images/20240630005002_Screenshot_2024-06-29_at_8.49.42_PM.png",
            "schematics_url": "files/ymc/images/20240630010358_Screenshot_2024-06-29_at_9.01.22_PM.png",
        }
    },
    {
        "project": {
            "id": 90,
            "team_id": 91,
            "unique_code": "20240701054150-1838",
            "title": "ASU Instructor Assistant GPT",
            "image_url": "files/ymc/images/20240701054021_ASU_GPT_logo.png",
            "schematics_url": "files/ymc/images/20240701045425_overview.png"
        }
    },
    {
        "project": {
            "id": 86,
            "team_id": 87,
            "unique_code": "20240701015816-1828",
            "title": "A Natural Language Processing Model for English to Chinese Translation of Medical Documents",
            "image_url": "files/ymc/images/20240630234709_Screen_Shot_2024-06-30_at_4.46.40_PM.png",
            "schematics_url": "files/ymc/images/20240630225832_Screen_Shot_2024-06-30_at_3.58.23_PM.png",
        }
    },
    {
        "project": {
            "id": 82,
            "team_id": 84,
            "unique_code": "20240624182858-1814",
            "title": "Edstation School",
            "image_url": "files/ymc/images/20240624173059_Edstation.png",
            "schematics_url": "files/ymc/images/20240624182817_Edstation.pdf",
        }
    },
    {
        "project": {
            "id": 88,
            "team_id": 89,
            "unique_code": "20240722155448-1830",
            "title": "Areca polyphenol hydrogel to accelerate bone repair",
            "image_url": "files/ymc/images/20240725115449_Areca_Hydrogel_Cover_Picture.JPG",
            "schematics_url": "files/ymc/images/20240725120001_Areca_Hydrogel_Paper.pdf",
        }
    }
]);

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 540) {
        height = width * (2000 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 540) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile-white.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-white.svg')";
    }
}

// function openDetail(id) {
//     router.push({ path: `/china-us/submission-detail/${id}` });
// }

onMounted(() => {
    adjustHeight();
    setBackgroundImage();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/eclipse.svg"), url("${process.env.VUE_APP_URL}/images/eclipse-2.svg")`;
    }

    adjustIframeWidth();

});

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function adjustIframeWidth() {
    const container = document.getElementById('image-container-3');
    const iframe = document.getElementById('responsive-iframe');
    if (container && iframe) {
        iframe.style.width = `${container.offsetWidth}px`;
        iframe.style.height = `${container.offsetWidth * 0.579}px`; // Asumsikan rasio 2:1 untuk tinggi
    }
}

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}
</script>

<style>
/* Hero Section */
.hero-section-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5em;
    padding: 0;
}

.responsive-layout {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    gap: 5em;
}

.responsive-image {
    height: 350px;
    margin-left: 5em;   
}

/* Typography */
.black-backdrop {
    font-weight: 700;
    line-height: 1.1em;
}

.black-backdrop {
    color: #3B4648;
    font-size: 2em;
}

.black-backdrop-mini, .black-backdrop-mini-2 {
    color: #3B4648;
}

.black-backdrop-mini {
    font-size: 1.5em;
    font-weight: 550;
}

.black-backdrop-mini-2 {
    font-size: 1.3em;
}

/* Card Styles */
.card-chinaus {
    display: flex;
    gap: 2em;
    background-color: rgba(242, 243, 251, 0.55);
    padding: 2.5em 3em;
    border-radius: 40px;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: row;
}

.card-chinaus>.image-container {
    flex: 2;
}

.card-chinaus>.label-container {
    flex: 3;
}

.card-chinaus>.image-container-1 {
    flex: 2;
}

.card-chinaus>.label-container-1 {
    flex: 3;
}

.card-chinaus>.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Section Headers */
.section-header, .label-card-chinaus, .label-card-chinaus-2 {
    color: #003D6A;
    font-weight: 600;
}

.section-header-2 {
    font-size: 2em;
    font-weight: 500;
    color: #003D6A;
}

.section-header-3 {
    font-size: 2em;
    font-weight: 600;
    color: #003D6A;
    line-height: 1.2;
}

.section-header {
    font-size: 2em;
    margin-bottom: 5px;
}

.label-card-chinaus {
    font-size: 3.2em;
}

.label-card-chinaus-2 {
    font-size: 4.2em;
    line-height: 1;
}

.card-image-china-us {
    height: 280px;
}

.container-china-us {
    width: 80%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .label-card-chinaus {
        font-size: 2.5em;
    }

    .label-card-chinaus-2 {
        font-size: 2.5em;
    }

    .card-image-china-us {
        height: 230px;
    }

    .container-china-us {
        width: 90%;
        margin: 0 auto;
    }
}

.scroll-container {
    display: flex;
}

.welcome-page-4 {
    background-size: 50%, 20%;
    background-repeat: no-repeat, no-repeat;
    background-position: right top, left bottom;
}

/* Additional Layout Styles */
.profile-info-container {
    display: flex;
    gap: 10px;
}

.label-cover-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.china-us-logo {
    height: 40px;
}

.organizer-logo {
    height: 20px;
}

/* Media Queries */
@media (min-width: 2311px) {
    .label-cover-2 {
        gap: 6px;
    }
    
    .hero-section-3 {
        padding: 6em;
    }

    .responsive-image {
        height: 500px;
        margin-left: 6em;
    }

    .black-backdrop {
        font-size: 5.5em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 2.5em;
    }

    .china-us-logo {
        height: 55px;
    }

    .organizer-logo {
        height: 35px;
    }
}

@media (min-width: 1964px) {
    .label-cover-2 {
        gap: 6px;
    }

    .hero-section-3 {
        padding: 6em;
    }

    .responsive-image {
        height: 500px;
        margin-left: 6em;
    }

    .black-backdrop {
        font-size: 5em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 2em;
    }

    .china-us-logo {
        height: 50px;
    }

    .organizer-logo {
        height: 30px;
    }
}

/* CSS untuk resolusi 1307px */
@media (max-width: 1307px) {
    .hero-section-3 {
        padding: 4em;
    }

    .responsive-image {
        height: 300px;
        margin-left: 5em;   
    }

    .black-backdrop {
        font-size: 2.5em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1.2em;
    }

    .china-us-logo {
        height: 35px;
    }

    .organizer-logo {
        height: 18px;
    }
}

/* CSS untuk resolusi 1206px */
@media (max-width: 1206px) {
    .hero-section-3 {
        padding: 3em;
    }

    .responsive-image {
        height: 290px;
        margin-left: 5em;
    }

    .black-backdrop {
        font-size: 2.4em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1.2em;
    }

    .china-us-logo {
        height: 34px;
    }

    .organizer-logo {
        height: 18px;
    }
}

/* CSS untuk tampilan 1133px */
@media (max-width: 1133px) {
    .hero-section-3 {
        padding: 4em;
    }

    .responsive-image {
        height: 280px;
    }

    .black-backdrop {
        font-size: 2.0em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1.1em;
    }

    .china-us-logo {
        height: 32px;
    }

    .organizer-logo {
        height: 17px;
    }
}

/* CSS untuk resolusi 1037px */
@media (max-width: 1025px) {
    .hero-section-3 {
        padding: 3em;
    }

    .responsive-image {
        height: 270px;
        margin-left: 5em;
    }

    .black-backdrop {
        font-size: 2.2em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1.1em;
    }

    .china-us-logo {
        height: 30px;
    }

    .organizer-logo {
        height: 16px;
    }
}

/* CSS untuk resolusi 898px */
@media (max-width: 898px) {
    .label-cover-2 {
        gap: 4px;
    }

    .hero-section-3 {
        padding: 2.5em;
    }

    .responsive-image {
        height: 250px;
    }

    .black-backdrop {
        font-size: 1.8em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1em;
    }

    .china-us-logo {
        height: 28px;
    }

    .organizer-logo {
        height: 15px;
    }
}

/* CSS untuk tampilan iPad mini */
@media (max-width: 768px) {
    .hero-section-3 {
        padding: 2.5em;
    }

    .responsive-image {
        height: 200px;
        margin-left: 0em;
    }

    .black-backdrop {
        font-size: 2em;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 1em;
    }

    .china-us-logo {
        height: 30px;
    }

    .organizer-logo {
        height: 16px;
    }
    
    .scroll-wrapper {
        position: relative;
        overflow: hidden;
    }

    .scroll-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        position: relative;
    }

    .scroll-wrapper::before,
    .scroll-wrapper::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        border-radius: 4px;
        z-index: 2;
    }

    .scroll-wrapper::before {
        left: 0;
        background: linear-gradient(to right, rgb(255, 255, 255, 0.8) 0%, transparent 100%);
    }

    .scroll-wrapper::after {
        right: 0;
        background: linear-gradient(to left, rgb(255, 255, 255, 0.8) 0%, transparent 100%);
    }
}

/* CSS untuk tampilan mobile */
@media (max-width: 540px) {
    .hero-section-3 {
        padding: 2em;
        flex-direction: column;
        gap: 1em;
    }

    .center-mobile {
        text-align: center;
    }

    .responsive-image {
        height: 200px;
        margin-left: 0em;
    }

    .black-backdrop {
        font-size: 2.7em;
        text-align: center;
    }

    .black-backdrop-mini, .black-backdrop-mini-2 {
        font-size: 0.9em;
    }

    .china-us-logo {
        height: 25px;
    }

    .organizer-logo {
        height: 15px;
    }

    .card-chinaus {
        flex-direction: column;
        padding: 1.5em 1.5em;
    }

    .welcome-page-4 {
        background-size: 400%, contain !important;
        background-repeat: no-repeat, no-repeat;
        background-position: right top, left bottom !important;
    }

    .responsive-layout {
        flex-direction: column;
        gap: 3px;
    }

    .label-cover {
        text-align: center;
    }
    
    .scroll-wrapper {
        position: relative;
        overflow: hidden;
    }

    .scroll-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        position: relative;
    }

    .scroll-wrapper::before,
    .scroll-wrapper::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        border-radius: 4px;
        z-index: 2;
    }

    .scroll-wrapper::before {
        left: 0;
        background: linear-gradient(to right, rgb(255, 255, 255, 0.8) 0%, transparent 100%);
    }

    .scroll-wrapper::after {
        right: 0;
        background: linear-gradient(to left, rgb(255, 255, 255, 0.8) 0%, transparent 100%);
    }
    
    .right-left {
        order: 2;
    }

    .china-us-logo {
        height: 20px !important;
    }

    .organizer-logo {
        height: 17px;
    } 

    .black-backdrop-mini {
        font-size: 2em;
        font-weight: 500;
    }

    .black-backdrop-mini-2 {
        font-size: 1.4em;
    }

    /* Menyembunyikan label gambar di mode mobile dan tablet */
    .winner-section-label {
        display: none;
    }
}

@media (max-width: 360px) {
    .responsive-image {
        height: 170px;
        margin-left: 0em;
    }

    .black-backdrop {
        font-size: 2.4em;
    }

    .black-backdrop-mini {
        font-size: 1.7em;
        font-weight: 500;
    }
}

.image-container-3 iframe {
    border-radius: 10px;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .image-container-3 iframe {
      width: 300px;
      height: 200px;
    }
}

@media (min-width: 768px) {
    .image-container-3 iframe {
      width: 1000px;
      height: 500px;
    }
}

.grid-container-zz {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.grid-item {
    text-align: center;
    position: relative;
    width: 100%;
}

.center-item {
    position: relative;
    width: 50%;
}

.ysa-winner-icon {
    position: absolute;
    top: 10px;
    left: 10px;
}

.grid-item-thumnail {
    width: 100%;
    height: 100%;
    max-height: 279px;
    border-radius: 8px;
}

.caption-1 {
    font-size: 14px;
    margin-top: 10px;
}

@media (max-width: 540px) {
    .grid-container-zz {
        grid-template-columns: 1fr;
    }

    .grid-container-zz > .grid-item:nth-child(9) {
        grid-column: span 1; /* Mengambil satu kolom */
        width: 100%; /* Lebar 100% pada perangkat kecil */
    }
}


</style>
