// src/services/discussionService.js

import http from "./httpService";

class DiscussionService {
  async getAllDiscussion(page = 1, sort = 'newest') {
    return await http.get(`/china-us/discussion?page=${page}&limit=5&sort_by=${sort}`);
  }

  async postDiscussion(data) {
    return await http.post('/china-us/discussion', data);
  }

  async getDiscussionId(id) {
    return await http.get(`/china-us/discussion/post/${id}`);
  } 

  async addComment(discussionId, data) {
    return await http.post(`/china-us/discussion/post/${discussionId}/comment`, data);
  } 

  async likeDiscussion(discussionId) {
    return await http.put(`/china-us/discussion/post/${discussionId}/like`);
  } 

  async likeComment(discussionId, commentId) {
    return await http.put(`/china-us/discussion/post/${discussionId}/comment/${commentId}/like`);
  } 
  
}

export default new DiscussionService();
