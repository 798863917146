<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/mobile-ux-bro.svg" alt="Arrow Icon" class="responsive-image-faq" />
                </div>
                <div class="label-cover">
                    <div class="white-backdrop">
                        View Other Project
                    </div>
                    <div class="white-backdrop-mini"> Check out other projects which have been submitted! </div>
                </div>
            </div>
        </div>
    </div>

    <NavigationBarComponent></NavigationBarComponent>

    <div class="welcome-page-4-dsa welcome-page-5 pb-5">
        <div v-if="projectDataShow" class="container justify-center align-center py-5">
            <div>
                <div @click="backtoList()" class="back-button">
                    <img src="/images/chinaus/arrow-left.svg" alt="">
                    <div>
                        Back
                    </div>
                </div>
            </div>

            <div class="card-chinaus" style="background-color: #F2F3FB;border-radius: 25px;">
                <div class="label-container">
                    <div>
                        <ProjectInformationPreviewPage :projectData="projectData" :isPreview="true"
                            @update-project-data="updateProjectData" @go-to-step="goToStep"
                            @update-visibility-modal-save="updateVisibilityModalSave" />
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="spinner-container">
                <div class="mt-5 spinner"></div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';
import ProjectInformationPreviewPage from '@/views/ChinaUs/SubmissionSubmit/ProjectInformationPreviewPage.vue';
import ChinaUsService from '@/services/chinausService.js';

const route = useRoute();
const router = useRouter();

const projectData = ref({
    title: "",
    image_url: "",
    summary: "",
    hardware: "",
    software: "",
    background: "",
    details: "",
    impact: "",
    schematics_url: "",
    demo_url: "",
    attachments_url: "",
    additional_info: ""
});

const projectDataShow = ref(false)

function backtoList() {
    router.push({ path: `/china-us` });
}

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 768) {
        height = width * (2000 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 768) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
}

onMounted(async () => {
    adjustHeight();
    setBackgroundImage();
    setBackgroundPosition();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse.svg")`;
    }
    const projectId = route.params.id;

    const response = await ChinaUsService.getSubmissionbyId(projectId);
    if (response.status === 200) {
        projectDataShow.value = true;
        Object.assign(projectData.value, response.data.data.project);
        console.log('projectData.value', projectData.value);
    }

});

function setBackgroundPosition() {
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');
    const width = window.innerWidth;

    if (width <= 768) {
        welcomePageElement.style.backgroundPosition = `right top`;
    } else {
        welcomePageElement.style.backgroundPosition = `right top`;

    }
}

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}
</script>