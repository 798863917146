<template>
    <div class="card-header">
            Lesson Packages Result {{ index + 1 }}
        </div>
        <div class="card">
            <div class="card-content">
                <div v-if="props.isLoading">
                    <LoadingComponent v-if="props.isLoading"></LoadingComponent>
                </div>
                <div v-else>
                    <h2>{{ props.result.topic }}</h2>
                    <p>{{ props.result.summary }}</p>
                    <div class="tags">
                        <span class="tag">{{ props.result.domain }}</span> |
                        <span v-for="(software, index) in props.result.softwares" :key="software" class="tag">
                            {{ software }}
                            <span v-if="index !== props.result.softwares.length - 1"> | </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="button" v-if="!props.isLoading" @click="goToPreview(props.idGenerated, index + 1)">Build Lesson Packages</button>
        </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { useRouter } from 'vue-router';
import LoadingComponent from '@/components/LoadingComponent.vue';
import cookiesService from '@/services/cookiesService';
import { useStore } from 'vuex';
import LocalStorageService from '@/services/localStorageService';

// eslint-disable-next-line
const router = useRouter();
const store = useStore();

const props = defineProps({
    result: Object,
    index: Number,
    idGenerated: Number,
    isLoading: Boolean
});

const goToPreview = ref(async (idGenerated, index) => {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
        let redirectObject = {
            "resource_id": idGenerated,
            "option": index
        };
        
        let jsonString = JSON.stringify(redirectObject);
        
        let encryptedString = encodeURIComponent(jsonString);
        
        router.push('/preview?data=' + encryptedString);
    } else {
        let redirectObject = {
            "resource_id": idGenerated,
            "option": index
        };
        store.commit('SET_SELECTED_LESSON_PACKAGE', redirectObject);
        const storedData = store.state;
        LocalStorageService.setCacheWithExpiry('cache-generate', storedData);
        window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
    }
});


</script>

<style>

.card {
    border: 1px solid #CCCCCC;
    border-radius: 25px;
    width: 100%;
    font-family: 'League Spartan';
}

.card-header {
    padding: 0.4em 0em;
    font-weight: bold;
    color: #003D6A;
    font-family: 'League Spartan';
    font-weight: 600;
    font-size: 1.5em;
}

.card-content {
    padding: 0.8em 1.6em;
    color: #003D6A;
    font-size: 1.2em;
}

.card-content h2 {
    color: #003D6A;
    margin-bottom: 10px;
    font-size: 1em;
}

.card-content p {
    font-size: 0.7em;
    color: #333333;
    line-height: 1.6;
}

.tags {
    margin-top: 1.6em;
    font-size: 0.7em;
}

.tag {
    color: #4C4DDC;
    padding: 5px 0px;
    border-radius: 3px;
}

.card-footer {
    padding: 1.4em 0px;
    text-align: right;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.button {
    border-radius: 25px;
    text-decoration: none;
    font-family: 'League Spartan';
    font-weight: 500;
    background-color: #003D6A;
    color: white;
    padding: 10px 20px;
}

.button:hover {
    background-color: #002744;
}
</style>