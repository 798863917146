<template>
  <div class="file-upload-wrapper">
    <div v-if="isLoading" class="spinner-container">
      <div class="my-5 spinner"></div>
    </div>
    <div v-else>
      <input v-if="!modelValue" type="file" :id="'ParentalConsent-' + idSuffix" @change="handleFileUpload" hidden />
      <label v-if="!modelValue" :for="'ParentalConsent-' + idSuffix" style="cursor: pointer" class="file-upload-label">{{ fileName || defaultLabel }}</label>
      <div v-if="modelValue" class="image-wrapper">
        <template v-if="isImageExtension(modelValue)">
          <div v-if="!isImageLoaded" class="spinner-container">
            <div class="my-5 spinner"></div>
          </div>
          <img v-if="isImageExtension(modelValue)" :src="`https://storage.eddy4teachers.com/${modelValue}`" v-show="isImageLoaded" alt="Image Preview" @load="handleImageLoad" @error="handleImageError" style="max-width: 200px; max-height: 200px;" />
        </template>
        <template v-else>
          <span>{{ fileName }}</span>
        </template>
      </div>
      <div>
        <button v-if="modelValue && (isImageLoaded || !isImage)" @click="removeFile" style="color: #E00069;" type="button">Remove</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from 'vue';
import ChinaUsService from '@/services/chinausService.js';

const props = defineProps({
  defaultLabel: {
    type: String,
    default: 'Upload'
  },
  modelValue: String,
  maxSize: Number, // Prop untuk ukuran maksimum file
  allowedTypes: {
    type: Array,
    default: () => [] // Prop untuk tipe file yang diizinkan
  }
});

const emits = defineEmits(['update:modelValue']);
const file = ref(null);
const fileName = ref('');
const idSuffix = ref('');
const isImageLoaded = ref(false); // Ref untuk status gambar
const isImage = ref(false); // Ref untuk mengecek apakah file adalah gambar
const isLoading = ref(false); // Ref untuk status loading

onMounted(() => {
  idSuffix.value = Math.random().toString(36).substring(2, 15);

  // Set fileName dari modelValue jika modelValue tidak kosong
  if (props.modelValue) {
    const segments = props.modelValue.split('/');
    fileName.value = segments[segments.length - 1];
  }
});

watch(() => props.modelValue, () => {
  isImageLoaded.value = false; // Reset status gambar saat modelValue berubah
});

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

const isImageExtension = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  return imageExtensions.includes(extension);
};

const handleFileUpload = async (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    file.value = files[0];
    fileName.value = file.value.name;

    // Cek ukuran file
    if (props.maxSize && file.value.size > props.maxSize) {
      alert('File size is too large. The maximum size is ' + props.maxSize / 1024 / 1024 + ' MB.');
      resetFile();
      return;
    }

    // Cek ekstensi file
    const extension = file.value.name.split('.').pop().toLowerCase();
    if (!props.allowedTypes.includes(extension)) {
      alert('File type not allowed. Only the following file types are allowed: ' + props.allowedTypes.join(', '));
      resetFile();
      return;
    }

    // Set isLoading menjadi true sebelum mulai upload
    isLoading.value = true;

    // Cek apakah file adalah gambar berdasarkan ekstensi
    isImage.value = isImageExtension(file.value.name);

    // Jika tipe file adalah gambar, tampilkan preview lokal
    if (isImage.value) {
      const localPreviewUrl = URL.createObjectURL(file.value);
      emits('update:modelValue', localPreviewUrl);
    } else {
      emits('update:modelValue', fileName.value);
    }

    try {
      const formData = new FormData();
      formData.append('file', file.value);
      formData.append('folder', 'ymc/images');

      const response = await ChinaUsService.uploadFile(formData);
      console.log('Upload berhasil:', response);
      emits('update:modelValue', response.file_path);
    } catch (error) {
      console.error('Gagal mengupload file:', error);
      resetFile();
    } finally {
      // Set isLoading menjadi false setelah upload selesai
      isLoading.value = false;
    }
  } else {
    resetFile();
  }
};

const handleImageLoad = () => {
  isImageLoaded.value = true; // Update status gambar ketika sudah dimuat
};

const handleImageError = () => {
  isImageLoaded.value = false; // Update status gambar ketika ada error
};

const removeFile = async () => {
  if (props.modelValue) {
    try {
      const response = await ChinaUsService.deleteFile(props.modelValue);
      console.log('File dihapus:', response);
    } catch (error) {
      console.error('Gagal menghapus file:', error);
    }
  }
  resetFile();
};

const resetFile = () => {
  file.value = null;
  fileName.value = '';
  emits('update:modelValue', '');
  isImageLoaded.value = false; // Reset status gambar
  isImage.value = false; // Reset status tipe file
};
</script>

<style>
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
