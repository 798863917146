<template>
    <!-- Sidebar with transitions -->
    <div class="right-sidebar" :class="{ 'is-open': sidebarOpen }">
        <div v-if="!sidebarOpen">
            <div class="mt-4">
                <img @click="toggleSidebar" style="cursor: pointer;" src="/images/green-arrow.svg" />
            </div>
        </div>
        <div v-else-if="sidebarOpen" style="width: 100%; padding: 0em 1.2em;cursor: pointer;">
            <!-- content box-->
            <div v-if="pageMeta === 'edit'">
                <div style="display: flex;gap: 5px;justify-content: space-between;">
                    <button @click="discard()" class="btn-discard" style="width: 100%;"><img
                            src="/images/close-circle.svg" alt=""> Discard</button>
                    <button class="btn-save" @click="displayPopUp" style="width: 100%;"><img src="/images/save.svg"
                            alt=""> Save</button>
                </div>
                <div class="detailed-profile-icons mt-4" style="font-weight: 600;" @click="toggleSidebar">
                    Earn More Credits!
                </div>
                <div class="detailed-profile-icons mt-4"
                    style="font-weight: 600;color: #003D6A;justify-content: space-between;" @click="toggleSidebar">
                    <div>Continue Editing</div>
                    <div style="color: #E00069;">{{ props.progress }}%</div>
                </div>
                <div class="detailed-profile-icons" style="font-weight: 600;color: #003D6A;" @click="toggleSidebar">
                    <ProgressBar :progress="props.progress" />
                </div>
                <div class="detailed-profile-icons mt-4" style="font-weight: 600;color: #003D6A;"
                    @click="toggleSidebar">
                    Original Author
                </div>
                <div class="detailed-profile-icons" style="color: black;" @click="toggleSidebar">
                    <img style="border-radius: 50%; height: 30px;" :src="props.originalAuthor?.image" alt="">
                    {{ props.originalAuthor?.firstname }} {{ props.originalAuthor?.lastname }}
                </div>
            </div>
            <div class="detailed-profile-icons mt-4" style="font-weight: 600;" @click="toggleSidebar">
                Content Information
            </div>
            <div v-if="gradeName" class="category-label mt-4">
                Grade
            </div>
            <div v-if="gradeName" class="category-chip">
                <span class="category-chip-child">{{ gradeName }}</span>
            </div>
            <div v-if="subjectName" class="category-label mt-4">
                Subject
            </div>
            <div v-if="subjectName" class="category-chip">
                <span class="category-chip-child">{{ subjectName }}</span>
            </div>
            <div v-if="techDomainName" class="category-label mt-4">
                Domain
            </div>
            <div v-if="techDomainName" class="category-chip">
                <span class="category-chip-child">{{ techDomainName }}</span>
            </div>
            <div v-if="softwareName" class="category-label mt-4">
                Software
            </div>
            <div v-if="softwareName" class="category-chip">
                <span v-for="item in softwareName" :key="item" class="category-chip-child">{{ item }}</span>
            </div>

            <div clas="mt-4" style="border-bottom: 1.5px solid rgb(216, 218, 229); margin: 10px 0px 10px 0px;"></div>
            <iframe
                src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2F3504315569887076%2F&width=280&show_metadata=false&appId=1393423441448923&height=0"
                width="280" height="1000" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
    </div>

    <ModalComponent :visible="showModalSave" @update:visible="updateVisibilityModalSave">
        <!-- Fill in capital here -->
        <div class="container-modal">
            <div class="modal-title">Save the progress?</div>
            <div class="border-header"></div>
            <div class="modal-icon">
                <img src="/images/save-modal.svg" alt="Save Icon" style="height: 80px;">
                <!-- Update the src as per your image path -->
            </div>
            <div style="display: flex;justify-content: center;font-size: 1em;">Would you
                like to save these lesson packages as a new entry?</div>
            <div
                style="display: flex;justify-content: center;font-size: 1em;padding-bottom: 0.9em;">
                They will be automatically stored in your profile.</div>

            <div class="modal-button">
                <button class="btn btn-discard" :class="{ 'btn-loading': isLoading }" :disabled="isLoading"
                    @click="updateVisibilityModalSave">Cancel</button>
                <button class="btn btn-save" :class="{ 'btn-loading': isLoading }" :disabled="isLoading"
                    style="padding: 0.5em 1em;" @click="save">Save</button>
                <button class="btn btn-save-as" :class="{ 'btn-loading': isLoading }" :disabled="isLoading"
                    @click="saveAsNew">Save as</button>
            </div>
        </div>
    </ModalComponent>

    <ModalComponent :visible="showModalSaveName" @update:visible="updateVisibilityModalSaveName">
        <!-- Fill in capital here -->
        <div class="container-modal">
            <div class="modal-title">Save the Lesson Packages?</div>
            <div class="border-header"></div>
            <div class="modal-icon">
                <img src="/images/save-modal.svg" alt="Save Icon" style="height: 80px;">
                <!-- Update the src as per your image path -->
            </div>

            <div style="padding: 10px 10px">
                <div style="color: rgb(224, 0, 105);font-size: 0.8em;">{{ lessonPackageErrorMessage }}</div>
                <div style=" display: flex; justify-content: space-between; ">
                    <div style="color: rgb(224, 0, 105); font-size: 0.8em;">{{ lessonPackageErrorMessage }}</div>
                    <div style="font-size: 0.8em;">{{ lessonPackageName ? lessonPackageName.length : 0 }} / 200</div>
                </div>
                <BaseInput type='text' :maxHeight="'80'" v-model="lessonPackageName" :error="islessonPackageError"
                    :errorMessage="lessonPackageErrorMessage" style="margin-bottom: 0.5em;"
                    placeholder="Lesson Package Title"></BaseInput>
            </div>

            <div class="modal-button">
                <button class="btn btn-discard" @click="updateVisibilityModalSaveName">Cancel</button>
                <button class="btn btn-save" style="padding: 0.5em 1em;" @click="confirmSaveAs">Confirm</button>
            </div>
        </div>
    </ModalComponent>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'; // Import useRoute
import LocalStorageService from '@/services/localStorageService';
import cookiesService from '@/services/cookiesService';
import ModalComponent from '@/components/ModalComponent.vue';
import BaseInput from '@/components/BaseInput.vue';
import ProgressBar from '@/components/ProgressBar.vue';

const route = useRoute();
const router = useRouter();
const pageMeta = ref(null);
const user = ref(null);

const props = defineProps({
    resource: {
        type: Object,
        default: null,
    },
    progress: {
        type: Number,
        default: 0,
    },
    originalAuthor: {
        type: Object,
        default: null
    }
});

watch(() => props.resource, () => {
    if (props.resource) {
        const selectedData = props.resource.selected_data;
        if (props.resource.payload.grade) {
            gradeName.value = props.resource.payload.grade;
        } else {
            gradeName.value = props.resource.raw_data[selectedData - 1]['core']['grade'];
        }

        if (props.resource.payload.subject) {
            subjectName.value = props.resource.payload.subject;
        } else {
            subjectName.value = props.resource.raw_data[selectedData - 1]['core']['subject'];
        }

        if (props.resource.payload.domain) {
            techDomainName.value = props.resource.payload.domain;
        } else {
            techDomainName.value = props.resource.raw_data[selectedData - 1]['core']['tech domain'];
        }

        if (props.resource.payload.software) {
            softwareName.value = props.resource.payload.software;
        } else {
            softwareName.value = props.resource.raw_data[selectedData - 1]['core']['software'];
        }

    }
});

const store = useStore();
const sidebarOpen = ref(true);

const gradeName = ref(null);
const subjectName = ref(null);
const techDomainName = ref(null);
const softwareName = ref(null);
const studentProfile = ref([]);
const showModalSave = ref(false);
const showModalSaveName = ref(false);
const lessonPackageName = ref(null);
const islessonPackageError = ref(false);
const lessonPackageErrorMessage = ref('');

onMounted(async () => {

    // Ini akan mengecek apakah FB sudah dimuat dan siap
    // eslint-disable-next-line
    if (window.FB) {
        // eslint-disable-next-line
        window.FB.XFBML.parse();
    } else {
        // Jika FB belum dimuat, buat listener untuk menunggu FB ready
        // eslint-disable-next-line
        window.fbAsyncInit = () => {
            // eslint-disable-next-line
            FB.XFBML.parse();
        };
    }

    pageMeta.value = route.meta.page;
    let storedData = store.state.formData;

    if (storedData !== null && storedData !== undefined) {
        const formResults = LocalStorageService.getFormResults();
        const formData = LocalStorageService.getFormData();
        if (formResults && formData) {
            store.commit('SET_FORM_RESULTS', formResults);
            store.commit('SET_FORM_DATA', formData);
            storedData = store.state.formData;
        }
    }

    gradeName.value = storedData.grade_term_name;
    subjectName.value = storedData.subject_term_name;
    techDomainName.value = storedData.domain_term_name;
    softwareName.value = storedData.software_term_name;
    studentProfile.value = storedData.student_profile_term_ids;

    try {
        user.value = cookiesService.getCookie('authToken');
    } catch (error) {
        console.log(error);
    }
});

function toggleSidebar() {
    sidebarOpen.value = !sidebarOpen.value;
}

function displayPopUp() {
    updateVisibilityModalSave();
}

function updateVisibilityModalSave() {
    showModalSave.value = !showModalSave.value
}

function updateVisibilityModalSaveName() {
    showModalSaveName.value = !showModalSaveName.value
}

const emit = defineEmits(['save', 'saveAs']);
const isLoading = ref(false);

const save = () => {
    isLoading.value = true;
    emit('save');
};

function saveAsNew() {
    updateVisibilityModalSaveName();
}

function confirmSaveAs() {
    if (lessonPackageName.value === null || lessonPackageName.value === '') {
        islessonPackageError.value = true;
        lessonPackageErrorMessage.value = 'Please insert name';
    } else {
        if(lessonPackageName.value.length <= 200) {
            emit('save', lessonPackageName.value); // Menambahkan parameter title ke dalam emit
            updateVisibilityModalSaveName();
            updateVisibilityModalSave();
        } else {
            islessonPackageError.value = true;
            lessonPackageErrorMessage.value = 'Title cannot exceed 100 characters.';
        }
    }
}

function discard() {
    const lessonPackageId = route.params.id;
    router.push({ path: `/lesson-packages/${lessonPackageId}` });
}
</script>

<style>
.btn-save,
.btn-discard,
.btn-save-as,
.btn-save-loading {
    border-radius: 13px;
    text-decoration: none;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
}

.btn-save {
    background-color: #00925E;
}

.btn-save-loading {
    background-color: #676767;
}

.btn-save:hover {
    background-color: #00734A;
}

.btn-discard {
    background-color: #E00069;
}

.btn-save-as {
    background-color: white;
    color: #00925E;
    border-style: solid;
    border-color: #00925E;
    border-width: 1px;
}

.btn-save-pas:hover {
    color: #00734A;
    background-color: white;
    border-style: solid;
    border-color: #00734A;
    border-width: 1px;
}

.btn-discard:hover {
    background-color: #b31660;
}

.btn-save img,
.btn-discard img {
    margin-right: 4px;
    margin-bottom: 4px;
}

.btn-loading {
    background-color: grey !important;
    color: white !important;
}

.btn-loading:hover {
    background-color: grey !important;
    color: white !important;
}
</style>