// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    formData: {
      topic: '',
      grade_term_id: '',
      subject_term_id: '',
      domain_term_id: '',
      software_term_id: '',
      student_profile_term_ids: [],
    },
    result1Loading: false,
    result2Loading: false,
    result3Loading: false,
    selectedLessonPackage: null,
  },
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    SET_FORM_RESULTS(state, formResult) {
      state.formResult = formResult;
    },
    SET_RESULTS1_LOADING(state, value) {
      state.result1Loading = value;
    },
    SET_RESULTS2_LOADING(state, value) {
      state.result2Loading = value;
    },
    SET_RESULTS3_LOADING(state, value) {
      state.result3Loading = value;
    },
    SET_SELECTED_LESSON_PACKAGE(state, value) {
      state.selectedLessonPackage = value;
    },
  },
});
