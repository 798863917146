<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Users</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title"> List of Users </div>
      <div>
        You can add, edit, and delete all of Users from this page.
      </div>

      <div class="admin-flex-container">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery"
            @input="debouncedGetAllSubmission">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>

        <a class="btn btn-create-list mx-2 my-md-auto" href="/admin/users/form">
          <span class="icon"><img src="/images/icon/plus-circle.svg" alt="download-icon"></span>
          <span class="admin-button-text">Add Users</span>
        </a>
      </div>

      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Title</th>
              <th>Grade</th>
              <th>Subject</th>
              <th>Domain</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="!tableLoading">
            <tr v-for="(project, index) in data.data.rows" :key="index">
              <td>{{ project.Status }}</td>
              <td>{{ project.Title }}</td>
              <td>{{ project.Grade }}</td>
              <td>{{ project.Subject }}</td>
              <td>{{ project.Domain }}</td>
              <td>
                <div class="admin-action-column">
                  <a :href="`/admin/china-us/projects/`">View</a>
                  <a href="#" @click="openModal(0)"><img src="/images/admin/trash.svg" alt="Delete"></a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.data.total_pages > 0" class="pagination-controls">
        <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in data.data.total_pages" :key="page" @click="changePage(page)"
          :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalSave" @update:visible="updateVisibilityModalSave">
    <div>
      <div>Submit your project?</div>
      <div></div>
      <div class="admin-confirmation-message">
        Are you sure want to remove this submission?
      </div>
      <div class="admin-confirmation-message">
        an email will be send out to the Team Leader regarding the removal.
      </div>
      <div>
        <button class="btn btn-discard" @click="updateVisibilityModalSave">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }"
          :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }"
          @click="deleteSubmission(projectId)">
          Confirm
        </button>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import LessonPlanService from '@/services/lessonPlanService.js';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';

const totalSubmitted = ref(0);
const stillInProgress = ref(0);
const showModalSave = ref(false);
const isLoading = ref(false);
const projectId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const currentPage = ref(1);

onMounted(async () => {
  await getAllSubmission(currentPage.value, 'newest');
});

const getAllSubmission = async (page, sort) => {
  try {
    tableLoading.value = true;
    const response = await LessonPlanService.getAllSubmission(page, sort, searchQuery.value);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;

      if (data.value.data.extras) {
        totalSubmitted.value = data.value.data.extras.total_rows_submitted;
        stillInProgress.value = data.value.data.extras.total_rows_in_progress;
      }
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const debouncedGetAllSubmission = debounce(() => {
  getAllSubmission(currentPage.value, 'newest');
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAllSubmission(page, 'newest');
  }
};

const openModal = (id) => {
  projectId.value = id; // Set projectId
  showModalSave.value = true;
};

const updateVisibilityModalSave = () => {
  showModalSave.value = !showModalSave.value;
};

const deleteSubmission = async (id) => {
  isLoading.value = true; // Mulai loading
  try {
    const response = await LessonPlanService.deletSubmission(id);
    if (response.status === 200) {
      await getAllSubmission(currentPage.value, 'newest');
      updateVisibilityModalSave(); // Tutup modal setelah penghapusan sukses
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    isLoading.value = false; // Selesai loading
  }
};
</script>