<template>
    <v-container v-if="isLoading" class="center-container">
        <div style="width: 100%;">
            <LoadingComponent></LoadingComponent>
        </div>
    </v-container>

    <div style="margin-top: 4em;"></div>
    <div style="margin-top: 4em;"></div>

    <GeneratedEditComponent ref="generatedEditComponentRef" v-show="!isLoading && !isError"  @loading="handleLoading" @error="handleError"></GeneratedEditComponent>

    <v-container v-show="!isLoading && isError">
        <ErrorGenerate @click="handleReload"></ErrorGenerate>
    </v-container>
</template>
    
<script setup>
import LoadingComponent from '@/components/LoadingComponent.vue';
import GeneratedEditComponent from '@/components/GeneratedEditComponent.vue';
import { ref, nextTick } from 'vue';
import ErrorGenerate from '@/components/ErrorGenerate.vue';

const isLoading = ref(false);
const isError = ref(false);
const generatedEditComponentRef = ref(null);

const handleLoading = (isLoadingValue) => {
  isLoading.value = isLoadingValue;
};

const handleError = (isErrorValue) => {
    isError.value = isErrorValue;
};

const handleReload = () => {
    nextTick(() => {
        if (generatedEditComponentRef.value && typeof generatedEditComponentRef.value.fetchDataFromParent === 'function') {
            console.log('generatedEditComponentRef.value', generatedEditComponentRef.value);
            generatedEditComponentRef.value.fetchDataFromParent();
        } else {
            console.error('fetchDataFromParent is not a function or the component reference is not valid');
        }
    });
};
</script>