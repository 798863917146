import VueCookies from 'vue-cookies';

export default {
  async getCookie(name) {
    // Misalkan ini adalah panggilan async, padahal sebenarnya synchronous
    return Promise.resolve(VueCookies.get(name));
  },
  async setCookie(name, value, options) {
    // Sama seperti getCookie, kita "mensimulasikan" operasi async
    return Promise.resolve(VueCookies.set(name, value, options));
  },
  async removeCookie(name) {
    // Menggunakan Promise.resolve untuk mensimulasikan operasi async
    return Promise.resolve(VueCookies.remove(name));
  },
};
