<template>
    <div class="container d-flex justify-center align-center py-5">
        <div class="card-chinaus" style="background-color: #F2F3FB;border-radius: 25px;">
            <div class="label-container">
                <h2 class="label-card-chinaus">Project Information</h2>

                <div class="input-group mb-2" :class="{ 'error': errors.title }">
                    <label for="projectTitle">Project Title*</label>
                    <input id="projectTitle" v-model="localProjectData.title" type="text" placeholder="Fill up the field" required />
                    <p v-if="errors.title" class="error-message">{{ errors.title }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.image_url }">
                    <label for="projectImage">Project Image* (5MB max)</label>
                    <FileUploadComponent v-model="localProjectData.image_url" :maxSize="5 * 1024 * 1024" :defaultLabel="'Upload (jpg, png, gif)'" :allowedTypes="['jpg', 'jpeg', 'png', 'gif']" />
                    <p v-if="errors.image_url" class="error-message">{{ errors.image_url }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.summary }">
                    <label for="summary">Summary*</label>
                    <div class="fs-08 italic">Tell us in a nutshell - what does your project do? (25 words max)</div>
                    <textarea id="summary" v-model="localProjectData.summary" type="description" placeholder="Fill up the field" required />
                    <p v-if="errors.summary" class="error-message">{{ errors.summary }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.hardware }">
                    <label for="hardware">Hardware*</label>
                    <div class="fs-08 italic">What hardware (e.g. Arduino, IoT sensors) did you use in this project)? (500 words max)</div>
                    <textarea id="hardware" v-model="localProjectData.hardware" type="description" placeholder="Fill up the field" required />
                    <p v-if="errors.hardware" class="error-message">{{ errors.hardware }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.software }">
                    <label for="software">Software, apps and online services*</label>
                    <div class="fs-08 italic">What software (e.g. Arduino IDE), apps (e.g. XXXX), or online services (e.g. TensorFlow) did you use in this project? (500 words max)</div>
                    <textarea id="software" v-model="localProjectData.software" type="description" placeholder="Fill up the field" required />
                    <p v-if="errors.software" class="error-message">{{ errors.software }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.background }">
                    <label for="projectBackground">Project Background*</label>
                    <div class="fs-08 italic">What inspired the development of this project? (500 words max)</div>
                    <textarea id="projectBackground" v-model="localProjectData.background" type="text" placeholder="Fill up the field" required />
                    <p v-if="errors.background" class="error-message">{{ errors.background }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.details }">
                    <label for="projectDetails">Project Details*</label>
                    <div class="fs-08 italic">What does your project do? How does it work? What was your development process like? (500 words max)</div>
                    <textarea id="projectDetails" v-model="localProjectData.details" type="text" placeholder="Fill up the field" required />
                    <p v-if="errors.details" class="error-message">{{ errors.details }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.impact }">
                    <label for="potentialImpact">Potential Impact*</label>
                    <div class="fs-08 italic">How does your project impact your local or global community? What is the potential impact if production is scaled up? (500 words max)</div>
                    <textarea id="potentialImpact" v-model="localProjectData.impact" type="text" placeholder="Fill up the field" required />
                    <p v-if="errors.impact" class="error-message">{{ errors.impact }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.schematics_url }">
                    <label for="schematics">Schematics* (10MB max)</label>
                    <FileUploadComponent v-model="localProjectData.schematics_url" :maxSize="10 * 1024 * 1024" :defaultLabel="'Upload (jpg, png, pdf)'" :allowedTypes="['jpg', 'jpeg', 'png', 'pdf']" />
                    <p v-if="errors.schematics_url" class="error-message">{{ errors.schematics_url }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.demo_url }">
                    <label for="projectDemo">Project Demo/Pitch*</label>
                    <div class="fs-08 italic">Here’s the opportunity to showcase your project in action. Please keep your videos to a maximum length of 3 minutes. Please also upload the video on a file sharing service, and upload the URL here. Please ensure the link is publicly visible.</div>
                    <textarea id="projectDemo" v-model="localProjectData.demo_url" type="text" placeholder="Fill up the field" required />
                    <p v-if="errors.demo_url" class="error-message">{{ errors.demo_url }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.attachments_url }">
                    <label for="otherAttachments">Other attachments (10MB max)</label>
                    <FileUploadComponent v-model="localProjectData.attachments_url" :maxSize="10 * 1024 * 1024" :defaultLabel="'Upload (jpg, pdf, zip)'" :allowedTypes="['jpg', 'jpeg', 'png', 'pdf', 'zip']" />
                    <p v-if="errors.attachments_url" class="error-message">{{ errors.attachments_url }}</p>
                </div>

                <div class="input-group mb-2" :class="{ 'error': errors.additional_info }">
                    <label for="anythingElse">Anything else you’d like to share?</label>
                    <div class="fs-08 italic">Please feel free to let us know anything else that might be relevant to your project.</div>
                    <textarea id="anythingElse" v-model="localProjectData.additional_info" type="text" placeholder="Fill up the field" />
                </div>

                <div style="display: flex;gap: 10px;justify-content: end;">
                    <button @click="saveSubmission" :class="{ 'button-competition-loading': props.isLoading, 'button-competition': !props.isLoading }" type="button">
                        <img src="/images/chinaus/save.svg" alt="Save Progress">
                        Save Progress
                    </button>

                    <button @click="gotoStep(1)" class="button-competition-red" type="button">
                        Previous Page
                    </button>
                    
                    <button @click="validateForm" class="button button-email" type="button">
                        Next Page
                    </button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue';
import FileUploadComponent from '@/components/FileUploadComponent.vue';

const props = defineProps({
    projectData: {
        type: Object,
        required: true
    },
    isLoading: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update-project-data', 'go-to-step', 'save-submission']);

// Menggunakan ref untuk membuat salinan lokal dari projectData
const localProjectData = ref({ ...props.projectData });
const errors = ref({});

watch(localProjectData, (newValue) => {
    // Emit event dengan data terbaru setiap kali localProjectData berubah
    emit('update-project-data', newValue);
}, { deep: true });

function validateForm() {
    errors.value = {};

    if (!localProjectData.value.title) {
        errors.value.title = 'Project Title is required.';
    }
    if (!localProjectData.value.image_url) {
        errors.value.image_url = 'Project Image is required.';
    }
    if (!localProjectData.value.summary) {
        errors.value.summary = 'Summary is required.';
    }
    if (!localProjectData.value.hardware) {
        errors.value.hardware = 'Hardware is required.';
    }
    if (!localProjectData.value.software) {
        errors.value.software = 'Software is required.';
    }
    if (!localProjectData.value.background) {
        errors.value.background = 'Project Background is required.';
    }
    if (!localProjectData.value.details) {
        errors.value.details = 'Project Details are required.';
    }
    if (!localProjectData.value.impact) {
        errors.value.impact = 'Potential Impact is required.';
    }
    if (!localProjectData.value.schematics_url) {
        errors.value.schematics_url = 'Schematics is required.';
    }
    if (!localProjectData.value.demo_url) {
        errors.value.demo_url = 'Project Demo/Pitch is required.';
    }

    if (Object.keys(errors.value).length === 0) {
        gotoStep(3);
    }
}

function gotoStep(index) {
    emit('go-to-step', index);
}

function saveSubmission() {
    emit('save-submission');
}
</script>
