<template>
    <div class="my-4 eddy-blue-card">
        <div>
            I’d like to build a lesson with the following topic prompt: <b>"{{ storedData ?  storedData.topic.toLowerCase() : ''}}"</b>
        </div>
        <div>
            It is intended for <b>'{{ storedData ?  storedData?.grade_term_name : '' }}'</b> <b>{{ storedData ? "'" +  storedData?.subject_term_name + "'" : '' }}</b> <span v-if="storedData && storedData?.domain_term_name"> that incorporates <b>{{ "'" + storedData?.domain_term_name + "'" + '.' }}</b></span> <span v-if="storedData && storedData?.student_profile_term_name?.length > 0"> I have students with <b>{{ storedData?.student_profile_term_name?.join(' and ') }}</b> in my class.</span>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineExpose } from 'vue';
import { useStore } from 'vuex';
import LocalStorageService from '@/services/localStorageService';

const store = useStore();
const storedData = ref(null);

onMounted(async () => {
    getPreviewData();
});

const getPreviewData = () => {
    storedData.value = store.state.formData;
    
    if (storedData.value !== null && storedData.value !== undefined) {
        const formData = LocalStorageService.getFormData();
        if(formData) {
            store.commit('SET_FORM_DATA', formData);
            storedData.value = store.state.formData;
        } 
    }
}

defineExpose({
    getPreviewData
});
</script>

<style>
    .eddy-blue-card {
        font-family: 'League Spartan';
        background-color: #003D6A;
        color: white;
        padding: 10px;
        border-radius: 10px;
        width: auto;
        text-align: center;
    }
</style>