<template>
    <div class="bar-container">
      <div class="bar-rail">
        <div class="bar-fill" :style="{ width: props.progress + '%', backgroundColor: color }"></div>
      </div>

        <svg class="bar-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" :style="{ left: `calc(${props.progress}%)` }" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" fill="#FFFF00"/>
            <mask id="mask0_21795_120166" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="5" width="10" height="10">
            <path d="M15 5H5V15H15V5Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_21795_120166)">
            <path d="M10.0004 5C8.66484 5 7.4092 5.52012 6.46484 6.46445L7.88967 8.75371L10.0004 10L10.7619 7.78028L10.0004 5Z" fill="#FFF05A"/>
            <path d="M5 10.0016C5 11.3371 5.52011 12.5928 6.46446 13.5371L8.28007 12.3856L9.99982 10.0016L7.3625 9.63281L5 10.0016Z" fill="#FFF05A"/>
            <path d="M13.5355 6.46484L11.3041 7.89238L10 10.0004L12.5967 10.6848L15 10.0004C15 8.66484 14.4799 7.40918 13.5355 6.46484Z" fill="#FBBE22"/>
            <path d="M10 15C11.3355 15 12.5912 14.4799 13.5355 13.5355L12.501 11.4344L10.0002 10L9.26172 13.1738L10 15Z" fill="#FBBE22"/>
            <path d="M6.46446 6.46484C5.52011 7.40918 5 8.66484 5 10.0004H10L6.46446 6.46484Z" fill="#FFD321"/>
            <path d="M6.46484 13.5355C7.40917 14.4799 8.66484 15 10.0004 15V10L6.46484 13.5355Z" fill="#FFD321"/>
            <path d="M13.5355 6.46445C12.5912 5.52012 11.3355 5 10 5V10L13.5355 6.46445Z" fill="#FFD321"/>
            <path d="M13.5355 13.5355C14.4799 12.5912 15 11.3355 15 10H10L13.5355 13.5355Z" fill="#FFD321"/>
            <path d="M6.61328 9.99746C6.61328 11.8687 8.13028 13.3857 10.0014 13.3857L10.8906 10.2178L10.0014 6.60938C8.13007 6.60938 6.61307 8.12615 6.61328 9.99746Z" fill="#EFAD1B"/>
            <path d="M10 6.60938V13.3857C11.8713 13.3857 13.3883 11.8687 13.3883 9.99746C13.3883 8.12617 11.8713 6.60918 10 6.60938Z" fill="#EFAD1B"/>
            <path d="M10.5421 10.6199C10.4384 10.7179 10.2871 10.747 10.1545 10.6941L8.32789 9.9664C8.21071 9.91972 8.18121 9.76754 8.27221 9.68025L9.63864 8.37244C9.73785 8.27752 9.90271 8.33025 9.92846 8.46502L10.1203 9.46658L11.5759 9.18786C11.7455 9.15543 11.8529 9.36365 11.7283 9.48297L10.5421 10.6199Z" fill="white"/>
            <path d="M10.4094 11.7335L9.30547 11.276C9.26757 11.2602 9.24043 11.2262 9.233 11.186L9.15818 10.7626C9.14161 10.669 9.23475 10.594 9.32286 10.6305L10.4094 11.0809V11.7335Z" fill="white"/>
            <path d="M8.30859 10.3125L8.48438 11.3882C8.49395 11.4472 8.56213 11.4756 8.61074 11.441L8.72677 11.3584L8.88731 11.5445C8.94045 11.606 9.04102 11.5592 9.02795 11.4789L8.87734 10.5574L8.65217 10.4605L8.30859 10.3125Z" fill="white"/>
            </g>
        </svg>


    </div>
  </template>
  
  <script setup>
  import { ref, defineProps } from 'vue';

  const props = defineProps({
    progress: {
      type: Number,
      default: 0,
    },
  });
  
  const color = ref('#E00069'); // Warna default
  </script>
  
  <style>
  .bar-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; /* Untuk positioning absolute dari icon */
  }
  
  .bar-rail {
    padding: 3px;
    background-color: #eee;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .bar-fill {
    height: 100%;
    border-radius: 8px;
    transition: width 0.5s ease;
  }
  
  .bar-icon {
    width: 50px; /* Ukuran ikon disesuaikan */
    height: 50px; /* Ukuran ikon disesuaikan */
    position: absolute; /* Posisi absolut relatif ke bar-container */
    top: 50%;
    transform: translate(-50%, -50%); /* Menyesuaikan posisi agar terpusat */
    transition: left 0.5s ease; /* Transisi halus untuk pergerakan ikon */
    z-index: 10;
  }
  </style>
  