<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <div id="dynamicHeightDiv" class="pa-5 background-style d-flex justify-center align-center">
                <div>
                    <div class="center-text">
                        <div class="px-2 mb-2 box-style">
                            <h1 class="headline headline-color font-25">Create your own tech-infused lessons!</h1>
                        </div>
                    </div>
                    <div class="center-text">
                        <div class="px-2 box-style">
                            <h2 class="headline-color font-23">It’s as simple as 1-2-3.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.adjustHeight();
        this.setBackgroundImage();
        window.addEventListener('resize', this.adjustHeight);
    },
    methods: {
        adjustHeight() {
            const width = window.innerWidth;
            const height = width * (510 / 1440);
            document.getElementById('dynamicHeightDiv').style.height = height + 'px';
            document.getElementById('dynamicHeightDiv').style.borderRadius = '0px 0px 35px 35px';
        },
        setBackgroundImage() {
            const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
            dynamicHeightDiv.style.backgroundImage = "url('./images/background-1.svg')";
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustHeight);
    }
};
</script>

<style>
.flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.flex-item {
    flex: 1; /* Mirip dengan mengambil seluruh lebar yang tersedia */
    margin-right: 0px !important;
}

/* Pastikan elemen di dalamnya tetap berpusat */
.d-flex {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
}

.center-text {
    text-align: center;
}

/* Lanjutkan dengan style lainnya seperti sebelumnya */

.background-style {
    font-family: 'League Spartan';background-size: cover;

    background-repeat: no-repeat;
    background-position: center center;
    font-size: 0.5em;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .background-style {
        font-size: 0.75em;
    }
}

@media (min-width: 1025px) {
    .background-style {
        font-size: 1em;
    }
}

.box-style {
    background-color: #003D6A;
    display: inline-block;
    border-radius: 12px;
}

.headline-color {
    color: white;
    margin: 0;
}

.font-25 {
    font-size: 2.5em;
}

.font-23 {
    font-size: 2.3em;
}
</style>