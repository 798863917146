<template>
    <div class="app-bar white-bg">
    <!-- Hamburger Menu Icon (only visible on mobile) -->
    <div class="hamburger-menu" @click="$emit('toggle-mobile-menu')" v-if="isMobile">
      <img src="/images/humburger-menu.svg" alt="Menu" />
    </div>
      <!-- User Section -->
      <div class="app-bar-section user-section">
        <a v-if="!user" href="/login" style="margin-top: 0.3em;" class="menu-link" @click="promptSignIn">Sign In</a>
        <div v-else>
          <v-menu v-model="userMenuOpen">
            <template v-slot:activator="{ on }">
              <div @click="toggleUserMenu"
                style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer"
                v-bind="on">
                <!-- Cek apakah user ada sebelum mencoba menampilkan gambar -->
                <img v-if="user.image" :src="user.image" alt="" style="height: 30px; border-radius: 50%;">
                <img src="/images/chevron-down.svg" alt="">
              </div>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="/profile">
                  <div
                    style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer;color: #003D6A">
                    <img src="/images/user-square.svg" style="height: 20px;" alt="">
                    <div style="padding-top: 6px;">Profile</div>
                  </div>
                </router-link>
              </v-list-item>
              <v-list-item>
                <div
                  style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer;color: #E00069"
                  @click="signOut">
                  <img src="/images/logout.svg" style="height: 20px;" alt="">
                  <div style="padding-top: 6px;">Sign Out</div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import cookiesService from '@/services/cookiesService';
  
  const user = ref(null);
  const userMenuOpen = ref(false);
  const isMobile = ref(window.innerWidth < 991);
  
  const handleResize = () => {
    isMobile.value = window.innerWidth < 991;
  };
  
  window.addEventListener('resize', handleResize);
  
  onMounted(() => {
    checkAuthToken();
    window.addEventListener('resize', handleResize);
  });
  
  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });
  
  const checkAuthToken = async () => {
    try {
      const authToken = await cookiesService.getCookie('authToken');
      if (authToken) {
        user.value = authToken;
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  checkAuthToken();
  
  const intervalId = setInterval(checkAuthToken, 500);
  
  function toggleUserMenu() {
    userMenuOpen.value = !userMenuOpen.value;
  }
  function signOut() {
    cookiesService.removeCookie('authToken');
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
  }
  
  function promptSignIn(event) {
    event.preventDefault();
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
  }
  </script>

<style scoped>
.hamburger-menu {
  display: none;
}

@media (max-width: 991px) {
  .hamburger-menu {
    display: block;
  }
}
</style>
