// src/services/userService.js

import http from "./httpService";

class LessonPackageService {
  async getAllGrades() {
    return await http.get("/grades");
  }

  async generateLessonPackage(data) {
    return await http.post('/lesson-packages/generate', data);
  }

  async buildLessonPackage(data) {
    return await http.post('/lesson-packages/build', data);
  }

  async getLessonPackage(id) {
    return await http.get(`/lesson-packages/view/${id}`);
  }

  async updateLessonPackage(id, data) {
    return await http.put(`/edit/${id}`, data);
  }  

  async getLessonPackageDownloads(id) {
    return await http.get(`/lesson-packages/download?lesson_package_id=${id}&resource_type=slides`);
  }  
}

export default new LessonPackageService();
