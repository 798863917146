import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import { loadFonts } from './plugins/webfontloader';
import store from './store';
import './assets/main.css';
import './assets/admin.css';
import VueGtag from 'vue-gtag';

loadFonts();

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(store);

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
}, router);

app.mount('#app');
