<template>
    <div style="position: relative;">
        <div class="flex-container">
            <div class="flex-item" style="padding: 0">
                <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">

                </div>
            </div>
        </div>

        <div v-if="isLoading" style="position: absolute;width: 100%;bottom: -65px">
            <div class="spinner-container">
                <div class="mt-5 spinner"></div>
            </div>
        </div>
        <div v-else-if="!isLoading" style="position: absolute;width: 100%;bottom: -200px">
            <div class="container py-5">
                <div class="discussion-detail-content-card mb-5">
                    <div v-if="discussion">
                        <div @click="backtoDiscussion()" class="back-button">
                            <img src="/images/chinaus/arrow-left.svg" alt="">
                            <div>
                                Back
                            </div>
                        </div>

                        <div class="profile-info-container">
                            <img v-if="!discussion.user" height="50" src="/images/chinaus/placeholder-background.png"
                                alt="Arrow Icon">
                            <img v-else-if="discussion.user" height="50" class="profile-image" :src="discussion.user.image" alt="Arrow Icon">

                            <div>
                                <div class="profile-name">{{ `${discussion.user.firstname} ${discussion.user.lastname} `  }}</div>
                                <div class="profile-time">{{ formatTime(discussion.created_at) }}</div>
                            </div>
                        </div>

                        <div class="my-1" v-html="discussion.content"></div>
                        <div style="display: flex;justify-content: center;">
                            <!-- <img height="500" src="/images/chinaus/example-image.png" alt=""> -->
                        </div>

                        <div class="my-1 like-count-container">
                            <div @click="likeDiscussion()" class="like-count image-tool-upload"><img height="20" :src="discussion.has_liked ? `/images/chinaus/liked.svg` : `/images/chinaus/like.svg`" alt="Arrow Icon"> 
                            <span :style="discussion.has_liked ? `color: #E00069 !important` : ``">{{ discussion.like_count }}</span>
                            </div>
                            <div class="like-count image-tool-upload"><img height="20" src="/images/chinaus/comment.svg" alt="Arrow Icon">
                                {{ discussion.comments.length }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!isLoading" style="margin-top: 176px;">
        <div class="container pt-5 mb-10">
            <div class="container">
                <div class="discussion-card">
                    <div class="input-text-discussion">
                        <editor-content :editor="editor" class="custom-editor-style" />
                    </div>
                    <div style=" display: flex; justify-content: space-between; ">
                        <div style="color: rgb(224, 0, 105); font-size: 0.8em;">{{ editorMessage }}</div>
                        <div style="font-size: 0.8em;">{{ totalCharacters }} / 1000</div>
                    </div>
                    <div class="tooltip-discussion-input">
                        <div class="tooltip-discussion-input-child" style="margin-top: 10px; display: flex; gap: 7px;">
                            <img class="image-tool" @click="toggleBold" height="25" src="/images/chinaus/bold.svg" alt="Bold Icon">
                            <img class="image-tool" @click="toggleItalic" height="25" src="/images/chinaus/italic.svg" alt="Italic Icon">
                            <img class="image-tool" @click="toggleUnderline" height="25" src="/images/chinaus/underline.svg" alt="Underline Icon">
                            <img class="image-tool" @click="toggleBulletList" height="25" src="/images/chinaus/bullet-points.svg" alt="Bullet Points Icon">
                        </div>
                        <div>
                            <button @click="addComment()" :disabled="isCommentLoading"
                        :class="{ 'button-send-loading': isCommentLoading }"  class="button-send">Send</button>
                        </div>
                    </div>
                </div>

                <div v-if="discussion">
                    <div v-for="comment in discussion.comments" :key="comment.id">
                        <div class="mt-5 discussion-content">
                            <div class="profile-info-container">

                            <img v-if="!comment.user" height="50" src="/images/chinaus/man01.png" alt="Arrow Icon">
                            <img v-else-if="comment.user" height="50" class="profile-image" :src="comment.user.image" alt="Arrow Icon">
                                <div>
                                    <div class="profile-name">{{ `${comment.user.firstname} ${comment.user.lastname} `  }}</div>
                                    <div class="profile-time">{{ formatTime(comment.created_at) }}</div>
                                </div>
                            </div>
                            <div class="my-1" v-html="comment.comment"></div>
                            <div class="my-4">
                                <div @click="likeDiscussionComment(comment.comment_id)" class="like-count" style="cursor: pointer;">
                                    Liked ({{ comment.like_count }})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>

<script setup>
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { onMounted, onBeforeUnmount, nextTick, ref, computed } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { useRouter, useRoute } from 'vue-router';
import discussionService from '@/services/discussionService';
import { formatDistanceToNow } from 'date-fns';

const editor = ref(null)

const route = useRoute();
const router = useRouter();

const discussion = ref(null);
const isLoading = ref(false);
const isCommentLoading = ref(false);
const editorMessage = ref('');

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 768) {
        height = width * (2000 / 1440);
    } else {
        height = width * (295 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 768) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/discusssion-background.svg')";
    }
}

onMounted(() => {
    adjustHeight();
    setBackgroundImage();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/eclipse.svg"), url("${process.env.VUE_APP_URL}/images/eclipse-2.svg")`;
    }

    getDiscussionDetail();

    editor.value = new Editor({
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder: 'Share your course experience',
            }),
            Underline,
        ],
    });
});

const isEditorEmpty = computed(() => {
    return !editor.value || editor.value.isEmpty;
});

const totalCharacters = computed(() => {
    if (!editor.value) return 0;
    return editor.value.getText().length;
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}

async function getDiscussionDetail() {
    try {
        isLoading.value = true;
        const discussionId = route.params.id;
        const response = await discussionService.getDiscussionId(discussionId);
        if (response.status == 200) {
            isLoading.value = false;
            discussion.value = response.data.data;
        } else {
            isLoading.value = false;
            console.error("Failed to post discussion even though no errors occurred", response);
        }
    } catch (error) {
        isLoading.value = false;
        console.error("Error posting discussion", error);
    }
}

async function addComment() {
    try {

        if(isEditorEmpty.value) {
            editorMessage.value = 'Please insert text';
            return;
        }

        if(editor.value.getText().length > 1000) {
            editorMessage.value = 'Cannot exceed 1000 characters';
            return;
        }

        isCommentLoading.value = true;

        const htmlContent = editor.value.getHTML();
        const data = { comment: htmlContent };

        const discussionId = route.params.id;
        const response = await discussionService.addComment(discussionId, data);
        if (response.status == 200) {
            isCommentLoading.value = false;
            editor.value.commands.setContent('');
            getDiscussionDetail();
        } else {
            isCommentLoading.value = false;
            console.error("Failed to post discussion even though no errors occurred", response);
        }
    } catch (error) {
        isCommentLoading.value = false;
        console.error("Error posting discussion", error);
    }
}

async function likeDiscussion() {
    try {
        isLoading.value = true;

        const discussionId = route.params.id;
        const response = await discussionService.likeDiscussion(discussionId);
        if (response.status == 200) {
            isLoading.value = false;
            getDiscussionDetail();
        } else {
            isLoading.value = false;
            console.error("Failed to post discussion even though no errors occurred", response);
        }
    } catch (error) {
        isLoading.value = false;
        console.error("Error posting discussion", error);
    }
}

async function likeDiscussionComment(commentId) {
    try {
        isLoading.value = true;

        const discussionId = route.params.id;
        const response = await discussionService.likeComment(discussionId, commentId);
        if (response.status == 200) {
            isLoading.value = false;
            getDiscussionDetail();
        } else {
            isLoading.value = false;
            console.error("Failed to post discussion even though no errors occurred", response);
        }
    } catch (error) {
        isLoading.value = false;
        console.error("Error posting discussion", error);
    }
}

function backtoDiscussion() {
    router.push({ path: `/china-us/discussion` });
}

function formatTime(time) {
    return formatDistanceToNow(new Date(time), { addSuffix: true });
}

function toggleBold() {
    editor.value.chain().focus().toggleBold().run();
}

function toggleItalic() {
    editor.value.chain().focus().toggleItalic().run();
}

function toggleUnderline() {
    editor.value.chain().focus().toggleUnderline().run();
}

function toggleBulletList() {
    editor.value.chain().focus().toggleBulletList().run();
}
</script>

<style>
.discussion-detail-content-card {
    background-color: #F2F3FB;
    padding: 3em 4em !important;
    border-radius: 45px;
}

.profile-name {
    color: #003D6A;
    font-weight: 500;
    font-size: 1.1em;
}

.profile-time {
    font-size: 1em;
    color: #555555;
}

.like-count {
    display: flex;
    gap: 5px;
    color: #635DFF;
}


.back-button {
    display: flex;
    color: #003D6A;
    margin-bottom: 10px;
    cursor: pointer;
    align-items: center;
    font-weight: 600;
}

.back-button div {
    margin-top: 0.1em;
}
</style>