import cookiesService from '@/services/cookiesService'

export async function isAdmin() {
  try {
    const authToken = await cookiesService.getCookie('authToken')

    if (!authToken) {
      throw new Error('authToken not found')
    }

    return authToken.user_group_id === 1
  } catch (error) {
    console.error(error)
    alert('Something went wrong. Please try again later.')

    return false
  }
}
