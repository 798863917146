<template>
    Plase Login on <a :href="url">{{ url }}</a> 
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue']);
const url = ref('')
onMounted(() => {
  emit('handleChangeHeaderFooter');
  emit('handleChangeFooterType');
  url.value = process.env.VUE_APP_LOGIN_URL;
});
</script>