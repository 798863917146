<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/faq.svg" alt="Arrow Icon" class="responsive-image-faq" />
                </div>
                <div class="label-cover">
                    <div class="white-backdrop">
                        Frequently
                    </div>
                    <div class="white-backdrop">
                        Asked
                    </div>
                    <div class="white-backdrop">
                        Questions
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NavigationBarComponent></NavigationBarComponent>

    <div class="container-china-us d-flex justify-content-center py-3" style="padding-bottom: 0px !important;">
        <div class="tabs scroll-container" style="margin-bottom: 0px !important;">
            <span :class="['tab', { 'tab-chinaus-active': activeTab === 1 }]" @click="selectTab(1)">General</span>
            <span :class="['tab', { 'tab-chinaus-active': activeTab === 2 }]" @click="selectTab(2)">Eligibility</span>
            <span :class="['tab', { 'tab-chinaus-active': activeTab === 3 }]" @click="selectTab(3)">Submission</span>
            <span :class="['tab', { 'tab-chinaus-active': activeTab === 4 }]" @click="selectTab(4)">Judging</span>
            <span :class="['tab', { 'tab-chinaus-active': activeTab === 5 }]" @click="selectTab(5)">Release of
                Results</span>
        </div>
    </div>

    <div class="welcome-page-4-dsa welcome-page-5 pb-5">

        <div class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus-accordion">
                <AccordionItem v-for="item in selectedQuestions" :key="item.title">
                    <template v-slot:title>
                        <div class="faq-title">{{ item.title }}</div>
                    </template>
                    <template v-slot:content>
                        <div class="faq-content" v-html="item.content"></div>
                    </template>
                </AccordionItem>
            </div>
        </div>


        <div class="container-china-us py-5" style="text-align: center;">
            <div class="section-header-2">Further Questions?</div>
            <a href="mailto:hello@eddy4teachers.com" class="button-email" target="_blank"
                style="text-decoration: none;">
                <img src="/images/email-white.svg" alt="Document Download">
                Contact us!
            </a>
        </div>

    </div>


</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue';
import AccordionItem from '@/views/ChinaUs/AccordionItem.vue';
import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';

const questions = [
    [
        { title: 'What is the China-US Young Maker Competition?', content: 'The China-US Young Maker Competition is an annual Maker competition celebrating the ingenuity and potential of the global Maker Movement. In this competition, individuals and teams from across China and the US show off their skills, vision, and innovation, through projects that have the potential to improve the world at large.' },
        { title: 'What are the competition’s objectives?', content: 'The objectives of the competition are threefold: <ul><li>Showcase innovative maker projects;</li><li>Recognize projects with the potential to make a positive impact on society;</li><li>Foster an international maker community, in particular amongst individuals in the United States and China.</li></ul>' },
        { title: 'What is the competition’s timeline?', content: '[UPDATED] Submissions open on <b>25 May</b> and close on <b>30 June, 23:59 (PST).</b>  Results of the US semifinals will be announced by the end of June. The grand finals will be held in the week of <b>5-11 August.</b>' },
        { title: 'How many winners will there be?', content: '10 finalists will be selected for the grand finals.' },
        { title: 'What will winners receive?', content: 'Team members in each of the top 10 projects will receive an all-expenses paid trip to China for the grand finals. Special Mention prize winners will receive a gift voucher of $50 per project.' },
        { title: 'I have a question about the US-China Young Maker Competition, which isn’t addressed here - who can I reach out to?', content: 'Please check out and post your question on our Discussion Page. We usually reply to questions there within 24h. There might also be others there with the same question as you! Alternatively, you can email <a href="mailto:terence@edm8ker.com" target="_blank"> terence@edm8ker.com </a> with your query.' },
    ],
    [
        { title: 'Who is eligible to enter the competition?', content: 'The competition is open to students and makers aged 16-40 residing in the US. Projects can be submitted individually or as a group of no more than 5 members. The Team Leader from each group must be above 18 years of age at the time of project submission.' },
        { title: 'What kinds of projects are eligible?', content: 'Any Maker projects that address a pressing challenge facing your community or which meet a societal need will be considered. The project must not have been commercialized. You may refer to the <a target="_blank" href="https://www.hackster.io/contests/2022chinausyoungmakercompetition"> 2022 competition website </a> for information on projects submitted in previous years.' },
    ],
    [
        { title: 'Why should I submit by the early submission deadline?', content: 'Projects submitted by the early submission deadline <b>(10 June, 23:59 (PST))</b> will be reviewed by our staff. We will provide specific feedback to help you improve your submission and align it with the submission criteria. It is a great opportunity to get feedback from us and fine-tune your project ahead of the final submission deadline.' },
        { title: 'When is the submission deadline?', content: '[Updated] Submissions close on <b>30 June, 23:59 (PST).</b> We regret that no late submissions will be entertained.' },
        { title: 'How and where do I submit my project?', content: 'Contestants should submit a project application to <a href="https://builder.eddy4teachers.com/china-us/submission" target="_blank">here</a>. You’ll need to <a href="https://eddy4teachers.com/login?tab=show-register" target="_blank">register</a> as a user on Eddy (edm8ker’s educator platform) prior to submission - registration is free!' },
        { title: 'Do I need to provide the information of all the members in my team?', content: 'Yes, information for all contributing team members should be included in the application.' },
        { title: 'What information do I need to provide in my submission?', content: 'The application will contain information about each team member, along with a detailed explanation of your project. If your project is complete, the application process should take no more than an hour to complete.' },
        { title: 'My project was previously submitted for another competition. Can I submit it for this one?', content: 'Yes, we accept previously-submitted projects so long as the project did not win the competition.' },
        { title: 'Will others be able to view my project once I’ve submitted?', content: 'In line with our principles of open-source sharing, all projects will be made publicly available after submission. That being said, we take a serious view of plagiarism and projects which are suspected of violating the rules of the Competition.' },
    ],
    [
        { title: 'How are contestants judged?', content: 'Contestants are judged by objective criteria and weighting as set forth in the Judging Criteria. The 10 highest-scoring contestants will be selected for the grand finals.' },
        { title: 'Who is judging the competition?', content: 'The competition will be judged by a panel of qualified judges, including representatives from the sponsors of the Competition. More information about the judging panel will be provided at a later date.' },
    ],
    [
        { title: 'How can I learn the results of the competition?', content: 'We will reach out to all Team Leaders by the end of June, with the results of judging. Please whitelist <a href="mailto:hello@edm8ker.com" target="_blank"> hello@edm8ker.com </a> to ensure that we’re able to get in touch with you!' },
        { title: 'When will the results be announced?', content: '[UPDATED] The results of the US semifinals will be announced by <b> mid-July </b>.' },
    ]
];

const selectedQuestions = ref(questions[0]);
const activeTab = ref(1);

function selectTab(index) {
    activeTab.value = index;
    selectedQuestions.value = questions[index - 1];
}


function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 540) {
        height = width * (1500 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 540) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
}


onMounted(() => {
    adjustHeight();
    setBackgroundImage();
    setBackgroundPosition();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse.svg")`;
    }

});

function setBackgroundPosition() {
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');
    const width = window.innerWidth;

    if (width <= 540) {
        welcomePageElement.style.backgroundPosition = `right top`;
    } else {
        welcomePageElement.style.backgroundPosition = `right top`;

    }

}

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}
</script>

<style>
.responsive-image-faq {
    height: 350px;
}

.faq-title {
    padding: 10px;
    font-size: 1.4em;
    color: #003D6A;
}

.faq-content {
    color: #003D6A;
}

.accordion-title-active .faq-title {
    color: #00925E;
}

.card-chinaus-accordion {
    font-family: 'League Spartan';
    display: flex;
    gap: 1em;
    background-color: rgba(242, 243, 251, 0.55);
    padding: 2em 2em;
    border-radius: 15px;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
}

.button-email {
    background-color: #00925E;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    color: white;
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-weight: 550;
}

.button-email img {
    width: 18px;
    height: auto;
}

.welcome-page-4-dsa {
    background-repeat: no-repeat;
}

.accordion-eqdsad {
    font-size: 1.2em;
    font-weight: 500;
}

.responsive-image-faq {
    height: 750px;
}

@media (max-width: 2311px) {
    .responsive-image-faq {
        height: 650px;
    }
}

@media (max-width: 1964px) {
    .responsive-image-faq {
        height: 436px;
    }
}

@media (max-width: 1307px) {
    .responsive-image-faq {
        height: 350px;
    }
}

@media (max-width: 1206px) {
    .responsive-image-faq {
        height: 300px;
    }
}

@media (max-width: 1133px) {
    .responsive-image-faq {
        height: 300px;
    }
}

@media (max-width: 1025px) {
    .responsive-image-faq {
        height: 250px;
    }
}

@media (max-width: 898px) {
    .responsive-image-faq {
        height: 250px;
    }
}

@media (max-width: 768px) {
    .responsive-image-faq {
        height: 200px;
    }
}

@media (max-width: 540px) {
    .responsive-image-faq {
        height: 250px;
    }
}

@media (max-width: 360px) {
    .responsive-image-faq {
        height: 250px;
    }
}
</style>