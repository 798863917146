<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <!-- HERO SECTION -->
            <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/online-document-bro.svg" alt="Arrow Icon" class="responsive-image-cd" />
                </div>
                <div class="label-cover">
                    <div class="white-backdrop">
                        Competition
                    </div>
                    <div class="white-backdrop">
                        Details
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NavigationBarComponent></NavigationBarComponent>

    <div class="container d-flex justify-content-center py-3">
        <div class="tabs scroll-container" style="margin-bottom: 0px !important;">
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 0 }]"
                @click="scrollToElement('eligibility', 0)">Eligibility</span>
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 1 }]"
                @click="scrollToElement('submission-requirements', 1)">Submission Requirements</span>
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 2 }]"
                @click="scrollToElement('judging-criteria', 2)">Judging Criteria</span>
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 3 }]"
                @click="scrollToElement('how-to-submit', 3)">How to Submit</span>
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 4 }]"
                @click="scrollToElement('judges', 4)">Judges</span>
            <span :class="['tab', { 'tab-chinaus-active': currentTabCompetitionDetail === 5 }]"
                @click="scrollToElement('full-rules', 5)">Full Rules</span>
        </div>
    </div>

    <div class="welcome-page-4-asd welcome-page-5">
        <div id="eligibility" class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="image-container item1">
                    <img class="card-image-china-us" src="/images/checklist-bro-2.svg" alt="Arrow Icon">
                </div>
                <div class="label-container item2">
                    <h2 class="label-card-chinaus">Eligibility</h2>
                    <ul>
                        <li> <b> Age and Residency: </b> All the members in your team need to be between 18 and 40 years
                            old and a legal resident of the United States (including all 50 states and D.C.). </li>
                            <li> <b> Team Composition: </b> You may submit as an individual, or as a group of not more than
                            5 members, including the Team Leader. If you are submitting as an individual, then you are
                            the Team Leader for your project. The Team Leader of each group needs to be above 18 years
                            old at the time of Project submission. </li>
                        <li> <b> Exclusions: </b> If anyone in your team works for certain organizations related to the
                            competition or are closely related to someone who does, you can't enter. Also, if your
                            project is already making money or has received funding, it's not eligible. </li>
                        <li> <b> Language Requirements: </b> You must be able to speak, read, write, and understand English fluently. </li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="submission-requirements" class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="label-container right-left">
                    <h2 class="label-card-chinaus">Submission Requirements</h2>
                    <ul>
                        <li> <b> Project Content: </b> All submissions must be in English. You’ll need to respond to a
                            few questions and provide a demo/pitch video (no longer than three minutes) explaining your
                            project. If you have your project ready, the application itself shouldn’t take more than an
                            hour.</li>
                        <li> <b> Technical and Content Restrictions: </b> Please respond to all the required questions
                            in the application form. Make sure your content doesn't break any laws or rules, including
                            intellectual property rights, and doesn't contain offensive material. </li>
                    </ul>
                </div>
                <div class="image-container" style="order: 1">
                    <img class="card-image-china-us" src="/images/chinaus/submission-requirement.svg" alt="Arrow Icon">
                </div>
            </div>
        </div>

        <div id="judging-criteria" class="container-china-us d-flex justify-center align-center py-7">
            <div class="card-chinaus">
                <div class="label-container">
                    <h2 class="label-card-chinaus">Judging Criteria</h2>

                    <div class="judging-criteria">
                        <div>Innovation and Creativity</div>
                        <div>25%</div>
                    </div>
                    <ul style="margin-top: 0px;">
                        <li>Does the project offer a unique approach to solving specific social, health, climate, or sustainability issues?</li>
                        <li>Is the project a completely original work from the Maker team?</li>
                    </ul>

                    <div class="border-header mt-5"></div>

                    <div class="judging-criteria">
                        <div>Rigor</div>
                        <div>25%</div>
                    </div>
                    <ul style="margin-top: 0px;">
                        <li>Is the project well-defined in its objectives and methodology?</li>
                        <li>Is the project feasible and realistic in addressing the identified issue(s)?</li>
                        <li>Is the project thorough and complete as a problem-solving solution?</li>
                    </ul>

                    <div class="border-header mt-5"></div>

                    <div class="judging-criteria">
                        <div>Community Impact</div>
                        <div>25%</div>
                    </div>
                    <ul style="margin-top: 0px;">
                        <li>Can the project foster long-term social change?</li>
                        <li>Are the needs of the target community well articulated, and does the project clearly and directly address these needs?</li>
                    </ul>

                    <div class="border-header mt-5"></div>

                    <div class="judging-criteria">
                        <div>Technical Execution</div>
                        <div>15%</div>
                    </div>
                    <ul style="margin-top: 0px;">
                        <li>Does the project exhibit craftsmanship, attention to detail, and quality in its execution?
                        </li>
                        <li>Are the project’s methodology and materials appropriate and effective for achieving its
                            goals?</li>
                    </ul>

                    <div class="border-header mt-5"></div>

                    <div class="judging-criteria">
                        <div>Commercialization Potential</div>
                        <div>10%</div>
                    </div>
                    <ul style="margin-top: 0px;">
                        <li>Is there potential to scale or commercialize the project in the future?</li>
                        <li>Does the project possess competitive advantages over alternatives and other existing
                            solutions?</li>
                        <li>Are there potential revenue streams or business models associated with the project?</li>
                    </ul>

                </div>
            </div>
        </div>

        <div id="how-to-submit" class="container-china-us d-flex justify-center align-center py-2"
            style="font-family: 'League Spartan';">
            <div class="text-center">
                <h2 class="section-header">How to Submit</h2>
            </div>
        </div>

        <div class="container-china-us responsive-container py-2" style="gap: 15px;">

            <template v-if="isMobile">

                <div class="carousel-container" style="overflow: hidden; position: relative;">
                    <!-- Item Carousel -->
                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                        </div>
                        <div class="label-container" style="height: 100%;margin-top: 25px;">
                            <h2 class="label-card-chinaus text-center">Registration</h2>
                            <div class="text-center">Register for an free account on Eddy.</div>
                            <div class="mt-3" style="display:flex;justify-content:center;"><a
                                    href="https://eddy4teachers.com/login?tab=show-register" class="button"> Register
                                    here! </a></div>
                        </div>
                    </div>

                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                        </div>
                        <div class="label-container">
                            <h2 class="label-card-chinaus text-center" style="font-size: 1.8em;">Project Submission</h2>
                            <div style="text-align: center;margin-top: 10px;">Submit your project details. You can edit your project until submissions close.</div>
                            <div style="margin-top: 20px;text-align: center;"> 
                                <div> Submissions open: <b> 25 May 2024 </b> </div>
                                <div> Early submission deadline: </div>
                                <div> <b>10 June 2024, 23:59 PST </b> </div>
                                <div> Submissions close: </div>
                                <div> <b> 22 June 2024, 23:59 hrs PST </b> </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                        </div>
                        <div class="label-container">
                            <h2 class="label-card-chinaus text-center">Judging</h2>
                            <div class="text-center">Your submission will be judged by our panel of judges.</div>
                            <div class="text-center" style="margin-top: 20px;">Finalists Announced:<br/> <b>End-June 2024</b></div>
                        </div>
                    </div>

                </div>

                <!-- Tombol navigasi -->

                <div style="display: flex; justify-content: space-evenly;">
                    <div style="display: flex;gap: 10px">
                        <button class="carousel-control-prev" @click="prevSlide">
                            <img src="/images/chinaus/vector-right.svg" alt="">
                        </button>

                        <button class="carousel-control-next" @click="nextSlide">
                            <img src="/images/chinaus/vector-left.svg" alt="">
                        </button>
                    </div>
                </div>
            </template>

            <template v-else>

                <div class="card-chinaus-column flex-start">
                    <div class="image-container text-center">
                        <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.8em;">Registration</h2>
                        <div class="text-center" style="margin-top: 10px">Register for an free account on Eddy.</div>

                        <div class="mt-3" style="display: flex;justify-content: center">
                            <a href="https://eddy4teachers.com/login?tab=show-register" class="button">
                                Register here!
                            </a>
                        </div>
                    </div>
                </div>

                <div class="card-chinaus-column flex-start">
                    <div class="image-container text-center">
                        <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.8em;">Project Submission</h2>
                        <div style="text-align: center;margin-top: 10px;">Submit your project details. You can edit your project until submissions close.</div>
                        <div style="margin-top: 20px;text-align: center;"> 
                            <div> Submissions open: <b> 25 May 2024 </b> </div>
                            <div> Early submission deadline: </div>
                            <div> <b>10 June 2024, 23:59 PST </b> </div>
                            <div> Submissions close: </div>
                            <div> <b> 22 June 2024, 23:59 hrs PST </b> </div>
                            <div> [UPDATED] Submissions close: </div>
                            <div> <b> 30 June 2024, 23:59 hrs (PST) </b> </div>
                        </div>
                    </div>
                </div>

                <div class="card-chinaus-column flex-start">
                    <div class="image-container text-center">
                        <img height="50" src="/images/chinaus/step-icon.svg" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.8em;">Judging</h2>
                        <div class="text-center" style="margin-top: 10px;">Your submission will be judged by our panel of judges.</div>
                        <div class="text-center" style="margin-top: 20px;">Finalists Announced:<br/> <b>End-June 2024</b></div>
                    </div>
                </div>

            </template>

        </div>

        <div id="judges" class="container-china-us responsive-container py-2" style="gap: 15px;">

            <div id="how-to-submit" class="container-china-us d-flex justify-center align-center py-2"
                style="font-family: 'League Spartan';">
                <div class="text-center">
                    <h2 class="section-header">Judges</h2>
                </div>
            </div>

        </div>

        <div class="container-china-us responsive-container py-2" style="gap: 15px;">

            <template v-if="isMobile">
                <div class="carousel-container-2" style="overflow: hidden; position: relative;">
                    <!-- Item Carousel -->
                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="200" src="/images/chinaus/judges/prof-liu-wei.png" alt="Arrow Icon">
                        </div>
                        <div class="label-container">
                            <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Prof. Liu Wei</h2>
                            <div class="text-center" style="margin-top: 10px;color: #003D6A;">Associate Professor, Faculty of Psychology, Beijing Normal University</div>
                            <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                            <div class="text-center" style="margin-top: 10px;color: #003D6A;">Prof. Wei serves as a board member of the International Chinese Association for Computer Human Interaction, a judge in the China-U.S. Young Maker Competition, and the director of the Design Psychology Research Center at the Beijing Design Society. He has been engaged in teaching and research in applied psychology, user experience design, and innovation and entrepreneurship education for over 15 years. He has received honors, such as the Fulbright Research Scholar and Cyrus Tang Young Scholar.</div>
                        </div>
                    </div>

                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="200" src="/images/chinaus/judges/mr-ronak-jogeshwar.png" alt="Arrow Icon">
                        </div>
                        <div class="label-container">
                            <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Mr. Ronak Jogeshwar</h2>
                            <div style="text-align: center;margin-top: 10px;color: #003D6A;"><br>Program Director, Y-Center Learning</div>
                            <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                            <div class="text-center" style="margin-top: 10px;color: #003D6A;">Ronak Jogeshwar is a leader with over a decade of experience in innovation and large-scale program implementation, focusing on social impact and maker education. At AIM, NITI Aayog, he led the Atal Tinkering Lab initiative, benefiting 10 million students and numerous startups. He established makerspaces and STEAM education programs, working with diverse stakeholders. Now, as Program Director at Y-Center Learning, he supports early-stage EdTech startups and drives educational innovation.</div>
                        </div>
                    </div>

                    <div class="card-chinaus-column" style="flex: 0 0 100%; scroll-snap-align: start;">
                        <div class="image-container text-center">
                            <img height="200" src="/images/chinaus/judges/mr-terence-tan.png" alt="Arrow Icon">
                        </div>
                        <div class="label-container">
                            <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Mr. Terence Tan</h2>
                            <div class="text-center" style="margin-top: 10px;color: #003D6A;"><br>General Manager (North America), edm8ker</div>
                            <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                            <div class="text-center" style="margin-top: 10px;color: #003D6A;">Mr Tan is the General Manager, North America for edm8ker, a global education consultancy and Maker Education pioneer in Southeast Asia. He holds an Ed.M. from the Harvard Graduate School of Education, which he attended under full scholarship. He was previously a policy officer in the Ministry of Education, Singapore, where his work impacted ~200k students in the higher education sector. Mr Tan started his career as a high school English and History teacher.</div>
                        </div>
                    </div>

                </div>

                <!-- Tombol navigasi -->

                <div style="display: flex; justify-content: space-evenly;">
                    <div style="display: flex;gap: 10px">
                        <button class="carousel-control-prev" @click="prevSlide2">
                            <img src="/images/chinaus/vector-right.svg" alt="">
                        </button>

                        <button class="carousel-control-next" @click="nextSlide2">
                            <img src="/images/chinaus/vector-left.svg" alt="">
                        </button>
                    </div>
                </div>
            </template>

            <template v-else>

                <div class="card-chinaus-column flex-start" style="padding: 2em 2em;">
                    <div class="image-container text-center">
                        <img height="150" src="/images/chinaus/judges/prof-liu-wei.png" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Prof. Liu Wei</h2>
                        <div class="text-center" style="margin-top: 10px;color: #003D6A;">Associate Professor, Faculty of Psychology, Beijing Normal University</div>
                        <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                        <div class="text-center" style="margin-top: 10px;color: #003D6A;">Prof. Wei serves as a board member of the International Chinese Association for Computer Human Interaction, a judge in the China-U.S. Young Maker Competition, and the director of the Design Psychology Research Center at the Beijing Design Society. He has been engaged in teaching and research in applied psychology, user experience design, and innovation and entrepreneurship education for over 15 years. He has received honors, such as the Fulbright Research Scholar and Cyrus Tang Young Scholar.</div>
                    </div>
                </div>

                <div class="card-chinaus-column flex-start" style="padding: 2em 2em;">
                    <div class="image-container text-center">
                        <img height="150" src="/images/chinaus/judges/mr-ronak-jogeshwar.png" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Mr. Ronak Jogeshwar</h2>
                        <div style="text-align: center;margin-top: 10px;color: #003D6A;"><br>Program Director, Y-Center Learning</div>
                        <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                        <div class="text-center" style="margin-top: 10px;color: #003D6A;">Ronak Jogeshwar is a leader with over a decade of experience in innovation and large-scale program implementation, focusing on social impact and maker education. At AIM, NITI Aayog, he led the Atal Tinkering Lab initiative, benefiting 10 million students and numerous startups. He established makerspaces and STEAM education programs, working with diverse stakeholders. Now, as Program Director at Y-Center Learning, he supports early-stage EdTech startups and drives educational innovation.</div>
                    </div>
                </div>

                <div class="card-chinaus-column flex-start" style="padding: 2em 2em;">
                    <div class="image-container text-center">
                        <img height="150" src="/images/chinaus/judges/mr-terence-tan.png" alt="Arrow Icon">
                    </div>
                    <div class="label-container">
                        <h2 class="label-card-chinaus text-center" style="font-size: 1.3em;">Mr. Terence Tan</h2>
                        <div class="text-center" style="margin-top: 10px;color: #003D6A;"><br>General Manager (North America), edm8ker</div>
                        <div style="width: 20%;height: 1px; background-color: #003D6A; margin: 0 auto;margin-top: 10px;"></div>
                        <div class="text-center" style="margin-top: 10px;color: #003D6A;">Mr Tan is the General Manager, North America for edm8ker, a global education consultancy and Maker Education pioneer in Southeast Asia. He holds an Ed.M. from the Harvard Graduate School of Education, which he attended under full scholarship. He was previously a policy officer in the Ministry of Education, Singapore, where his work impacted ~200k students in the higher education sector. Mr Tan started his career as a high school English and History teacher.</div>
                    </div>
                </div>

            </template>

        </div>

        <div id="full-rules" class="container-china-us d-flex justify-center align-center py-5">
            <div class="card-chinaus">
                <div class="image-container">
                    <img class="card-image-china-us" src="/images/chinaus/download.svg" alt="Arrow Icon">
                </div>
                <div class="label-container">
                    <h2 class="label-card-chinaus">Full Rules</h2>
                    <div>Click the button to see the full rules</div>
                    <a href="/files/2024CUSYMCRulesampampRegulations35.pdf" target="_blank" class="button-document-download mt-2">
                        <img src="/images/chinaus/document-download.svg" alt="Document Download">
                        View rules
                    </a>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref, computed } from 'vue';
import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';

const currentTabCompetitionDetail = ref(0);
const windowWidth = ref(window.innerWidth);
const isMobile = computed(() => windowWidth.value <= 540);

function updateWindowWidth() {
    windowWidth.value = window.innerWidth;
}

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 540) {
        height = width * (1500 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 540) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
}

function scrollToElement(id, index) {
    const element = document.getElementById(id);
    currentTabCompetitionDetail.value = index;
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

onMounted(() => {
    adjustHeight();
    setBackgroundImage();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);
    window.addEventListener('resize', updateWindowWidth);

    const welcomePageElement = document.querySelector('.welcome-page-4-asd');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/cd-eclipse-1.svg"), url("${process.env.VUE_APP_URL}/images/chinaus/cd-eclipse-2.svg"), url("${process.env.VUE_APP_URL}/images/chinaus/cd-eclipse-3.svg")`;
    }

});

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
    window.removeEventListener('resize', updateWindowWidth);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('competitionDetailButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}

function nextSlide() {
    const container = document.querySelector('.carousel-container');
    const slideWidth = container.clientWidth;
    container.scrollLeft += slideWidth; // Scroll sepanjang satu slide penuh
}

function prevSlide() {
    const container = document.querySelector('.carousel-container');
    const slideWidth = container.clientWidth;
    container.scrollLeft -= slideWidth; // Scroll sepanjang satu slide penuh
}

function nextSlide2() {
    const container = document.querySelector('.carousel-container-2');
    const slideWidth = container.clientWidth;
    container.scrollLeft += slideWidth; // Scroll sepanjang satu slide penuh
}

function prevSlide2() {
    const container = document.querySelector('.carousel-container-2');
    const slideWidth = container.clientWidth;
    container.scrollLeft -= slideWidth; // Scroll sepanjang satu slide penuh
}
</script>

<style>
/* Default styles */
.responsive-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.card-chinaus-column {
    font-family: 'League Spartan';
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: rgba(242, 243, 251, 0.55);
    padding: 2em 4em;
    border-radius: 45px;
    width: 100%;
}

.welcome-page-4-asd {
    background-size: 50%, 50%;
    background-repeat: no-repeat, no-repeat;
    background-position: right top, left bottom;
}

.button-document-download {
    background-color: #635DFF;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    color: white;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    font-weight: 600;
    width: 200px;
    text-decoration: none;
}

.button-document-download.disabled {
    background-color: #9898a2;
    cursor: not-allowed;
}

.button-document-download img {
    width: 18px;
    height: auto;
}

.tab-chinaus-active {
    border-bottom: 2px solid #00925E;
    color: #00925E !important;
    font-weight: 600;
}

.judging-criteria {
    display: flex;
    justify-content: space-between;
    color: #3B4648;
    font-size: 1.4em;
    font-weight: 600;
}

.carousel-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.carousel-container-2 {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.carousel-control-prev,
.carousel-control-next {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    min-width: 36px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.carousel-control-prev {
    left: 0px;
}

/* HERO SECTION styles and custom media queries */
.hero-section-3 {
    padding: 5em;
    background-color: #f0f0f0;
}

.responsive-image-cd {
    height: 650px;
}

.white-backdrop {
    color: white;
    font-size: 8em;
    font-weight: 700;
    line-height: 1.2;
}

@media (max-width: 2311px) {
    .hero-section-3 {
        height: 1200px;
        padding: 4em;
    }
    .responsive-image-cd {
        height: 436px;
    }

    .white-backdrop {
        font-size: 7em;
    }
}

@media (max-width: 1964px) {
    .hero-section-3 {
        height: 1000px;
        padding: 3.5em;
    }
    .responsive-image-cd {
        height: 436px;
    }

    .white-backdrop {
        font-size: 5em;
    }
}

@media (max-width: 1307px) {
    .hero-section-3 {
        height: 800px;
        padding: 3em;
    }
    .responsive-image-cd {
        height: 350px;
    }

    .white-backdrop {
        font-size: 5em;
    }
}

@media (max-width: 1206px) {
    .hero-section-3 {
        height: 750px;
        padding: 2.5em;
    }
    .responsive-image-cd {
        height: 300px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}

@media (max-width: 1133px) {
    .hero-section-3 {
        height: 700px;
        padding: 2em;
    }
    .responsive-image-cd {
        height: 200px;
    }

    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}

@media (max-width: 1025px) {
    .hero-section-3 {
        height: 650px;
        padding: 1.5em;
    }

    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 5em;
    }
}

@media (max-width: 898px) {
    .hero-section-3 {
        height: 600px;
        padding: 1em;
    }

    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}

@media (max-width: 768px) {
    .hero-section-3 {
        height: 550px;
        padding: 0.8em;
    }
    
    .responsive-image-cd {
        height: 200px;
    }

    .white-backdrop {
        font-size: 5em;
    }

    .responsive-container {
        flex-direction: column;
    }

    .welcome-page-4-asd {
        background-size: 100%, 100% !important;
    }

    .card-chinaus-column {
        flex: 0 0 100%;
        scroll-snap-align: start;
        min-width: 100%;
    }
}

@media (max-width: 540px) {
    .hero-section-3 {
        height: 500px;
        padding: 0.5em;
    }

    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}

@media (max-width: 360px) {
    .hero-section-3 {
        height: 450px;
        padding: 0.3em;
    }
    
    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}
</style>
