<template>
    <div style="position: relative;">
        <div v-if="!isMobile" class="flex-container">
            <div class="flex-item" style="padding: 0">
                <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                    <div class="label-cover" style="text-align: center;">
                        <div class="blue-backdrop">
                            Discussion
                        </div>
                        <div class="blue-backdrop-mini">
                            Have questions? Want to network with fellow contestants? Create a post here!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex-container">
            <div class="flex-item" style="padding: 0">
                <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                    <div class="label-cover" style="text-align: center;">
                        <div class="blue-backdrop">
                            Discussion
                        </div>
                        <div class="blue-backdrop-mini">
                            <div>
                                Have questions? Want to network with
                            </div>
                            <div>
                                fellow contestants? Create a post here!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="discussion-input-container"
            style="font-family: 'League Spartan';position: absolute;width: 100%;bottom: -105px">
            <div class="container pt-5">
                <DiscussionInputComponent :getAllDiscussion="getAllDiscussion"></DiscussionInputComponent>
            </div>
        </div>
    </div>

    <div class="welcome-page-4 content-margin-discussion">
        <div v-if="isLoading" class="spinner-container">
            <div class="mt-5 spinner"></div>
        </div>
        <div v-else class="container py-5">
            <div class="responsive-container-2">
                <div style="display: flex; gap: 10px;">
                    <img src="/images/chinaus/sort.svg" alt="">
                    <!-- Tambahkan ini -->
                    <select v-model="sort" @change="changeSort">
                        <option value="newest">Sort by Newest</option>
                        <option value="oldest">Sort by Oldest</option>
                    </select>
                </div>
            </div>
            <div class="responsive-container-2 py-5">
                <template v-if="discussion.length === 0">
                    <img src="/images/chinaus/not-yet.svg" alt="Arrow Icon"
                        class="responsive-image-submission-list">
                    <div class="text-center color-primary text-medium-bold">No discussion yet...</div>
                    <div class="text-center color-primary" style="font-size: 0.8em;">Be the first!</div>
                </template>
                <template v-else>
                    <div v-for="post in discussion" :key="post.id">
                        <DiscussionPostComponent :getAllDiscussion="getAllDiscussion"
                            @update:isLoading="handleLoading"  @update:isStatusLike="updateisStatusLike" :data="post" />
                    </div>

                    <!-- Tombol "View Other Discussion" -->
                    <div class="view-other-discussion-container">
                        <button v-if="!isLoadingOther && !allDiscussionsLoaded" @click="getAllDiscussion(currentPage, sort)" class="view-other-button">
                            View Other Discussions
                        </button>
                        <div v-if="isLoadingOther" class="spinner-container">
                            <div class="mt-5 spinner"></div>
                        </div>
                        <div v-if="allDiscussionsLoaded" class="text-center mt-4">All discussions are loaded.</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import DiscussionInputComponent from '@/components/discussion/DiscussionInputComponent.vue';
import DiscussionPostComponent from '@/components/discussion/DiscussionPostComponent.vue';
import discussionService from '@/services/discussionService';

const isLoading = ref(false);
const isLoadingOther = ref(false);
const discussion = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const sort = ref('newest');
const allDiscussionsLoaded = ref(false);
const isMobile = ref(window.innerWidth < 768);

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 768) {
        height = width * (1397 / 1440);
    } else {
        height = width * (295 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if(dynamicHeightDiv) {
        dynamicHeightDiv.style.height = `${height}px`;
    }
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 768) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-discussion-mobile.svg')";

        const discussionInputContainer = document.getElementById('discussion-input-container');
        discussionInputContainer.style.bottom = '-50px';
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/discusssion-background.svg')";
    }
}

async function getAllDiscussion(_currentpage = 1, _sort = 'newest') {
    try {
        if (isLoading.value || isLoadingOther.value) return;
        isLoadingOther.value = true;
        allDiscussionsLoaded.value = false;
        const response = await discussionService.getAllDiscussion(_currentpage, _sort);
        if (response.status == 200) {
            const newDiscussions = response.data.data.rows;
            if (newDiscussions.length > 0) {
                if(_currentpage == 1) {
                    discussion.value = [];
                }
                discussion.value.push(...newDiscussions);
                currentPage.value = response.data.data.page + 1;
                totalPages.value = response.data.data.total_pages;
                if (currentPage.value > totalPages.value) {
                    allDiscussionsLoaded.value = true;
                }
            } else {
                allDiscussionsLoaded.value = true;
            }
        } else {
            console.error("Gagal mengambil diskusi", response);
        }
    } catch (error) {
        console.error("Error saat mengambil diskusi", error);
    } finally {
        isLoadingOther.value = false;
    }
}

const changeSort = async () => {
    currentPage.value = 1;
    discussion.value = [];
    allDiscussionsLoaded.value = false;
    await getAllDiscussion(currentPage.value, sort.value);
};

onMounted(() => {
    adjustHeight();
    setBackgroundImage();
    getAllDiscussion(currentPage.value, sort.value);
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/eclipse.svg"), url("${process.env.VUE_APP_URL}/images/eclipse-2.svg")`;
    }

});

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function handleLoading(newLoadingState) {
  isLoading.value = newLoadingState;
}

function updateisStatusLike(id) {
    const element = discussion.value.find(el => el.post_id === id);
    if (element) {
        element.has_liked = !element.has_liked;
        element.like_count += element.has_liked ? 1 : -1;
    }
}
</script>

<style>
.blue-backdrop {
    color: #003D6A;
    font-size: 3em;
    font-weight: 700;
    line-height: 0.8;
    text-align: center;
    background-color: white;
    padding: 10px 5px 5px 5px;
    border-radius: 7px;
    margin-bottom: 5px;
    letter-spacing: 0;
    display: inline-block;
}

.blue-backdrop-mini {
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.1em;
    color: #003D6A;
    background-color: white;
    padding: 5px;
    border-radius: 7px;
    letter-spacing: 0;
}

.button-send {
    background-color: #003D6A;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    color: white;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    font-weight: 600;
}

.discussion-card {
    border: 1px solid #D8DAE5;
    padding: 15px 40px;
    border-radius: 10px;
}

.discussion-content-card {
    background-color: white;
    border: 1px solid #D8DAE5;
    padding: 1em 1.2em;
    border-radius: 10px;
}

.input-text-discussion {
    display: flex;
    color: #3B4648;
    gap: 10px;
    min-height: 80px;
    border-bottom: 1px solid #D8DAE5;
}

.tooltip-discussion-input {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
}

.tooltip-discussion-input-child {
    margin-top: 10px;
    display: flex;
    gap: 7px;
}

.like-count-container {
    display: flex;
    gap: 10px;
}

.discussion-content {
    border-bottom: 1px solid #D8DAE5;
}

.responsive-container-2 {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.content-margin-discussion {
    font-family: 'League Spartan';
    margin-top: 112px;
}

@media (max-width: 768px) {
    .responsive-container-2 {
        width: 100%;
        margin: 0 auto;
    }

    .discussion-card {
        border: 1px solid #D8DAE5;
        padding: 10px 20px;

        border-radius: 10px;
    }

    .blue-backdrop {
        font-size: 5em;
    }

    .blue-backdrop-mini {
        font-size: 2.3em;
    }

    .content-margin-discussion {
        margin-top: 50px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.content-margin-discussion {
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.view-other-discussion-container {
    text-align: center;
    margin-top: 20px;
}

.view-other-button {
    background-color: #003D6A;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    color: white;
    font-size: 1em;
    cursor: pointer;
    font-weight: 600;
}
</style>