
<template>
    <div class="discussion-content-card mb-5">
        <div class="discussion-content">
            <div class="profile-info-container">
                <img height="50" class="profile-image" :src="props.data.user.image" alt="Arrow Icon">
                <div>
                    <div class="profile-name">{{ `${props.data.user.firstname} ${props.data.user.lastname} ` }}
                    </div>
                    <div class="profile-time">{{ formatTime(props.data.created_at) }}</div>
                </div>
            </div>
            <div class="my-1" v-html="props.data.content"></div>
            <div class="my-1" style="display: flex;" v-if="props.data.images">
                <img :src="`${appLoginUrl}/app/${props.data.images}`" style="border-radius:1rem;max-height:300px;margin:0 auto;max-width: 100%;" alt="avt">
            </div>
            <div class="my-1 like-count-container">
                <div @click="likeDiscussion(props.data.post_id)" class="like-count image-tool-upload">
                <img height="20" :src="props.data.has_liked ? `/images/chinaus/liked.svg` : `/images/chinaus/like.svg`" alt="Arrow Icon"><span :style="props.data.has_liked ? `color: #E00069 !important` : ``">{{ props.data.like_count }}</span></div>
                <a :href="`/china-us/discussion/${props.data.post_id}`" style="text-decoration: none;" class="like-count image-tool-upload"><img height="20" src="/images/chinaus/comment.svg" alt="Arrow Icon">{{
                    props.data.comments.length }}</a>
            </div>
        </div>
        <div class="mt-3" style="display:flex;justify-content:space-between;align-items:center;">
            <div style="color:#CCCCCC;"> Be the first to comment!</div>
            <a :href="`/china-us/discussion/${props.data.post_id}`" class="button button-send"
                style="text-decoration: none;">Join Conversation</a>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { formatDistanceToNow } from 'date-fns';
import discussionService from '@/services/discussionService';

const props = defineProps({
    data: null,
    getAllDiscussion: Function,
    isLoading: Boolean
});

const emit = defineEmits(['update:isLoading','update:isStatusLike']);

const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);

function formatTime(time) {
    return formatDistanceToNow(new Date(time), { addSuffix: true });
}

async function likeDiscussion(discussionId) {
    try {

        const response = await discussionService.likeDiscussion(discussionId);
        if (response.status == 200) {
            emit('update:isStatusLike', discussionId);
        } else {
            console.error("Failed to post discussion even though no errors occurred", response);
        }
    } catch (error) {
        emit('update:isLoading', false);
        console.error("Error posting discussion", error);
    }
}
</script>