<template>
    <div style="display: flex;margin-bottom: 5em;">
        <!-- content section -->
        <div :style="{ width: defaultContentWidth + 'px' }">
            <div style="display:flex;gap:5px;justify-content:space-between;">
                <div class="header">{{ title }} </div>
                <div v-if="pageMeta==='preview'"><button @click="redirectToEditWithQueryParams" class="btn-save" style="width: 100%;padding-left: 20px;padding-right: 20px;"><img src="/images/edit.svg" alt=""> Edit</button></div>
            </div>
            <div class="border-header"></div>
            <div style="display: flex; margin: 5px;">
                <div class="chip"><!-- <img height="20" style="margin-right: 0.3em;" src="/images/ai-assisted.svg" alt="ai-assisted" /> -->AI Assisted</div>
                <div v-if="Array.isArray(softwareName) && softwareName.length > 0" class="chip"><!-- <img height="20" style="margin-right: 0.3em;" src="/images/software-icon.svg" alt="category" /> --> Software :  <span v-for="item in softwareName" :key="item" >{{ softwareName.join(', ') }}</span></div>

                <div v-if="techDomainName" class="chip">Tech Domain :  <span>{{ techDomainName }}</span></div>

                <div v-if="subjectName" class="chip">Subject :  <span>{{ subjectName }}</span></div>

                <div v-if="gradeName" class="chip">Grade :  <span>{{ gradeName }}</span></div>

                <div v-if="Array.isArray(studentProfileName) && studentProfileName.length > 0" class="chip">Student Profile :  <span v-for="item in studentProfileName" :key="item" >{{ studentProfileName.join(', ') }}</span></div>


                <!-- this.softwareName = response.data.data.resource.raw_data[selectedData - 1]['core']['software'];
                    this.techDomainName = response.data.data.resource.raw_data[selectedData - 1]['core']['tech domain'];
                    this.subjectName = response.data.data.resource.raw_data[selectedData - 1]['core']['subject'];
                    this.gradeName = response.data.data.resource.raw_data[selectedData - 1]['core']['grade'];

                    // grade: "Upper Elementary"
                    // software: ['ChatterOn']
                    // student profile: ['ELL']
                    // subject: "Social Studies"
                    // tech domain: "Artificial Intelligence"
                    // topic: "Understanding GPT-3: AI Chatbots and Their  -->
            </div>
            
            <div class="content">
                
                <div class="lesson-title">
                    Introduction
                </div>
                <div v-html="this.introContent" style="padding: 0.5rem 0;"></div>

                <div class="lesson-title">
                    Opening Activity
                </div>
                <div v-html="this.openingActivityContent" style="padding: 0.5rem 0;"></div>

                <div class="lesson-title">
                    Main Activity
                </div>
                <div v-html="this.mainActivityContent" style="padding: 0.5rem 0;"></div>

                <div class="lesson-title">
                    Closing Activity
                </div>
                <div v-html="this.closingActivityContent" style="padding: 0.5rem 0;"></div>

                <div v-html="this.assessmentContent" style="margin: 2.5rem 0;"></div>
            
            </div>
        </div>
        
    </div>
</template>

<script>
import LessonPackageService from '@/services/lessonPackageService';
import { mapState } from 'vuex';
// import { useRouter, useRoute } from 'vue-router';

export default {
    data() {
    return {
      activeTab: 'introduction',
      sidebarOpen: false,
      defaultContentWidth: null,
      introContent: '',
      openingActivityContent: '',
      mainActivityContent: '',
      closingActivityContent: '',
      assessmentContent: '',
      title: '',
      softwareName: null,
      techDomainName: null,
      subjectName: null,
      gradeName: null,
      studentProfileName: null,
      resource: null,
      pageMeta: null,
      lessonPackageId: null
    };
  },
  computed: {
    ...mapState(['formData']),
  },
  emits: ['loading','error'],
  async mounted() {
    this.setDefaultContentWidth();
    
    this.fetchPageMeta();
  
    window.addEventListener('resize', this.setDefaultContentWidth);

    this.lessonPackageId = this.$route.params.id;

    if(this.lessonPackageId) {
        await this.fetchData(this.lessonPackageId);
    }

  }, 
  methods: {  
    fetchDataFromParent() {
      const data = this.decryptDataFromURL();
      
      this.fetchData(data);
      
    },
    fetchPageMeta() {
        const pageMeta = this.$route.meta.page;
        this.pageMeta = pageMeta;
    },
    redirectToEditWithQueryParams() {
        const queryParams = this.$route.query;
        
        this.$router.push({ path: '/edit', query: queryParams });
    },
    decryptDataFromURL() {
        // Menggunakan this.$route dan this.$router untuk akses ke route dan router
        const encryptedString = this.$route.query.data;
        if (!encryptedString) {
            this.$router.push({ path: '/', query: { key: 'reset' } });
            return;
        }
        
        const decryptedString = decodeURIComponent(encryptedString);
        const redirectObject = JSON.parse(decryptedString);
        
        return redirectObject;
    },
    setActiveTab(tabName) {
      this.activeTab = tabName;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    setDefaultContentWidth() {
      const contentElement = this.$el.querySelector('.content');
      if (contentElement) {
        const marginSpace = 100;
        this.defaultContentWidth = window.innerWidth - marginSpace;
      }
    },
    findSectionByTitle(content, title) {
      const keys = Object.keys(content);

      for (const key of keys) {
        const section = content[key];
                
        if (section.title === title) {
          return section;
        }
      }

      return null;
    },
    findSectionContent(section) {
      if (!section) {
        return '';
      }

      const keys = Object.keys(section.content);

      for (const key of keys) {
        const content = section.content[key];
        return content.content;
      }
    },
    async fetchData(id) {
        this.$emit('loading', true);
        try {
            const response = await LessonPackageService.getLessonPackage(id);
            if (response.status === 200) {

                console.log("formData", this.formData.software_term_name)
                const content = response.data.data.lesson_package.content;
                this.title = response.data.data.lesson_package.title;
                this.resource = response.data.data.resource;

                const introSection = this.findSectionByTitle(content, 'Introduction');
                const openingActivitySection = this.findSectionByTitle(content, 'Opening Activity');
                const mainActivitySection = this.findSectionByTitle(content, 'Main Activity');
                const closingActivitySection = this.findSectionByTitle(content, 'Closing Activity');
                const assessmentSection = this.findSectionByTitle(content, 'Assessment');

                this.introContent = this.findSectionContent(introSection);
                this.openingActivityContent = this.findSectionContent(openingActivitySection);
                this.mainActivityContent = this.findSectionContent(mainActivitySection);
                this.closingActivityContent = this.findSectionContent(closingActivitySection);
                this.assessmentContent = this.findSectionContent(assessmentSection);

                const selectedData = response.data.data.resource.selected_data;
                if (response.data.data.resource.payload.software) {
                    this.softwareName = response.data.data.resource.payload.software;
                    this.techDomainName = response.data.data.resource.payload['tech domain'];
                    this.subjectName = response.data.data.resource.payload['subject'];
                    this.gradeName = response.data.data.resource.payload['grade'];   
                    this.studentProfileName = response.data.data.resource.payload['student profile'];      
                } else {
                    this.softwareName = response.data.data.resource.raw_data[selectedData - 1]['core']['software'];
                    this.techDomainName = response.data.data.resource.raw_data[selectedData - 1]['core']['tech domain'];
                    this.subjectName = response.data.data.resource.raw_data[selectedData - 1]['core']['subject'];
                    this.gradeName = response.data.data.resource.raw_data[selectedData - 1]['core']['grade'];
                    this.studentProfileName = response.data.data.resource.raw_data[selectedData - 1]['core']['student profile'];

                    // grade: "Upper Elementary"
                    // software: ['ChatterOn']
                    // student profile: ['ELL']
                    // subject: "Social Studies"
                    // tech domain: "Artificial Intelligence"
                    // topic: "Understanding GPT-3: AI Chatbots and Their 
                }
            } else {
                this.$emit('error', true);
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            this.$emit('error', true);
        } finally {
            this.$emit('loading', false);
        }
    }
  },
};
</script>

<style>
.container {
    width: 80%;
    margin: 0 auto;
}

.header {
    font-family: 'League Spartan' !important;
    font-size: 1.7em;
    font-weight: 600;
    margin: 0.3em 0em;
    max-width: 66vw;
    word-wrap: break-word;
}

.sub-header {
    font-size: 1.3em;
    font-weight: 500;
}

.chip {
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-family: 'League Spartan';
    color: #003D6A;
    text-decoration: none;
}

.chip:not(:last-child) {
    padding-right: 0.5em;
    border-right: 2px solid #EAECF0;
}

.content {
    font-family: 'League Spartan';
    font-size: 16px;
}

.activity-table {
    width: 60%;
    border-collapse: collapse;
}

.activity-table th,
.activity-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.activity-table th {
    background-color: #f8f8f8;
}

.activity-table td:not(:first-child) {
    /* background-color: #f1f1f1; */
}

.activity-table th:first-child,
.activity-table td:first-child {
    /* background-color: #e7e7e7; */
}

.right-sidebar {
    font-family: 'League Spartan';
    display: flex;
    flex: 0 0 50px;
    justify-content: center; 
    border-left: 2px solid #D8DAE5;
    transition: flex-basis 0.5s ease;
}

.right-sidebar.is-open {
    font-family: 'League Spartan';
    flex: 0 0 250px;
}

.category-label {
    font-size: 1.2em; 
    font-weight: 700;
}

.category-chip {
    font-size: 0.7em; 
    padding: 0.1em; 
    border-radius: 2px;
}

.category-chip-child {
    background-color: rgb(234, 236, 240);
    padding: 0.3em;
    border-radius: 3px;
}

.lesson-title {
    margin-top: 1.2em;
    margin-bottom: 0.5em;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25rem;
}
</style>