<template>
    <div class="input-container-333">
      <input
        type="text"
        placeholder="Enter Title..."
        class="title-input"
      />
      <input
        type="text"
        placeholder="Indicate the total duration of this lesson, in minutes..."
        class="duration-input"
      />
      <input
        type="text"
        placeholder="Enter Sub Title...(Max 150 Characters)"
        class="subtitle-input"
      />
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .input-container-333 {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
  }
  
  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    margin: 5px 0;
    font-size: 1.2em;
    color: #ccc;
  }
  
  input.title-input {
    font-size: 2em;
    font-weight: bold;
  }
  
  input.duration-input {
    font-size: 1em;
    color: #ccc;
  }
  
  input.subtitle-input {
    font-size: 1em;
    color: #ccc;
  }
  </style>
  