<template>
    <div class="container d-flex justify-center align-center py-5">
        <div class="card-chinaus" style="background-color: #F2F3FB;border-radius: 25px;">
            <div class="label-container">
                <h2 class="label-card-chinaus">Team Information</h2>
                
                <div class="tabs scroll-container pt-2 pb-4" style="margin-bottom: 0px !important;">
                    <!-- Tab for Leaders -->
                    <span class="tab default-flex"
                        :class="{ 'tab-chinaus-active-primary': currentTabMember === 0, 'tab-error': hasError(0) }"
                        @click="goToTabMember(0)">
                        <img v-if="hasError(0)" src="/images/exclamation-mark.svg" height="15" alt="">
                        <span>Team Leader</span>
                    </span>

                    <!-- Tabs for other members -->
                    <span v-for="(member, index) in props.teamData.members" :key="index" 
                        @click="goToTabMember(index + 1)"
                        class="tab default-flex"
                        :class="{ 'tab-chinaus-active-primary': currentTabMember === index + 1, 'tab-error': hasError(index + 1) }">
                        <img v-if="hasError(index + 1)" src="/images/exclamation-mark.svg" height="15" alt="">
                        <span>{{ `Member ${index + 1}` }} </span>
                        <img height="15" @click.stop="removeMember(index + 1)" src="/images/close.svg"
                            class="image-tool-upload filter-brown" alt="Close Icon">
                    </span>

                    <img
                        @click="addMember()"
                        :style="`${props.teamData.members.length >= 4 ? 'display: none' : ''}`"
                        src="/images/chinaus/add.svg"
                        class="image-tool-upload"
                        alt="Add"
                    />
                </div>

                <div style="display: flex;gap: 10px;justify-content: end;">

                </div>

                <!-- Form Validation -->
                <form v-if="!formLoading">
                    <div v-if="currentTabMember >= 1" class="label-members">All team members should be signed up on Eddy. Signing up takes less than a minute - share <a target="_blank" :href="`${appLoginUrl}/login?tab=show-register`">this link!</a></div>
                    
                    <div class="input-group mb-2" :class="{ 'error': errors[currentTabMember]?.user }">
                        <label for="city">Select User*</label>
                        <BaseInput2 type="select" :options="users" :maxHeight="'245'" @update:modelValue="handleUserChange"  @search-change="debouncedSearchHandler"
                            v-model="selectedMemberData.user_id" placeholder="User" :disabled="currentTabMember === 0"/>
                        <p v-if="errors[currentTabMember]?.user" class="error-message">{{ errors[currentTabMember]?.user }}</p>
                    </div>

                    <div v-if="showName">
                        <div class="input-group mb-2">
                            <label for="FirstName">First Name</label>
                            <input id="FirstName" class="input-main-2 disabled" type="text" placeholder="First Name" v-model="firstName" :disabled="currentTabMember === 0" />
                            <p v-if="errors[currentTabMember]?.firstName" class="error-message">{{ errors[currentTabMember]?.firstName }}</p>
                        </div>

                        <div class="input-group mb-2">
                            <label for="LastName">Last Name</label>
                            <input id="LastName" class="input-main-2 disabled" type="text" placeholder="Last Name" v-model="lastName" :disabled="currentTabMember === 0" />
                            <p v-if="errors[currentTabMember]?.lastName" class="error-message">{{ errors[currentTabMember]?.lastName }}</p>
                        </div>
                    </div>

                    <div class="input-group mb-2" :class="{ 'error': errors[currentTabMember]?.phone }">
                        <label for="Phone">Phone Number*</label>
                        <input id="Phone" type="text" placeholder="Phone Number" v-model="selectedMemberData.phone" required />
                        <p v-if="errors[currentTabMember]?.phone" class="error-message">{{ errors[currentTabMember]?.phone }}</p>
                    </div>

                    <div class="input-group mb-2" :class="{ 'error': errors[currentTabMember]?.dateofBirth }">
                        <label for="DateofBirth">Date of Birth*</label>
                        <VueDatePicker v-model="formattedDob" :enableTimePicker="false" menuView="calendar" format="yyyy-MM-dd" :autoApply="true" />
                        <p v-if="errors[currentTabMember]?.dateofBirth" class="error-message">{{ errors[currentTabMember]?.dateofBirth }}</p>
                    </div>

                    <div class="input-group mb-2" :class="{ 'error': errors[currentTabMember]?.state }">
                        <label for="city">State*</label>
                        <BaseInput2 type="select" :options="states" :maxHeight="'245'" @update:modelValue="handleStateChange" v-model="selectedMemberData.state" placeholder="State" />
                        <p v-if="errors[currentTabMember]?.state" class="error-message">{{ errors[currentTabMember]?.state }}</p>
                    </div>

                    <div class="input-group mb-4" :class="{ 'error': errors[currentTabMember]?.city }">
                        <label for="city">City*</label>
                        <input id="city" type="text" placeholder="City" v-model="selectedMemberData.city" required />
                        <p v-if="errors[currentTabMember]?.city" class="error-message">{{ errors[currentTabMember]?.city }}</p>
                    </div>
                    
                    <div class="input-group mb-5" v-if="parentalConsentNeeded" :class="{ 'error': errors[currentTabMember]?.parentalConsent }">
                        <label for="ParentalConsent">Parental Consent*</label>
                        <div class="fs-08 italic">If you are below 18 years old at the point of submission, please
                            download the following form, get your parent or guardian to sign it, and upload the
                            completed form here. One form is needed for each team member who is below 18.</div>

                        <div class="fs-08 default-flex purple-filter mt-2" style="cursor: pointer" @click="parentalConsentDownload">
                            <img height="13" src="/images/chinaus/document-download.svg" alt="Document Download">
                            <div style="margin-top: 0.2em;">Download form here</div>
                        </div>
                        <FileUploadComponent v-model="selectedMemberData.parental_consent_url" :defaultLabel="'Upload Parental Consent Form'" :allowedTypes="['pdf', 'docx', 'doc']" />
                        <p v-if="errors[currentTabMember]?.parentalConsent" class="error-message">{{ errors[currentTabMember]?.parentalConsent }}</p>
                    </div>

                    <div style="display: flex;gap: 10px;justify-content: end;">
                        <button @click="saveSubmission" :class="{ 'button-competition-loading': props.isLoading, 'button-competition': !props.isLoading }" type="button">
                            <img src="/images/chinaus/save.svg" alt="Save Progress">
                            Save Progress
                        </button>

                        <button @click="validateForm()" class="button button-email" type="button">
                            Next Page
                        </button>
                    </div>
                </form>
                <div v-else class="spinner-container">
                    <div class="mt-5 spinner"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed, defineProps, defineEmits } from 'vue';
import FileUploadComponent from '@/components/FileUploadComponent.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import BaseInput2 from '@/components/BaseInput2.vue';
import usStateService from '@/services/usStateService';
import userService from '@/services/userService';
import '@vuepic/vue-datepicker/dist/main.css';
import { differenceInYears } from 'date-fns';
import { debounce } from 'lodash';

const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);

const props = defineProps({
    teamData: {
        type: Object,
        required: true
    },
    isLoading: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['go-to-step', 'save-submission']);
const firstMounted = ref(true);
const formLoading = ref(true);

onMounted(async () => {
    if (firstMounted.value) {
        firstMounted.value = false;
        await getAllUser('');
        getAllStates();
        goToTabMember(0);
    }
});

function formatDate(date) {
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
}

const formattedDob = computed({
  get() {
    return selectedMemberData.value.date_of_birth ? formatDate(selectedMemberData.value.date_of_birth) : '';
  },
  set(value) {
    selectedMemberData.value.date_of_birth = formatDate(value);
    selectedMemberData.value.date_of_birth = formatDate(value);
  }
});

const currentTabMember = ref(0);
const members = ref(['Team Leader', 'Member 1']);

const showName = ref(false);
const firstName = ref('');
const lastName = ref('');

const parentalConsentNeeded = computed(() => {
    if (selectedMemberData.value.date_of_birth) {
        const age = differenceInYears(new Date(), new Date(selectedMemberData.value.date_of_birth));
        return age < 18;
    }
    return false;
});

/* eslint-disable */
const cities = ref([]);
const states = ref([]);
const users = ref([]);
const errors = ref([]);

const userIds = ref([]) 

async function getAllStates() {
    try {
        const response = await usStateService.getAllStates();
        if (response.status === 200) {
            const stateOptions = response.data.data.map((state) => ({
                text: state,
                value: state
            }));
            states.value = stateOptions;
        } else {
            console.error("Failed to retrieve states: Status code", response.status);
        }
    } catch (error) {
        console.error("Failed to get state", error);
    }
}

async function getCitiesbyState(state) {
    try {
        const response = await usStateService.getCityByState(state) ;
        if (response.status === 200) {
            const stateOptions = response.data.data.map((state) => ({
                text: state,
                value: state
            }));
            cities.value = stateOptions;
        } else {
            console.error("Failed to retrieve cities: Status code", response.status);
        }
    } catch (error) {
        console.error("Failed to get cities", error);
    }
}

const getUserIds = () => {
    const ids = [];
    ids.push(props.teamData.leader.user_id);
    props.teamData.members.forEach(member => {
        ids.push(member.user_id);
    });
    return ids;
};

function addMember() {
    if (members.value.length >= 5) {
        return; // Stop execution
    }

    const newMemberNumber = members.value.length;
    members.value.push(`Member ${newMemberNumber}`);

    props.teamData.members.push({
        user_id: null,
        phone: '',
        date_of_birth: null,
        state: '',
        city: '',
        parental_consent_url: ''
    });

    goToTabMember(newMemberNumber);
}

const removeMember = (index) => {
    if (index > 0 && index <= props.teamData.members.length) {
        members.value.splice(index, 1);
        props.teamData.members.splice(index - 1, 1);
        errors.value.splice(index, 1);
        
        if (currentTabMember.value === index) {
            goToTabMember(index - 1);
        } else if (currentTabMember.value > index) {
            goToTabMember(currentTabMember.value - 1);
        }

        console.log('error', errors)
    }
}


function validateForm() {
    for (let i = 0; i < members.value.length; i++) {
        errors.value[i] = {};

        const member = i === 0 ? props.teamData.leader : props.teamData.members[i - 1];
        if (!member.user_id) {
            errors.value[i].user = 'User is required.';
        }
        if (!member.phone) {
            errors.value[i].phone = 'Phone Number is required.';
        }
        if (!member.date_of_birth) {
            errors.value[i].dateofBirth = 'Date of Birth is required.';
        }
        if (!member.city) {
            errors.value[i].city = 'City is required.';
        }
        if (!member.state) {
            errors.value[i].state = 'State is required.';
        }
        
        if(parentalConsentNeeded.value) {
            if (!member.parental_consent_url) {
                errors.value[i].parentalConsent = 'Parental Consent is required.';
            }
        } else {
            delete errors.value[i].parentalConsent;
        }
    }

    const allValid = !Object.keys(errors.value).some(key => Object.keys(errors.value[key]).length > 0);

    if (allValid) {
        gotoStep(2);
    }
}

function hasError(index) {
    return errors.value[index] && Object.keys(errors.value[index]).length > 0;
}

const handleStateChange = (selectedState) => {
    getCitiesbyState(selectedState);
}

const parentSearchHandler = (name) => {
    getAllUser(name);
}

async function getAllUser(name) {
    try {
        const response = await userService.getAllUser(name);
        if (response.status === 200) {
            users.value = [];
            
            response.data.data.rows.forEach((state) => {
                if (state.firstname === '') {
                    return;
                }

                if (state.lastname === '0') {
                    return;
                }

                users.value.push({
                    text: `${state.firstname ? state.firstname : ''} ${state.lastname ? state.lastname : ''}`,
                    firstname: state.firstname,
                    lastname: state.lastname,
                    user_id: state.user_id,
                    value: state.user_id,
                    email: state.email,
                    disabled: userIds.value.includes(state.user_id)
                });
            });

            userIds.value = getUserIds();
            await getSelectedUser('', userIds.value);
        } else {
            console.error("Failed to retrieve states: Status code", response.status);
        }
    } catch (error) {
        console.error("Failed to get state", error);
    }
}

async function getSelectedUser(name, _userIds) {
    try {
        const response = await userService.getAllUser(name, _userIds);
        if (response.status === 200) {
            response.data.data.rows.forEach((state) => {
                const exists = users.value.some(user => user.value === state.user_id);
                
                if (!exists) {
                    users.value.push({
                        text: `${state.firstname ? state.firstname : ''} ${state.lastname ? state.lastname : ''}`,
                        firstname: state.firstname,
                        lastname: state.lastname,
                        user_id: state.user_id,
                        value: state.user_id,
                        email: state.email,
                        disabled: userIds.value.includes(state.user_id)
                    });
                }
            });

        } else {
            console.error("Failed to retrieve users: Status code", response.status);
        }
    } catch (error) {
        console.error("Failed to get users", error);
    }
}

const debouncedSearchHandler = debounce(parentSearchHandler, 300);

const handleUserChange = (selectedUser) => {
    firstName.value = '';
    lastName.value = '';
    const selected = users.value.find(user => user.value === selectedUser);

    if (selected) {
        showName.value = true;
        firstName.value = selected.firstname;
        lastName.value = selected.lastname;

        formLoading.value = false;
        if (currentTabMember.value === 0) {
            props.teamData.leader.user = selected;
        } else if (currentTabMember.value > 0) {
            const memberIndex = currentTabMember.value - 1;
            props.teamData.members[memberIndex].user = selected;
        }
    } else {
        console.log('User not found');
    }
};

const selectedMemberData = ref({
  date_of_birth: ''
});

const goToTabMember = async (index) => {
    firstName.value = '';
    lastName.value = '';
    currentTabMember.value = index;
    const isLeader = index === 0;
    const memberData = isLeader ? props.teamData.leader : props.teamData.members[index - 1];

    if (memberData) {
        selectedMemberData.value = memberData;

        if (memberData.user_id) {
            handleUserChange(memberData.user_id);
        }

        if (memberData.state) {
            await getCitiesbyState(memberData.state);
        }
    } else {
        console.error('memberData is undefined');
    }
};

function gotoStep(index) {
    emit('go-to-step', index);
}

function saveSubmission() {
    emit('save-submission');
}

const baseUrl = process.env.VUE_APP_URL;

function parentalConsentDownload() {
  const link = document.createElement('a');
  link.href = `${baseUrl}/files/Parental_Consent_Eddy.pdf`;
  link.download = 'Parental_Consent_Eddy.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

</script>

<style>
.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
    padding: 9px 35px 5px 32px !important;
}

.tab-error {
    color: #E00069 !important;
}

.label-members {
    color: #3B4648;
    font-family: 'League Spartan';
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 8px !important;
}
</style>
