<template>
    <v-container v-if="isLoading" class="center-container">
        <div style="width: 100%;">
            <LoadingComponent></LoadingComponent>
        </div>
    </v-container>
    
    <GeneratedPrintComponent ref="GeneratedPrintComponentRef" v-show="!isLoading && !isError"  @loading="handleLoading" @error="handleError"></GeneratedPrintComponent>

    <v-container v-show="!isLoading && isError">
        <ErrorGenerate @click="handleReload"></ErrorGenerate>
    </v-container>

</template>
    
<script setup>
import { defineEmits } from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GeneratedPrintComponent from '@/components/print/GeneratedPrintComponent.vue';
import { ref, onMounted } from 'vue';
import ErrorGenerate from '@/components/ErrorGenerate.vue';

const generatePhase = ref('1');
const isLoading = ref(false);
const isError = ref(false);
const emit = defineEmits(['change-header-footer', 'fetch-data']);
const GeneratedPrintComponentRef = ref(null);

const setGeneratePhase = (newPhase) => {
    generatePhase.value = newPhase;
};

const handleLoading = (isLoadingValue) => {
  isLoading.value = isLoadingValue;
};

const handleError = (isErrorValue) => {
    isError.value = isErrorValue;
};

const handleReload = () => {
    if (GeneratedPrintComponentRef.value) {
        GeneratedPrintComponentRef.value.fetchDataFromParent();
    }
};

onMounted(() => {
    setGeneratePhase(3);
    emit('handleChangeFooterType', 'simplified-footer');
});

</script>