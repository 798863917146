/* eslint-disable */ 
<template>
    <div style="display: flex;margin-bottom: 5em;">

        <div id="processingOverlay"
            style="display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 1000;">
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;">
                Processing...
            </div>
        </div>
        <!-- content section -->
        <div :style="{ marginLeft: '50px', marginRight: '20px', width: defaultContentWidth + 'px' }">

            <div style="display:flex;gap:5px;justify-content:space-between;">
                <div class="header">{{ title }} </div>
                <div style="display: flex; align-items: center;" v-if="pageMeta === 'preview'">
                    <button @click="redirectToEditWithQueryParams" class="btn-save" style="width: 100%;padding-left: 20px;padding-right: 20px;"><img src="/images/edit.svg" alt=""> Edit</button></div>
                </div>
            <div class="border-header"></div>
            <div style="display: flex; margin: 0.6em 0em;">
                <div class="chip"><img height="20" style="margin-right: 0.3em;" src="/images/ai-assisted.svg"
                        alt="ai-assisted" />AI Assisted</div>
                <div v-if="Array.isArray(softwareName) && softwareName.length > 0" class="chip"><img height="20" style="margin-right: 0.3em;"
                        src="/images/software-icon.svg" alt="category" />Software :<span class="ml-2" v-for="item in softwareName"
                        :key="item">{{ softwareName.join(',') }}</span></div>
                <div @click="getAndOpenPDF" class="chip" :style="{ cursor: pageMeta === 'preview' ? 'pointer' : 'default', color: pageMeta === 'preview' ? 'initial' : 'grey' }">
                    <img height="20" style="margin-right: 0.3em;" :src="pageMeta === 'preview' ? '/images/category-icon.svg' : '/images/category-icon-disabled.svg'" alt="ai-assisted" />{{ buttonText }}
                </div>
                <div @click="openTeacherMode"  :style="{ cursor: pageMeta === 'preview' ? 'pointer' : 'default', color: pageMeta === 'preview' ? 'initial' : 'grey' }" class="chip">
                    <img height="20" style="margin-right: 0.3em;" :src="pageMeta === 'preview' ? '/images/teacher-mode.svg' : '/images/teacher-mode-disabled.svg'" alt="ai-assisted" />Teacher Mode</div>
            </div>
            <div class="tabs">
                <span class="tab" :class="{ active: activeTab === 'introduction' }"
                    @click="setActiveTab('introduction')">Introduction</span>
                <span class="tab" :class="{ active: activeTab === 'opening' }" @click="setActiveTab('opening')">Opening
                    Activity</span>
                <span class="tab" :class="{ active: activeTab === 'main-activity' }"
                    @click="setActiveTab('main-activity')">Main Activity</span>
                <span class="tab" :class="{ active: activeTab === 'closing-activity' }"
                    @click="setActiveTab('closing-activity')">Closing Activity</span>
                <span class="tab" :class="{ active: activeTab === 'assessment' }"
                    @click="setActiveTab('assessment')">Assessment</span>
            </div>
            <div class="content">

                <div id="introduction-content" v-if="activeTab === 'introduction'" v-html="introContent" style="padding: 0.5rem 0;"></div>
                <div id="opening-content" v-if="activeTab === 'opening'" v-html="openingActivityContent" style="padding: 0.5rem 0;">
                </div>
                <div id="main-activity-content" v-if="activeTab === 'main-activity'" v-html="mainActivityContent" style="padding: 0.5rem 0;">
                </div>
                <div id="closing-activity-content" v-if="activeTab === 'closing-activity'" v-html="closingActivityContent"
                    style="padding: 0.5rem 0;"></div>
                <div id="assessment-content" v-if="activeTab === 'assessment'" v-html="assessmentContent" style="padding: 0.5rem 0;"></div>

            </div>

            <p class="title" style="margin-top: 1rem;">
                Resources
            </p>

            <div style="margin-top: 1.25rem; display: flex; flex-direction: column; gap: 1.25rem;">
                <a class="standard-text button-blue" @click="downloadResources"
                    style="display: flex;gap: 0.5em;cursor: pointer;">
                    <img src="/images/document-upload.svg" alt="" style="margin-top: 0.1em;"> Teacher’s Deck
                </a>
            </div>

        </div>


        <ContentSidebar :resource="resource" :progress="progressCount" :originalAuthor="originalAuthor" @save="saveLessonPackage"/>

    </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineExpose, nextTick } from "vue";
import ContentSidebar from "@/components/ContentSidebar.vue";
import LessonPackageService from "@/services/lessonPackageService";
// eslint-disable-next-line
import { countContent, countProgress } from "@/services/cmsEditorProgressService";
import { Node, mergeAttributes } from "@tiptap/core";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import { Editor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { useRoute, useRouter } from 'vue-router';
import { TextSelection } from 'prosemirror-state';
import Underline from '@tiptap/extension-underline'

const saveLessonPackage = async (title = '') => {
    const lessonPackageId = route.params.id;

    const contentData = {
        "save_as": title,
        "progress": progressCount.value,
        "grade": "",
        "subject": "",
        "domain": "",
        "software": "",
        "student_profile": [],
        "content": content.value
    }
    
    const response = await LessonPackageService.updateLessonPackage(lessonPackageId, contentData);
    if (response.status === 200) {
        if(title && title != '') {
            router.push({ path: `/lesson-packages/${response.data.data}` });   
        } else {
            router.push({ path: `/lesson-packages/${lessonPackageId}` });   
        }
    } else {
        emit("error", true);
        throw new Error(`Request failed with status ${response.status}`);
    }
};

const selectionStart = ref(0);
const selectionEnd = ref(0);

const mouseSelectionPlugin = new Plugin({
    view(editorView) {
        const handleSelectionChange = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                if (editorView.dom.contains(range.commonAncestorContainer)) {
                    const startPos = editorView.posAtDOM(range.startContainer, range.startOffset);
                    const endPos = editorView.posAtDOM(range.endContainer, range.endOffset);

                    // Memperbarui ref Vue
                    selectionStart.value = startPos;
                    selectionEnd.value = endPos;
                    
                    console.log("Seleksi mouse dari:", startPos, "sampai:", endPos);
                }
            }
        };

        document.addEventListener("selectionchange", handleSelectionChange);

        return {
            destroy() {
                document.removeEventListener("selectionchange", handleSelectionChange);
            },
        };
    },
});
const selectionChangePlugin = new Plugin({
  // eslint-disable-next-line 
  view(editorView) {
    return {
      // eslint-disable-next-line 
      update(view, prevState) {
        view.state.tr.steps.forEach((step) => {
             // eslint-disable-next-line 
          if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
            const selection = view.state.selection;
            console.log(`Seleksi dari: ${selection.from} sampai: ${selection.to}`);
          }
        });
      }
    };
  }
});
const PreventDeletionOfSpecificHTMLElements = Extension.create({
    name: "preventDeletionOfSpecificHTMLElements",
    addKeyboardShortcuts() {
        return {
            Backspace: ({ editor }) => {
                const { selection } = editor.state;
                const { empty, anchor } = selection;
                if (empty) {
                    const posBefore = anchor - 1;
                    const resolvedPos = editor.state.doc.resolve(posBefore);
                    const nodeBefore = resolvedPos.nodeBefore;
                    if (nodeBefore && nodeBefore.type.name === "paragraph") {
                        return true;
                    }
                }
                return false;
            },
        };
    },
});
const PreventBackspaceDeletion = Extension.create({
    name: "preventBackspaceDeletion",
    addKeyboardShortcuts() {
        return {
            Backspace: () => {
                // eslint-disable-next-line
                const { state, dispatch } = this.editor;
                const { selection } = state;
                const { $anchor } = selection;
                if ($anchor.pos === 1) {
                    return true;
                }
                return false;
            },
        };
    },
});
const CustomParagraph = Node.create({
    name: "paragraph",
    group: "block",
    content: "inline*",
    addAttributes() {
        return {
            class: { default: null },
            style: { default: null },
        };
    },
    parseHTML() {
        return [
            {
                tag: "p",
                getAttrs: (node) => ({
                    class: node.getAttribute("class"),
                    style: node.getAttribute("style"),
                }),
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ["p", mergeAttributes(HTMLAttributes), 0];
    },
});
const CustomBulletList = Node.create({
    name: "bulletList",
    group: "block",
    content: "listItem+",
    addAttributes() {
        return {
            class: { default: null },
            style: { default: null },
        };
    },
    parseHTML() {
        return [{ tag: "ul" }];
    },
    renderHTML({ HTMLAttributes }) {
        return ["ul", mergeAttributes(HTMLAttributes), 0];
    },
});
const CustomListItem = Node.create({
    name: "listItem",
    content: "paragraph block*",
    addAttributes() {
        return {
            class: { default: null },
            style: { default: null },
        };
    },
    parseHTML() {
        return [{ tag: "li" }];
    },
    renderHTML({ HTMLAttributes }) {
        return ["li", mergeAttributes(HTMLAttributes), 0];
    },
});

const activeTab = ref("introduction");
const sidebarOpen = ref(false);
const defaultContentWidth = ref(null);
const introContent = ref("");
const openingActivityContent = ref("");
const mainActivityContent = ref("");
const closingActivityContent = ref("");
const assessmentContent = ref("");
const title = ref("");
const softwareName = ref(null);
const resource = ref(null);
const pageMeta = ref(null);
const buttonText = ref("Print as PDF");
const contentId = ref(null);
const content = ref(null);
// eslint-disable-next-line
const countContentOriginal = ref(null);
const countContentEdited = ref(null);
const progressCount = ref(5);

const isEditing = ref(false);
// eslint-disable-next-line
const originalHtmlContent = ref("");
const editingElement = ref(null);
const DynamicEditor = ref(null);
const originalAuthor = ref(null);
const teacherModeId = ref(null);

const route = useRoute();
const router = useRouter();


const emit = defineEmits(['loading', 'error']);
const isHtmlMode = ref(false); // State untuk melacak mode HTML

const showProcessingOverlay = (show) => {
    const overlay = document.getElementById("processingOverlay");
    if (overlay) {
        overlay.style.display = show ? "block" : "none";
    }
};
const applyHoverEffects = () => {
    const selectors = ['p:not(li p)', 'ul', 'ol'];
    selectors.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {

            element.addEventListener('mouseenter', function () {
                this.style.color = '#87898a';
                this.style.cursor = 'pointer';
            });
            element.addEventListener('mouseleave', function () {
                this.style.color = '';
                this.style.cursor = '';
            });
            element.addEventListener('click', () => {
                const saveButton = document.querySelector('.save-changes-button');

                if (saveButton) {
                    saveButton.click(); // Programmatically trigger a save button click
                }

                element.style.color = '';
                element.style.cursor = '';
                editingElement.value = element;

                if (!isEditing.value) {
                    isEditing.value = true;
                    
                    // Assuming showTiptapEditor is another method you have
                    showTiptapEditor(element, element.outerHTML);
                }
            });
        });
    });
};

const showTiptapEditor = (element, originalContent) => {
    showProcessingOverlay(true);
    if (DynamicEditor.value) {
        const saveButton = document.querySelector(".save-changes-button");
        if (saveButton) {
            saveButton.click();
        }
    }
    setTimeout(() => {
        const editorContainer = document.createElement("div");
        editorContainer.style.position = "relative";
        editorContainer.style.zIndex = "1010";
        const pageOverlay = document.createElement("div");
        pageOverlay.style.cssText =
            "position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000;";
        document.body.appendChild(pageOverlay);
        pageOverlay.addEventListener("click", () => {
            const saveButton = document.querySelector(".save-changes-button");
            if (saveButton) {
                saveButton.click();
            }
            pageOverlay.remove();
        });
        const toolbarHTML = `<div style="display: flex; justify-content: center;">
                <img class="button-tiptap" src="/images/tiptap-bar/undo.svg" alt="Undo" data-action="undo">
                <img class="button-tiptap" src="/images/tiptap-bar/redo.svg" alt="Redo" data-action="redo">
                <img class="button-tiptap" src="/images/tiptap-bar/bold.svg" alt="Bold" data-action="bold">
                <img class="button-tiptap" src="/images/tiptap-bar/italic.svg" alt="Italic" data-action="italic">
                <img class="button-tiptap" src="/images/tiptap-bar/underline.svg" alt="Underline" data-action="underline">
                <img class="button-tiptap" src="/images/tiptap-bar/strokes.svg" alt="Strike" data-action="strike">
                <img class="button-tiptap" src="/images/tiptap-bar/unordered-list.svg" alt="Bullet List" data-action="bulletList">
                <img class="button-tiptap" src="/images/tiptap-bar/ordered-list.svg" alt="Ordered List" data-action="orderedList">
            </div>`;
        const toolbarContainer = document.createElement("div");
        toolbarContainer.innerHTML = toolbarHTML;
        editorContainer.appendChild(toolbarContainer);
        const editorContent = document.createElement("div");
        editorContent.setAttribute("id", "dynamic-editor");
        editorContent.setAttribute("contenteditable", "true");
        editorContent.style.cssText =
            "border: 1px solid #ccc; min-height: 200px; margin-top: 10px;padding: 0px 10px;";
        editorContainer.appendChild(editorContent);
        element.replaceWith(editorContainer);
        setTimeout(() => {
            initEditor("dynamic-editor", originalContent);
            setTimeout(() => {
                setTimeout(() => {
                    addSaveButtonLogic(editorContainer);
                    setTimeout(() => {
                        setupToolbarActions(toolbarContainer, DynamicEditor.value);
                        showProcessingOverlay(false);
                    }, 200);
                }, 200);
            }, 200);
        }, 200);
    }, 200);
};
const setupToolbarActions = (toolbar, editor) => {
    toolbar.querySelectorAll(".button-tiptap").forEach((button) => {
        button.addEventListener("click", (event) => {
            event.preventDefault();
            if (!editor || !editor.view) {
                console.log("Editor belum diinisialisasi atau tidak ditemukan.");
                return;
            }

            // Make sure the editor has focus
            editor.view.focus();

            // Move to a saved selection before executing the command
            editor.view.dispatch(
                editor.view.state.tr.setSelection(
                    TextSelection.create(editor.view.state.doc, selectionStart.value, selectionEnd.value)
                )
            );

            const action = button.getAttribute("data-action");
            switch (action) {
                case "undo":
                    editor.chain().focus().undo().run();
                    break;
                case "redo":
                    editor.chain().focus().redo().run();
                    break;
                case "bold":
                    editor.chain().focus().toggleBold().run();
                    break;
                case "italic":
                    editor.chain().focus().toggleItalic().run();
                    break;
                case "underline":
                    editor.chain().focus().toggleUnderline().run(); // Menambahkan garis bawah
                    break;
                case "strike":
                    editor.chain().focus().toggleStrike().run(); // Menambahkan coretan
                    break;
                case "bulletList":
                    editor.chain().focus().toggleBulletList().run(); // Menambahkan daftar tidak berurut
                    break;
                case "orderedList":
                    editor.chain().focus().toggleOrderedList().run(); // Menambahkan daftar berurut
                    break;
                case "htmlMode":
                    toggleHtmlMode();
                    break;
                // Tambahkan lebih banyak kasus sesuai kebutuhan
            }
        });
    });
};

const initEditor = (selector, content) => {
    const element = document.getElementById(selector);
    if (element) {
        DynamicEditor.value = new Editor({
            element: element,
            extensions: [
                StarterKit.configure({
                    paragraph: false,
                    bulletList: false,
                    listItem: false,
                }),
                CustomParagraph,
                CustomBulletList,
                CustomListItem,
                BulletList,
                ListItem,
                OrderedList,
                PreventBackspaceDeletion,
                PreventDeletionOfSpecificHTMLElements,
                Underline,
            ],
            content: content,
            editorProps: {
                plugins: [selectionChangePlugin, mouseSelectionPlugin],
            },
        });
        addSaveButtonLogic(element.parentElement);
    } else {
        console.error("Element not found for selector: " + selector);
    }
};
const addSaveButtonLogic = (editorComponent) => {
    let saveButton = editorComponent.querySelector(".save-changes-button");
    if (!saveButton) {
        saveButton = document.createElement("button");
        saveButton.textContent = "Save Changes";
        saveButton.className = "save-changes-button";
        saveButton.style.visibility = "hidden";
        editorComponent.appendChild(saveButton);
        saveButton.addEventListener("click", () => {
            const updatedContent = DynamicEditor.value.getHTML();
            const tempElement = document.createElement("div");
            tempElement.innerHTML = updatedContent;
            const newContentElement = tempElement.firstChild;
            if (editorComponent && newContentElement) {
                editorComponent.replaceWith(newContentElement);
            }
            DynamicEditor.value.destroy();
            DynamicEditor.value = null;
            saveButton.remove();
            isEditing.value = false;

            const realcontent = document.getElementById(activeTab.value + '-content');

            if(realcontent) {
                if(activeTab.value === 'introduction') {

                    countContentEdited.value = countContent(realcontent.innerHTML, openingActivityContent.value, mainActivityContent.value, closingActivityContent.value, assessmentContent.value);
                    progressCount.value = countProgress(countContentOriginal.value, countContentEdited.value);
                    
                    introContent.value = realcontent.innerHTML;
                    updateSectionByTitle(introContent.value, 'Introduction');
                        
                } else if(activeTab.value === 'opening') {

                    countContentEdited.value = countContent(introContent.value, realcontent.innerHTML, mainActivityContent.value, closingActivityContent.value, assessmentContent.value);
                    progressCount.value = countProgress(countContentOriginal.value, countContentEdited.value);
                    
                    openingActivityContent.value = realcontent.innerHTML
                    updateSectionByTitle(openingActivityContent.value, 'Opening Activity');
                        
                } else if(activeTab.value === 'main-activity') {

                    countContentEdited.value = countContent(introContent.value, openingActivityContent.value, realcontent.innerHTML, closingActivityContent.value, assessmentContent.value);
                    progressCount.value = countProgress(countContentOriginal.value, countContentEdited.value);
                    
                    mainActivityContent.value = realcontent.innerHTML
                    updateSectionByTitle(mainActivityContent.value, 'Main Activity');

                } else if(activeTab.value === 'closing-activity') {

                    countContentEdited.value = countContent(introContent.value, openingActivityContent.value, mainActivityContent.value, realcontent.innerHTML, assessmentContent.value);
                    progressCount.value = countProgress(countContentOriginal.value, countContentEdited.value);
                    
                    closingActivityContent.value = realcontent.innerHTML
                    updateSectionByTitle(closingActivityContent.value, 'Closing Activity');
                        
                } else if(activeTab.value === 'assessment') {

                    countContentEdited.value = countContent(introContent.value, openingActivityContent.value, mainActivityContent.value, closingActivityContent.value, realcontent.innerHTML);
                    progressCount.value = countProgress(countContentOriginal.value, countContentEdited.value);
                    
                    assessmentContent.value = realcontent.innerHTML
                    updateSectionByTitle(assessmentContent.value, 'Assessment');

                }
            }

            nextTick(() => {
                applyHoverEffects();
            });
        });
    }
};
const toggleHtmlMode = () => {
  isHtmlMode.value = !isHtmlMode.value;

  if (isHtmlMode.value) {
    // Menyimpan konten editor ke dalam format HTML
    const htmlContent = DynamicEditor.value.getHTML();
    // Anda bisa menggunakan DOM secara langsung atau Vue template untuk menampilkan HTML ini
    document.getElementById('dynamic-editor').innerHTML = `<textarea style="width: 100%; height: 300px;">${htmlContent}</textarea>`;
  } else {
    // Mengembalikan dari HTML mode ke editor mode
    const textarea = document.getElementById('dynamic-editor').querySelector('textarea');

    // buatkan code untuk init ulang
    const htmlContent = textarea ? textarea.value : '';
    DynamicEditor.value.commands.setContent(htmlContent);
  }
};

// eslint-disable-next-line 
const fetchDataFromParent = () => {
    const data = decryptDataFromURL();
    fetchData(data);
};
const fetchPageMeta = () => {
    pageMeta.value = route.meta.page;
};

const redirectToEditWithQueryParams = () => {
    const queryParams = route.query;
    router.push({ path: '/edit', query: queryParams });
};

const decryptDataFromURL = () => {
    const encryptedString = route.query.data;
    if (!encryptedString) {
        router.push({ path: '/', query: { key: 'reset' } });
        return;
    }
    const decryptedString = decodeURIComponent(encryptedString);
    const redirectObject = JSON.parse(decryptedString);
    return redirectObject;
};
const setActiveTab = (tabName) => {
    showProcessingOverlay(true);
    const saveButton = document.querySelector(".save-changes-button");
    if (saveButton) {
        saveButton.click();
    }
    setTimeout(() => {
        activeTab.value = tabName;
        setTimeout(() => {
            applyHoverEffects();
            showProcessingOverlay(false);
        }, 1500);
    }, 1500);
};
// eslint-disable-next-line
const toggleSidebar = () => {
    sidebarOpen.value = !sidebarOpen.value;
};
const setDefaultContentWidth = () => {
    const contentElement = document.querySelector(".content");
    if (contentElement) {
        const marginSpace = 100;
        defaultContentWidth.value = window.innerWidth - marginSpace;
    }
};
const findSectionByTitle = (content, title) => {
    const keys = Object.keys(content);
    for (const key of keys) {
        const section = content[key];
        if (section.title === title) {
            return section;
        }
    }
    return null;
};
const updateSectionByTitle = (newContent, title) => {
    const keys = Object.keys(content.value);
    for (const key of keys) {
        const section = content.value[key];
        if (section.title === title) {
            const childContentProperty = Object.keys(content.value[key].content);
            for (const childContentkey of childContentProperty) {
                content.value[key].content[childContentkey].content = newContent;
            }   
        }
    }
    return null;
};
const findSectionContent = (section) => {
    if (!section) {
        return "";
    }
    const keys = Object.keys(section.content);
    for (const key of keys) {
        const content = section.content[key];
        return content.content;
    }
};
const downloadResources = async () => {
    window.open(
        `${process.env.VUE_APP_API_URL}/api/lesson-packages/download?lesson_package_id=${contentId.value}&resource_type=slides`,
        "_blank"
    );
};
// eslint-disable-next-line
const fetchData = async (data) => {
    emit("loading", true);
    try {
        const response = await LessonPackageService.buildLessonPackage(data);
        if (response.status === 200) {
            content.value = response.data.data.lesson_package.content;
            title.value = response.data.data.lesson_package.title;
            resource.value = response.data.data.resource;
            contentId.value = response.data.data.resource.lesson_package_id;
            const introSection = findSectionByTitle(content.value, "Introduction");
            const openingActivitySection = findSectionByTitle(
                content.value,
                "Opening Activity"
            );
            const mainActivitySection = findSectionByTitle(
                content.value,
                "Main Activity"
            );
            const closingActivitySection = findSectionByTitle(
                content.value,
                "Closing Activity"
            );
            const assessmentSection = findSectionByTitle(content.value, "Assessment");
            introContent.value = findSectionContent(introSection);
            openingActivityContent.value = findSectionContent(
                openingActivitySection
            );
            mainActivityContent.value = findSectionContent(mainActivitySection);
            closingActivityContent.value = findSectionContent(
                closingActivitySection
            );
            assessmentContent.value = findSectionContent(assessmentSection);
            const selectedData = response.data.data.resource.selected_data;
            if (response.data.data.resource.payload.software) {
                softwareName.value = response.data.data.resource.payload.software;
            } else {
                softwareName.value =
                    response.data.data.resource.raw_data[selectedData - 1]["core"][
                    "software"
                    ];
            }

            const author = {
                firstname : response?.data?.data?.properties?.author?.firstname,
                lastname : response?.data?.data?.properties?.author?.lastname,
                image : response?.data?.data?.properties?.author?.image
            }

            originalAuthor.value = author;
            teacherModeId.value = response?.data?.data?.resource?.slides_id;
            nextTick(() => {
                applyHoverEffects();
            });
        } else {
            emit("error", true);
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        console.error(error);
        emit("error", true);
    } finally {
        emit("loading", false);
    }
};
// eslint-disable-next-line
const fetchDataLessonPackage = async (id) => {
    emit("loading", true);
    try {
        const response = await LessonPackageService.getLessonPackage(id);
        if (response.status === 200) {
            content.value = response.data.data.lesson_package.content;
            title.value = response.data.data.lesson_package.title;
            resource.value = response.data.data.resource;
            contentId.value = response.data.data.resource.lesson_package_id;

            progressCount.value = response.data.data.properties.progress;

            const introSection = findSectionByTitle(content.value, "Introduction");
            const openingActivitySection = findSectionByTitle(
                content.value,
                "Opening Activity"
            );
            const mainActivitySection = findSectionByTitle(
                content.value,
                "Main Activity"
            );
            const closingActivitySection = findSectionByTitle(
                content.value,
                "Closing Activity"
            );
            const assessmentSection = findSectionByTitle(content.value, "Assessment");
            introContent.value = findSectionContent(introSection);
            openingActivityContent.value = findSectionContent(
                openingActivitySection
            );
            mainActivityContent.value = findSectionContent(mainActivitySection);
            closingActivityContent.value = findSectionContent(
                closingActivitySection
            );
            assessmentContent.value = findSectionContent(assessmentSection);
            const selectedData = response.data.data.resource.selected_data;
            if (response.data.data.resource.payload.software) {
                softwareName.value = response.data.data.resource.payload.software;
            } else {
                softwareName.value =
                    response.data.data.resource.raw_data[selectedData - 1]["core"][
                    "software"
                    ];
            }
            
            countContentOriginal.value = countContent(introContent.value, openingActivityContent.value, mainActivityContent.value, closingActivityContent.value, assessmentContent.value);

            const author = {
                firstname : response?.data?.data?.properties?.author?.firstname,
                lastname : response?.data?.data?.properties?.author?.lastname,
                image : response?.data?.data?.properties?.author?.image
            }

            originalAuthor.value = author;
            teacherModeId.value = response?.data?.data?.resource?.slides_id;

            nextTick(() => {
                applyHoverEffects();
            });
        } else {
            emit("error", true);
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        console.error(error);
        emit("error", true);
    } finally {
        emit("loading", false);
    }
};
const getAndOpenPDF = async () => {
    try {

        if (pageMeta.value !== 'preview') {
            return;
        }

        buttonText.value = "Loading...";
        const queryString = window.location.search;
        const appUrl = process.env.VUE_APP_URL;
        const apiUrl = process.env.VUE_APP_API_URL;
        const apiEndpoint = `${apiUrl}/print?url=${appUrl}/print?${queryString}`;
        const response = await fetch(apiEndpoint);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
        buttonText.value = "Print as PDF";
    } catch (error) {
        console.error("Error fetching PDF:", error);
        buttonText.value = "Print as PDF";
    }
};

const openTeacherMode = async () => {
        if (pageMeta.value !== 'preview') {
            return;
        }

        window.open('https://slides.eddy4teachers.com/' + teacherModeId.value , "_blank");
};

onMounted(async () => {
    setDefaultContentWidth();
    fetchPageMeta();
    window.addEventListener("resize", setDefaultContentWidth);

    const lessonPackageId = route.params.id;
    console.log('lessonPackageId', lessonPackageId)
    if (lessonPackageId) {
        await fetchDataLessonPackage(lessonPackageId);
    } else {
        const data = decryptDataFromURL();
        await fetchData(data);
    }
});

defineExpose({
  fetchDataFromParent
});
</script>

<style>
.container {
    width: 80%;
    margin: 0 auto;
}

.header {
    font-family: 'League Spartan' !important;
    font-size: 1.7em;
    font-weight: 600;
    margin: 0.3em 0em;
    max-width: 66vw;
    word-wrap: break-word;
}

.border-header {
    border-bottom: 1.5px solid #CCCCCC;
    margin-bottom: 0.9em;
    stroke-linecap: round;
}

.sub-header {
    font-size: 1.3em;
    font-weight: 500;
}

.chip {
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-family: 'League Spartan';
    color: #003D6A;
    text-decoration: none;
}

.chip:not(:last-child) {
    padding-right: 0.5em;
    border-right: 2px solid #EAECF0;
}

.content {
    font-family: 'League Spartan';
    font-size: 16px;
}

.activity-table {
    width: 60%;
    border-collapse: collapse;
}

.activity-table th,
.activity-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.activity-table th {
    background-color: #f8f8f8;
}

.activity-table td:not(:first-child) {
    /* background-color: #f1f1f1; */
}

.activity-table th:first-child,
.activity-table td:first-child {
    /* background-color: #e7e7e7; */
}

.right-sidebar {
    font-family: 'League Spartan';
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
    border-left: 2px solid #D8DAE5;
    transition: flex-basis 0.5s ease;
}

.right-sidebar.is-open {
    font-family: 'League Spartan';
    flex: 0 0 250px;
}

.category-label {
    font-size: 1.2em;
    font-weight: 700;
}

.category-chip {
    font-size: 0.7em;
    padding: 0.1em;
    border-radius: 2px;
}

.category-chip-child {
    background-color: rgb(234, 236, 240);
    padding: 0.3em;
    border-radius: 3px;
}

.standard-text {
    font-size: 1rem;
    font-weight: 400;
}

.button-blue {
    width: max-content;
    padding: 0.625rem 1.25rem;
    border-radius: 1rem;
    background-color: #003D6A;
    color: #ffffff;
    line-height: 1.25rem;
    text-align: center;
    text-decoration: none;
}

.button-tiptap {
    cursor: pointer;
}

ul:not(.ul-padding) {
    margin-top: 0.4rem;
    padding-left: 1rem;
}

ol:not(.ul-padding) {
    margin-top: 0.4rem;
    padding-left: 2rem;
}

</style>