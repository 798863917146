<template>
    <v-container>
        <div v-if="props.showPromptPreview" class="form-title-mini">
                My Prompt
        </div>

        <PromptPreview ref="promptPreviewRef" v-if="props.showPromptPreview"></PromptPreview>

        <div v-if="props.showResultSection && result">
          <GenerateResultSection v-for="(resultItem, index) in result" :key="resultItem.topic" :result="resultItem" :index="index" :idGenerated="idGenerated"></GenerateResultSection>
          <GenerateResultSection v-if="result.length < 3 && !props.showErrorSection" :isLoading="true" :index="result.length"></GenerateResultSection>
        </div>
        
        <ErrorGenerate @click="retry" v-if="props.showErrorSection"></ErrorGenerate>
        
        <GenerateResultSection v-if="props.showLoading && !result" :isLoading="true" :index="0"></GenerateResultSection>

        <div style="height: 300px;"></div>

    </v-container>
</template>

<script setup>
import { ref, onMounted, defineEmits, watch, defineProps } from 'vue';
import GenerateResultSection from '@/components/GenerateResultSection.vue';
import ErrorGenerate from '@/components/ErrorGenerate.vue';
import PromptPreview from '@/components/PromptPreview.vue';
import LocalStorageService from '@/services/localStorageService';
import { useStore } from 'vuex';

const props = defineProps({
  showPromptPreview: {
    type: Boolean,
    default: false,
  },
  showLoading: {
    type: Boolean,
    default: false,
  },
  showResultSection: {
    type: Boolean,
    default: false,
  },
  showErrorSection: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const result = ref([]);
const idGenerated = ref(Number);
const promptPreviewRef = ref(null);

onMounted(() => {
  updateResult();
});

watch(() => store.state.formResult, () => {
  updateResult();
});

const updateResult = () => {
  const storedData = store.state.formResult;
  
  if(storedData) {
    idGenerated.value = storedData.id;
    result.value = storedData.results;
  }
  if(promptPreviewRef.value) {
    promptPreviewRef.value.getPreviewData();
  }
};

const emit = defineEmits(['generate-clicked']);

const retry = () => {
  let storedData = store.state.formData;
    
  if (storedData !== null && storedData !== undefined) {
      const formResults = LocalStorageService.getFormResults();
      const formData = LocalStorageService.getFormData();
      if(formResults && formData) {
          store.commit('SET_FORM_RESULTS', formResults);
          store.commit('SET_FORM_DATA', formData);
          storedData = store.state.formData;
      } 
  }

  let data = storedData;
  emit('generate-clicked', data);
}
</script>