// src/services/ChinaUsService.js

import http from "./httpService";

class ChinaUsService {
  async getAllSubmission(page, sort, stat) {
    const param = {
      page: page,
      sort: sort,
      status: stat
    }
    
    const url = http.getUrlWithParams(`/china-us/submission/projects`, param);
    return await http.get(url);
  }

  async getSubmissionForm() {
    try {
      const response = await http.get("/china-us/submission/form");
      return response;
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        return error.response;
      }
      throw error;
    }
  }

  async deletSubmission(id) {
    const response = await http.delete(`/china-us/submission/projects/${id}`);
    return response;
  }

  async getSubmissionbyId(id) {
      const response = await http.get(`/china-us/submission/projects/${id}`);
      return response;
  }

  async postSubmissionForm(data) {
    try {
      const response = await http.post("/china-us/submission/form", data);
      return response;
    } catch (error) {
      if (error.response.status === 400) {
        return error.response;
      }
      throw error;
    }
  }

  async uploadFile(formData) {
    const url = "https://storage.eddy4teachers.com/api/files";
    const options = {
      method: "POST",
      headers: {
        Authorization: "Bearer 9d3357c3f6ee75c701d271c29bcfe0a8",
      },
      body: formData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      return await response.json();
    } catch (error) {
      alert("Failed to upload file:" + error);
      throw error;
    }
  }
  
  async deleteFile(filePath) {
    return http.delete("https://storage.eddy4teachers.com/api/files", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 9d3357c3f6ee75c701d271c29bcfe0a8",
      },
      data: { file_path: filePath },
    });
  }
}

export default new ChinaUsService();
