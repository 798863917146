<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Create Lesson Plans</h1>
    </div>
    <div class="admin-content-layout">
      <div class="admin-main-content">
        <TitleSection></TitleSection>
        <EditorSection></EditorSection>
      </div>
      <div class="admin-right-sidebar" :class="{ 'mobile-open': rightSidebarOpen }">
        <div class="admin-sidebar-header">
          <button class="close-sidebar-button" @click="toggleRightSidebar" v-if="isMobile">Close Sidebar</button>
        </div>
        <div class="admin-sidebar-content">
          <!-- Sidebar content here -->
          <RightSidebar></RightSidebar>
        </div>
      </div>
    </div>
    <button class="floating-button" @click="toggleRightSidebar" v-if="isMobile && !rightSidebarOpen">Open
      Sidebar</button>
    <div class="right-sidebar-overlay" v-if="rightSidebarOpen && isMobile" @click="toggleRightSidebar"></div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import RightSidebar from '@/components/admin/lesson-plans/RightSidebar.vue';
import TitleSection from '@/components/admin/lesson-plans/TitleSection.vue';
import EditorSection from '@/components/admin/lesson-plans/EditorSection.vue';

const form = reactive({
  userGroup: '',
  firstName: '',
  lastName: '',
  email: '',
  schoolName: '',
  password: '',
  confirmPassword: '',
  status: '',
  active: '',
  linkedinUrl: '',
  image: null,
});

const errors = reactive({
  userGroup: '',
  firstName: '',
  lastName: '',
  email: '',
  schoolName: '',
  password: '',
  confirmPassword: '',
  status: '',
  active: '',
  linkedinUrl: '',
  image: '',
});

const validateForm = () => {
  // Reset errors
  Object.keys(errors).forEach((key) => {
    errors[key] = '';
  });

  let isValid = true;

  // Validate required fields
  Object.keys(form).forEach((key) => {
    if (!form[key] && key !== 'linkedinUrl' && key !== 'image') {
      errors[key] = `${key} is required`;
      isValid = false;
    }
  });

  if (form.password !== form.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
    isValid = false;
  }

  if (!isValid) {
    return;
  }

  // Add form submission logic here
  alert('Form berhasil disubmit!');
};

const handleFileUpload = (event) => {
  form.image = event.target.files[0];
};

const rightSidebarOpen = ref(false);
const isMobile = ref(window.innerWidth < 991);

const handleResize = () => {
  isMobile.value = window.innerWidth < 991;
};

window.addEventListener('resize', handleResize);

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function toggleRightSidebar() {
  rightSidebarOpen.value = !rightSidebarOpen.value;
}
</script>