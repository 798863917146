<template>
    <div class="flex-container">
        <div class="flex-item" style="padding: 0">
            <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
                <div>
                    <img src="/images/chinaus/submission-background.svg" alt="Arrow Icon"
                        class="responsive-image-faq" />
                </div>
                <div class="label-cover">
                    <div class="white-backdrop">
                        Submission
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="welcome-page-4-dsa welcome-page-5 pb-5">

        <div v-if="currentAppState !== AppState.isAdminorYSA">
            
            <div>
                <div class="container d-flex justify-center align-center py-5">
                    <div class="card-chinaus" style="background-color: #F2F3FB;border-radius: 25px;">
                        <div class="label-container">
                            <h2 class="label-card-chinaus">Review and Submit</h2>

                            <div class="tabs scroll-container my-4">
                                <span @click="goToTabReview(1)" class="tab"
                                    :class="{ 'tab-chinaus-active-primary': currentTabReview == 1 }">Team
                                    Information</span>
                                <span @click="goToTabReview(2)" class="tab"
                                    :class="{ 'tab-chinaus-active-primary': currentTabReview == 2 }">Project
                                    Information</span>
                            </div>

                            <div v-if="currentTabReview == 1">
                                <TeamInformationPreviewPage :teamData="teamData" :isConfirm="true" @go-to-step="goToStep" @update-visibility-modal-save="updateVisibilityModalSave"/>
                            </div>
                            <div v-else-if="currentTabReview == 2">
                                <ProjectInformationPreviewPage :projectData="projectData" :isConfirm="true" @update-project-data="updateProjectData" @go-to-step="goToStep" @update-visibility-modal-save="updateVisibilityModalSave"/>
                            </div>
                            <div @click="gotoSubmissionList()" style="display: flex;gap: 10px;justify-content: end;">
                                <button class="button button-email" type="button">
                                    Confirm
                                </button>                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-else style="height: 100vh;">
            <div class="spinner-container">
                <div class="mt-5 spinner"></div>
            </div>
        </div>

    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import TeamInformationPreviewPage from '@/views/ChinaUs/SubmissionSubmit/TeamInformationPreviewPage.vue';
import ProjectInformationPreviewPage from '@/views/ChinaUs/SubmissionSubmit/ProjectInformationPreviewPage.vue';
import cookiesService from '@/services/cookiesService';
import ChinaUsService from '@/services/chinausService.js';

const router = useRouter();

const teamData = ref({
    leader: {
        user_id: null,
        phone: "",
        date_of_birth: "",
        state: "",
        city: "",
        parental_consent_url: ""
    },
    members: [
        {
            user_id: null,
            phone: "",
            date_of_birth: "",
            state: "",
            city: ""
        },
    ]
});

const AppState = Object.freeze({
    isAdminorYSA: 'isAdminorYSA',
    isFormExist: 'isFormExist',
    isNoForm: 'isNoForm',
    isSubmitted: 'isSubmitted'
});

const currentAppState = ref(AppState.isAdminorYSA);
const showModalSave = ref(false);

const getSubmissionForm = async () => {
    try {
        const response = await ChinaUsService.getSubmissionForm();

        if (response.status === 200) {
            if (response.data.message === "This user has no submission") {
                currentAppState.value = AppState.isNoForm;
            } else if (response.data.message === "Submission form retrieved successfully") {
                currentAppState.value = AppState.isFormExist;
                if (response.data.data) {
                    Object.assign(teamData.value.leader, response.data.data.leader);
                    Object.assign(teamData.value.members, response.data.data.members);
                    projectData.value = response.data.data.project;
                }
            }
        } else if (response.status === 403) {
            router.push({ path: `/china-us/submission` });
        }
    } catch (error) {
        console.log(error.message);
    }
};

const currentUser = ref(null);

const checkAuthToken = async () => {
    try {
        const authToken = await cookiesService.getCookie('authToken');
        if (authToken) {
            currentUser.value = authToken;
        }
    } catch (error) {
        console.error(error);
    }
};

checkAuthToken();

const currentStep = ref(3);
const currentTabReview = ref(1);

const projectData = ref({
    title: "",
    image_url: "",
    summary: "",
    hardware: "",
    software: "",
    background: "",
    details: "",
    impact: "",
    schematics_url: "",
    demo_url: "",
    attachments_url: "",
    additional_info: ""
});

const updateProjectData = (updatedData) => {
    projectData.value = updatedData; // Update data asli
}

function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 768) {
        height = width * (2000 / 1440);
    } else {
        height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 768) {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
        dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
}

onMounted(async () => {
    await checkAuthToken();

    teamData.value.leader.user_id = currentUser.value.user_id;
    adjustHeight();
    setBackgroundImage();
    setBackgroundPosition();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);

    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');

    if (welcomePageElement) {
        welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse.svg")`;
    }

    await getSubmissionForm();
});

function setBackgroundPosition() {
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');
    const width = window.innerWidth;

    if (width <= 768) {
        welcomePageElement.style.backgroundPosition = `right top`;
    } else {
        welcomePageElement.style.backgroundPosition = `right top`;

    }

}

onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
});

function scrollToFAQ() {
    nextTick(() => {
        const faqButton = document.getElementById('faqButton');
        if (faqButton) {
            const scrollContainer = faqButton.closest('.scroll-container');
            const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
            scrollContainer.scrollLeft = scrollPosition + 26;
        }
    });
}

const goToStep = (step) => {
    currentStep.value = step;
};

const goToTabReview = (step) => {
    currentTabReview.value = step;
};

function updateVisibilityModalSave() {
    showModalSave.value = !showModalSave.value
}

function gotoSubmissionList() {
    router.push({ path: `/china-us/submission-list` });
}
</script>