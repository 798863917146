<template>
  <div class="container-37">
    <div class="border-container-37 mb-5">
      <div class="header-37">
        <div class="flex-container-37">
          <img height="20" style="margin-bottom: 0.3em;" src="/images/admin/folder-purple.svg" alt="Submission Icon">
          <h1>Submission Overview</h1>
        </div>
        <p>as of {{ formatDate(new Date()) }}</p>
      </div>
      <div class="stats">
        <div class="stat">
          <h2>Total Submitted</h2>
          <p>{{ totalSubmitted }}</p>
        </div>
        <div class="stat">
          <h2>Still in Progress</h2>
          <p>{{ stillInProgress }}</p>
        </div>
      </div>
    </div>

    <div class="header-38">
      <h1>Project’s List</h1>
    </div>
    <div class="table-responsive">
      <table class="admin-table">
        <thead>
          <tr>
            <th>Project's Name</th>
            <th>Status</th>
            <th>Date Uploaded</th>
            <th>Unique Code</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="!tableLoading">
          <tr v-for="(project, index) in data.data.rows" :key="index">
            <td>{{ project.project.title }}</td>
            <td>{{ project.team.submission_status }}</td>
            <td>{{ new Date(project.project.created_at).toLocaleDateString() }}</td>
            <td>{{ project.project.unique_code }}</td>
            <td class="action-column">
              <a :href="`/admin/china-us/projects/${project.project.id}`">View</a>
              <a href="#" @click="openModal(project.project.id)"><img src="/images/admin/trash.svg" alt="Delete"></a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <div class="spinner-container">
                <div class="spinner"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-controls">
      <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
      <button v-for="page in data.data.total_pages" :key="page" @click="changePage(page)"
        :class="{ 'page-active': page === currentPage }">{{ page }}</button>
      <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
    </div>
  </div>

  <ModalComponent :visible="showModalSave" @update:visible="updateVisibilityModalSave">
    <div class="container-modal">
      <div class="modal-title">Submit your project?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1em;">
        Are you sure want to remove this submission?
      </div>
      <div style="display: flex;justify-content: center;font-size: 1em;">
        an email will be send out to the Team Leader regarding the removal.
      </div>
      <div class="modal-button">
        <button class="btn btn-discard" style="min-width: 80px;" @click="updateVisibilityModalSave">Back</button>
        <button class="btn" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading"
          :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }"
          style="padding: 0.5em 1em; min-width: 80px;"
          @click="deleteSubmission(projectId)">
          Confirm
        </button>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import ChinaUsService from '@/services/chinausService.js';
import ModalComponent from '@/components/ModalComponent.vue';

const formatDate = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const totalSubmitted = ref(0);
const stillInProgress = ref(0);
const showModalSave = ref(false);
const isLoading = ref(false);
const projectId = ref(null);
const tableLoading = ref(true);

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const currentPage = ref(1);

onMounted(async () => {
  await getAllSubmission(currentPage.value, 'newest');
});

const getAllSubmission = async (page, sort) => {
  try {
    tableLoading.value = true;
    const response = await ChinaUsService.getAllSubmission(page, sort);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;

      if(data.value.data.extras) {
        totalSubmitted.value = data.value.data.extras.total_rows_submitted;
        stillInProgress.value = data.value.data.extras.total_rows_in_progress;
      }
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAllSubmission(page, 'newest');
  }
};

const openModal = (id) => {
  projectId.value = id; // Set projectId
  showModalSave.value = true;
};

const updateVisibilityModalSave = () => {
  showModalSave.value = !showModalSave.value;
};

const deleteSubmission = async (id) => {
  isLoading.value = true; // Mulai loading
  try {
    const response = await ChinaUsService.deletSubmission(id);
    if (response.status === 200) {
      await getAllSubmission(currentPage.value, 'newest');
      updateVisibilityModalSave(); // Tutup modal setelah penghapusan sukses
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    isLoading.value = false; // Selesai loading
  }
};
</script>

<style>
body {
  font-family: 'League Spartan', sans-serif;
}
.border-container-37 {
  border: 1px solid #D8DAE5;
  padding: 10px;
  border-radius: 8px;
}
.flex-container-37 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.container-37 {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
}
.header-37 {
  margin-bottom: 20px;
}
.header-37 h1 {
  margin: 0;
  font-size: 2em;
  color: #003D6A;
}
.header-37 p {
  color: #003D6A;
}
.header-38 h1 {
  color: #003D6A;
  font-size: 1.7em;
}
.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stats .stat {
  background-color: #4C4DDC;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  text-align: center;
  margin: 0 10px;
}
.stats .stat:first-child {
  margin-left: 0;
}
.stats .stat:last-child {
  margin-right: 0;
}
.table-responsive {
  overflow-x: auto;
}
.admin-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  border-radius: 20px;
}
.admin-table th,
.admin-table td {
  padding: 10px;
  border: 0.5px solid #ddd;
  text-align: left;
}
.admin-table th {
  background-color: #003D6A;
  color: white;
}
.admin-table td {
  color: #003D6A;
}
.admin-table thead th:first-child {
  border-top-left-radius: 10px;
}
.admin-table thead th:last-child {
  border-top-right-radius: 10px;
}
.admin-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.admin-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-column a {
  margin: 0 5px;
}
</style>
