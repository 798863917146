<template>
    <div class="container d-flex justify-center align-center pt-5" style="font-family: 'League Spartan';">
        <div class="scroll-wrapper">
        <div class="text-center scroll-container">
            <!-- Menggunakan :class untuk binding kondisi -->
            <a id="competitionDetailButton" href="/china-us"
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'ChinaUSPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/home.svg" alt="Competition Details Icon">
                Home
            </a>
            <!-- Menggunakan :class untuk binding kondisi -->
            <a id="competitionDetailButton" href="/china-us/competition-details"
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'CompetitionDetailsPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/trophy.svg" alt="Competition Details Icon">
                Competition Details
            </a>
            <!-- Link lainnya -->
            <a id="faqButton" href="/china-us/faq" 
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'ChinaUSFAQPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/message-question.svg" alt="FAQ Icon">
                FAQ
            </a>
            <a href="/china-us/discussion" target="_blank" 
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'DiscussionPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/message-question.svg" alt="Discussion Icon">
                Discussion
            </a>
            <a href="/china-us/submission"
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'SubmissionSubmitPage' || route.name === 'SubmissionPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/send.svg" alt="Submission Icon">
                Submission
            </a>
            <a href="/china-us/submission-list"
                :class="['button-competition mx-2', { 'button-competition-active': route.name === 'SubmissionListPage' || route.name === 'SubmissionDetailPage' }]" style="cursor: pointer;">
                <img height="11.5" src="/images/chinaus/folder-2.svg" alt="Submission Icon">
                View All Projects
            </a>
        </div>
        </div>
    </div>
</template>

<script setup>
// import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
//const isRouterNameMatch = computed(() => route.name === 'NamaRouteTertentu'); // Sesuaikan dengan nama route yang diinginkan
</script>