<template>
  <!-- Conditional Rendering for Standard Footer -->
  <div v-if="footerType === 'standart-footer'" :style="{ height: footerHeight, backgroundColor: footerColor }"
    class="footer" @mouseenter="toggleFooter(true)" @mouseleave="toggleFooter(false)" ref="footerRef">

    <!-- Logo Section -->
    <template v-if="!footerExpanded">
      <div class="footer-col">
        <div class="logo-container">
          <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
        </div>
        <div class="edm8ker-copyright-mobile">
          ©2024 Eddy. All rights reserved.
        </div>
      </div>

      <!-- Toggle Section -->
      <div class="footer-col">
        <img height="30" src="/images/arrow-square-right.svg" alt="Arrow Icon" class="clickable-image" />
      </div>

      <!-- End Section -->
      <div class="footer-col text-end footer-background">
        <div class="edm8ker-copyright">
          ©2024 Eddy. All rights reserved.
        </div>
      </div>
    </template>

    <!-- Expanded Content -->
    <template v-if="footerExpanded">
      <div class="footer-expanded-content pt-3"
        style="display: flex; align-items: start; flex-direction: column; height: 100%;">
        <div class="logo-container">
          <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
        </div>
        <!-- Address Section -->
        <div class="pl-4 pt-4 flex-container">
          <img class="flex-item pr-1" height="30" src="/images/location.svg" alt="Location Icon" />
          <div>
            <div class="footer-label">edm8ker LLC</div>
            <div class="footer-label">601 S Rancho Dr</div>
            <div class="footer-label">Las Vegas, NV 89106</div>
          </div>
        </div>
      </div>

      <!-- Information Sections -->
      <div class="footer-expanded-content" style="width: 60%; height: 100%;display: flex; /* Mengaktifkan flexbox */
    justify-content: space-around;">
        <div class="pt-4 column">
          <!-- Column 1: About -->
          <div>
            <div class="footer-label-title">About</div>
            <div>
              <div class="footer-label">
                <a href="#" class="footer-link">Our Approach</a>
              </div>
              <div class="footer-label">
                <a href="https://eddy4teachers.com/digital-making" class="footer-link">Digital Making</a>
              </div>
              <div class="footer-label">
                <a href="https://eddy4teachers.com/hands-on-making" class="footer-link">Hands on Making</a>
              </div>
              <div class="footer-label">
                <a href="https://eddy4teachers.com/about-ysa" class="footer-link">Sustainability Ed (YSA)</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 column">
          <!-- Column 2: Help/Legal -->
          <div>
            <div class="footer-label-title">Help/Legal</div>
            <div>

              <div class="footer-label">
                <a href="https://edm8kersupport.zendesk.com/hc/en-us" target="_blank" class="footer-link">Help
                  Center</a>
              </div>

              <div class="footer-label">
                <a @click="updateVisibilityPrivacyPolicies" class="footer-link">Privacy Policies</a>
              </div>

              <div class="footer-label">
                <a @click="updateVisibilityTermsofService" class="footer-link">Terms of Service</a>
              </div>

            </div>
          </div>
        </div>
        <div class="pt-4 column-right">
          <!-- Column 3: Get In Touch -->
          <div>
            <div class="footer-label-title">Get In Touch</div>
            <div>

              <div class="footer-label" style="display:flex;align-items:center;margin-bottom: 0px;">
                <div><img src="/images/email.svg"></div>
                <div style="margin-bottom: 5px;margin-left: 5px;"><a href="mailto:hello@eddy4teachers.com"
                    target="_blank" class="footer-link">hello@eddy4teachers.com</a></div>
              </div>

              <div class="footer-label" style="display:flex;align-items:center;"><img src="/images/social-icon.svg">
                <div style="margin-top: 5px;margin-left:5px;"><a href="https://www.linkedin.com/showcase/eddy4teachers/"
                    target="_blank" class="footer-link">LinkedIn</a></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Footer End Section (Expanded) -->
      <div class="footer-expanded-content pb-3"
        style="display: flex; justify-content: flex-end; flex-direction: column; height: 100%;">
        <div class="edm8ker-copyright">
          © 2024 Edm8ker. All rights reserved.
        </div>
      </div>
    </template>

    <ModalComponent :visible="showModalPrivacyPolicies" @update:visible="updateVisibilityPrivacyPolicies">
      <!-- Fill in capital here -->
      <div class=" w-full rounded-xl fancybox-content container-modal" id="under-construction-popup">
        <h2 class="text-center text-color-primary pb-3 fz-28" style="color:#003D6A;">Privacy Policy</h2>

        <p class="text-16 mt-1 ff-3">This Privacy Policy outlines the types of information that Eddy and edm8ker LLC
          (“we,”
          “us,” “our”) may collect from you when you visit our website, how we use that information, and the choices you
          have
          regarding our use of your personal data. By using our website, you agree to this Privacy Policy..</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">1. Information We Collect</h2>

        <p class="text-16 mt-1 ff-3">We may collect various types of personal data from you, including:</p>

        <ul style="margin:20px !important">
          <li>
            <p lass="text-16 ff-3">Contact information, such as your name, email address, phone number, and mailing
              address.
            </p>
          </li>
          <li>
            <p lass="text-16 ff-3">Demographic information, such as your age, gender, and location.</p>
          </li>
          <li>
            <p lass="text-16 ff-3">Information about your use of our website, such as your IP address, browser type, and
              operating system.</p>
          </li>
          <li>
            <p lass="text-16 ff-3">Any other information you choose to provide to us.</p>
          </li>
        </ul>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">2. How We Use Your Information</h2>

        <p class="text-16 mt-1 ff-3">We may use your personal data for the following purposes:</p>

        <ul style="margin:20px !important">
          <li>
            <p class="text-16 ff-3">To provide you with the products or services you have requested.</p>
          </li>
          <li>
            <p class="text-16 ff-3">To personalize your experience on our website.</p>
          </li>
          <li>
            <p class="text-16 ff-3">To improve our website and the services we offer.</p>To improve our website and the
            services we offer.
          </li>
          <li>
            <p class="text-16 ff-3">To communicate with you about our products, services, and promotions.</p>
          </li>
          <li>
            <p class="text-16 ff-3">To conduct research and analytics.</p>
          </li>
          <li>
            <p class="text-16 ff-3">To comply with legal obligations.</p>
          </li>
        </ul>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">3. Information Sharing</h2>

        <p class="text-16 mt-1 ff-3">We may share your personal data with third-party service providers who assist us
          with
          our
          business operations, such as website hosting, payment processing, and data analytics. We may also share your
          information if required by law or to protect our legal rights.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">4. Cookies and Other Tracking Technologies</h2>

        <p class="text-16 mt-1 ff-3">We use cookies and other tracking technologies to collect information about your
          use of
          our website, such as your browsing history, pages visited, and preferences. You can choose to disable cookies
          in
          your browser settings, but this may limit your ability to use certain features of our website.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">5. Your Rights</h2>

        <p class="text-16 mt-1 ff-3">You have the right to access, correct, and delete your personal data. You may also
          object
          to our processing of your personal data or request that we restrict the use of your information. To exercise
          these
          rights, please contact us using the information provided below.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">6. Security</h2>

        <p class="text-16 mt-1 ff-3">We take reasonable measures to protect your personal data from unauthorized access,
          use,
          or disclosure. However, no security system is completely secure, and we cannot guarantee the security of your
          personal data.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">7. Changes to This Policy</h2>

        <p class="text-16 mt-1 ff-3">We may update this Privacy Policy from time to time. If we make significant
          changes, we
          will notify you by posting a prominent notice on our website or by sending you an email.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">8. Contact Us</h2>

        <p class="text-16 mt-1 ff-3">If you have any questions or concerns about our Privacy Policy, please contact us
          at
          hello@eddy4teachers.com.</p>
      </div>
    </ModalComponent>

    <ModalComponent :visible="showModalTermsofService" @update:visible="updateVisibilityTermsofService">
      <!-- Fill in capital here -->
      <div class=" w-full rounded-xl fancybox-content container-modal" id="under-construction-popup"
        style="display: inline-block;">
        <h2 class="text-center text-color-primary pb-3 fz-28" style="color:#003D6A;">Terms of Service</h2>
        <p class="text-16 mt-1 ff-3">Welcome to Eddy, a product developed by edm8ker LLC (“we,” “us,” “our”). By using
          our
          website and services, you agree to the following Terms of Service:</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">1. Use of Our Website</h2>

        <p class="text-16 mt-1 ff-3">You may use our website for lawful purposes only. You may not use our website to:
        </p>

        <ul style="margin:20px !important">
          <li>
            <p class="text-16 ff-3">Violate any applicable laws or regulations.</p>
          </li>
          <li>
            <p class="text-16 ff-3">Infringe on the intellectual property rights of others.</p>
          </li>
          <li>
            <p class="text-16 ff-3">Transmit any viruses, malware, or other harmful software.</p>
          </li>
          <li>
            <p class="text-16 ff-3">Engage in any activity that is harmful, obscene, or offensive.</p>
          </li>
        </ul>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">2. User Accounts</h2>

        <p class="text-16 mt-1 ff-3">If you create an account on our website, you are responsible for maintaining the
          security
          of your account and password. You may not share your account or password with anyone else. You are also
          responsible
          for any activity that occurs on your account.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">3. Intellectual Property</h2>

        <p class="text-16 mt-1 ff-3">All content on our website, including text, images, graphics, and logos, is the
          property
          of edm8ker LLC or its licensors and is protected by intellectual property laws. You may not use or reproduce
          our
          content without our permission.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">4. Disclaimer of Warranties</h2>

        <p class="text-16 mt-1 ff-3">Our website and services are provided “as is” and without warranties of any kind,
          whether
          express or implied. We do not guarantee that our website will be error-free or that our services will meet
          your
          expectations.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">5. Limitation of Liability</h2>

        <p class="text-16 mt-1 ff-3">We will not be liable for any damages arising from the use of our website or
          services.
          This includes direct, indirect, incidental, and consequential damages. Some jurisdictions do not allow the
          exclusion
          or limitation of liability for certain types of damages, so the above limitations may not apply to you.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">6. Indemnification</h2>

        <p class="text-16 mt-1 ff-3">You agree to indemnify, defend, and hold edm8ker LLC harmless from any claims,
          damages,
          or expenses arising from your use of our website or services or your violation of these Terms of Service.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">7. Termination</h2>

        <p class="text-16 mt-1 ff-3">We may terminate your access to our website or services at any time for any reason
          without notice.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">8. Governing Law</h2>

        <p class="text-16 mt-1 ff-3">These Terms of Service will be governed by and construed in accordance with the
          laws of
          the United States of America. Any legal action arising from these Terms of Service must be brought in the
          courts
          located in the United States of America.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">9. Changes to These Terms</h2>

        <p class="text-16 mt-1 ff-3">We may update these Terms of Service from time to time. If we make significant
          changes,
          we will notify you by posting a prominent notice on our website or by sending you an email.</p>

        <h2 class="text-color-primary mt-1 mb-1" style="color:#003D6A;">10. Contact Us</h2>

        <p class="text-16 mt-1 ff-3">If you have any questions or concerns about our Terms of Service, please contact us
          at
          hello@eddy4teachers.com.</p>
      </div>
    </ModalComponent>

  </div>

  <!-- Conditional Rendering for Simplified Footer -->
  <div v-else-if="footerType === 'simplified-footer'" class="simplified-footer">
    ©2024 Edm8ker
  </div>
</template>

<script>
import ModalComponent from '@/components/ModalComponent.vue';

export default {
  name: 'AppFooter',
  props: {
    footerType: {
      type: String,
      default: 'standart-footer', // Default value if not provided
    }
  },
  components: {
    ModalComponent,
  },
  data() {
    return {
      footerExpanded: false,
      footerHeight: '88px',
      footerColor: "#EAECFF",
      showModalPrivacyPolicies: false,
      showModalTermsofService: false,
    };
  },
  mounted() {
    this.setBackgroundImage();
  },
  methods: {
    setBackgroundImage() {
      const footerElement = this.$refs.footerRef;
      if (footerElement) {
        footerElement.style.backgroundImage = 'url("/images/footer-background.svg")';
        footerElement.style.backgroundPosition = 'right bottom';
        footerElement.style.backgroundSize = 'cover';
        footerElement.style.backgroundSize = '200px 100px';
      }
    },
    toggleFooter(expand) {
      if (window.innerWidth < 760) {
        return;
      }
      this.footerExpanded = expand;
      this.footerHeight = this.footerExpanded ? '280px' : '88px';
    },
    updateVisibilityPrivacyPolicies() {
      this.showModalPrivacyPolicies = !this.showModalPrivacyPolicies
    },
    updateVisibilityTermsofService() {
      this.showModalTermsofService = !this.showModalTermsofService
    }
  },
};
</script>

<style>
.footer {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: height 0.5s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;
}

.footer-col {
  flex: 1;
  text-align: center;
}

.clickable-image {
  cursor: pointer;
}

.text-end {
  text-align: right;
}

.footer-background::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 414px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: 9999;
}

.footer-label-title {
  font-family: 'League Spartan';
  font-weight: 600;
  color: #003D6A;
  margin-bottom: 0.4em;
}

.footer-label-title::after {
  content: "";
  display: block;
  border-bottom: 1.5px solid #003D6A;
  margin-top: 0.4em;
  width: 40%;
}

.flex-container {
  display: flex;
}

.flex-item {
  margin-right: 10px;
}

.footer-expanded-content {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  width: 18%;
}

.column-right {
  display: flex;
  flex-direction: column;
  width: 22%;
}

.footer-label {
  margin-bottom: 10px;
}

.edm8ker-copyright {
  font-size: 0.9em;
  position: relative;
  z-index: 1;
  font-weight: 400;
  color: #003D6A;
  font-family: 'League Spartan';
}

.simplified-footer {
  color: #003D6A;
  font-family: 'League Spartan';
  padding: 12px 0px 10px 0px;
  background-color: #EAECFF;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.footer-link {
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px !important;
  color: #003D6A;
}

.edm8ker-copyright-mobile {
  display: none;
}

/* Aturan CSS untuk layar dengan lebar maksimal 600px */
@media (max-width: 760px) {
  .footer {
    flex-direction: row;
    align-items: center;
  }

  .logo-container,
  .footer-col:nth-child(2) {
    width: 100%;
    text-align: right;
    padding-right: 1em;
  }

  .footer-expanded-content,
  .column,
  .column-right,
  .footer-background {
    display: none;
  }

  .edm8ker-copyright-mobile {
    color: #003D6A;
    display: block;
    font-size: 0.6em;
  }
}
</style>
