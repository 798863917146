import { stripHtml } from 'string-strip-html'

export function countContent(intro, opening, main, closing, assessment) {
  let countIntro = stripHtml(intro).result.length
  let countOpening = stripHtml(opening).result.length
  let countMain = stripHtml(main).result.length
  let countClosing = stripHtml(closing).result.length
  let countAssessment = stripHtml(assessment).result.length

  return countIntro + countOpening + countMain + countClosing + countAssessment
}

export function countProgress(countOriginal, countEdited) {
  if (countEdited <= countOriginal) {
    return 0;
  }

  const maxTarget = countOriginal * 5 / 100;
  const added = countEdited - countOriginal;

  let progress;
  if (added > maxTarget) {
    progress = 100;
  } else {
    progress = (added / maxTarget) * 100;
  }
  
  progress = Math.floor(progress);
  return Math.max(5, progress);
}

