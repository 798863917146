<template>
    <div v-if="props.visible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content" style="position: relative;">
        <slot></slot>
        <button @click="closeModal" style="position: absolute;top: 12px;right: 14px;">
        <img src="/images/close.svg" alt="">
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue'
  
  const props = defineProps({
    visible: Boolean
  })
  
  const emits = defineEmits(['update:visible'])
  
  function closeModal() {
    emits('update:visible', false)
  }
  </script>
  
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }
  
  .modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    z-index: 1000000000000000000000000000;
  }

  .modal-title {
    font-family: 'League Spartan';
    color: #003D6A;
    font-weight: 600;
    font-size: 1.4em;
  }

  .modal-icon {
    display: flex;
    justify-content: center;
    padding-top: 0.5em;
    padding-bottom:0.5em
  }

  .modal-button {
    display: flex;
    justify-content:
    center; gap: 0.5em;
  }
  </style>
  