<template>
  <router-view @handleChangeHeaderFooter="handleChangeHeaderFooter" @handleChangeFooterType="handleChangeFooterType"></router-view>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

onMounted(() => {
  document.title = "Builder - the Educator's Buddy";
});

// Data
const showHeader = ref(true);
const showFooter = ref(true);
// eslint-disable-next-line
const headerType = computed(() => route.meta.footer);
const footerType = computed(() => route.meta.footer ?? 'standart-footer');

// eslint-disable-next-line
const handleChangeHeaderFooter = (showHeaderVal, showFooterVal) => {
  showHeader.value = showHeaderVal;
  showFooter.value = showFooterVal;
};

// eslint-disable-next-line
const handleChangeFooterType = (footerTypeVal) => {
  footerType.value = footerTypeVal;
};
</script>
