<template>
    <div v-if="props.visible" class="modal-overlay-bottom" @click.self="closeModal">
      <div class="modal-content-bottom" style="position: relative;width: 100%;">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue'
  
  const props = defineProps({
    visible: Boolean
  })
  
  // eslint-disable-next-line
  const emits = defineEmits(['update:visible'])
  
  function closeModal() {
    emits('update:visible', false)
  }  
  </script>
  
  <style>
  .modal-overlay-bottom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Mengubah posisi ke bawah */
    z-index: 9999999999;
  }
  
  .modal-content-bottom {
    background: white;
    padding: 20px;
    border-radius: 25px 25px 0px 0px;
    z-index: 1000000000000000000000000000;
    animation: slide-up 0.3s ease-out; /* Animasi slide up */
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%); /* Mulai dari bawah */
    }
    to {
      transform: translateY(0); /* Bergerak ke posisi awal */
    }
  } 
</style>
  