<template>
    <div class="admin-container">
        <div class="admin-header">
            <h1>Add User</h1>
        </div>
        <div class="border-container-unique37">
            <form class="admin-form-group-container" @submit.prevent="validateForm" novalidate>
                <div class="admin-form-group">
                    <label>User Group</label>
                    <div class="custom-select">
                        <select v-model="form.userGroup" required>
                            <option value="" disabled>Pilih User Group</option>
                            <option>Administrator</option>
                        </select>
                        <img src="/images/arrow-bottom.svg" class="select-icon" />
                    </div>
                    <span v-if="errors.userGroup" class="error-message-123">{{ errors.userGroup }}</span>
                </div>
                <div class="admin-form-group">
                    <label>First Name</label>
                    <input type="text" v-model="form.firstName" required />
                    <span v-if="errors.firstName" class="error-message-123">{{ errors.firstName }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Last Name</label>
                    <input type="text" v-model="form.lastName" required />
                    <span v-if="errors.lastName" class="error-message-123">{{ errors.lastName }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Email</label>
                    <input type="email" v-model="form.email" required />
                    <span v-if="errors.email" class="error-message-123">{{ errors.email }}</span>
                </div>
                <div class="admin-form-group">
                    <label>School Name</label>
                    <input type="text" v-model="form.schoolName" required />
                    <span v-if="errors.schoolName" class="error-message-123">{{ errors.schoolName }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Password</label>
                    <input type="password" v-model="form.password" required />
                    <span v-if="errors.password" class="error-message-123">{{ errors.password }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Confirm Password</label>
                    <input type="password" v-model="form.confirmPassword" required />
                    <span v-if="errors.confirmPassword" class="error-message-123">{{ errors.confirmPassword }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Status</label>
                    <div class="custom-select">
                        <select v-model="form.status" required>
                            <option value="" disabled>Pilih Status</option>
                            <option>Active</option>
                        </select>
                        <img src="/images/arrow-bottom.svg" class="select-icon" />
                    </div>
                    <span v-if="errors.status" class="error-message-123">{{ errors.status }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Active</label>
                    <div class="custom-select">
                        <select v-model="form.active" required>
                            <option value="" disabled>Pilih Active</option>
                            <option>Approve</option>
                        </select>
                        <img src="/images/arrow-bottom.svg" class="select-icon" />
                    </div>
                    <span v-if="errors.active" class="error-message-123">{{ errors.active }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Linkedin Url</label>
                    <input type="text" v-model="form.linkedinUrl" />
                    <span v-if="errors.linkedinUrl" class="error-message-123">{{ errors.linkedinUrl }}</span>
                </div>
                <div class="admin-form-group">
                    <label>Image</label>
                    <input type="file" @change="handleFileUpload" />
                    <span v-if="errors.image" class="error-message-123">{{ errors.image }}</span>
                </div>
                <button type="submit" class="submit-btn">SUBMIT</button>
            </form>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';

const form = reactive({
    userGroup: '',
    firstName: '',
    lastName: '',
    email: '',
    schoolName: '',
    password: '',
    confirmPassword: '',
    status: '',
    active: '',
    linkedinUrl: '',
    image: null
});

const errors = reactive({
    userGroup: '',
    firstName: '',
    lastName: '',
    email: '',
    schoolName: '',
    password: '',
    confirmPassword: '',
    status: '',
    active: '',
    linkedinUrl: '',
    image: ''
});

const validateForm = () => {
    // Reset errors
    Object.keys(errors).forEach(key => {
        errors[key] = '';
    });

    let isValid = true;

    // Validate required fields
    Object.keys(form).forEach(key => {
        if (!form[key] && key !== 'linkedinUrl' && key !== 'image') {
            errors[key] = `${key} is required`;
            isValid = false;
        }
    });

    if (form.password !== form.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
        isValid = false;
    }

    if (!isValid) {
        return;
    }

    // Add form submission logic here
    alert('Form berhasil disubmit!');
};

const handleFileUpload = (event) => {
    form.image = event.target.files[0];
};
</script>
